const ErrorPage = () => (
  <div id="error-page">
    <div className="white-wrapper">
      <div className="error-box">
        <div className="error-box__column">
          <h3 className="error-box__title">You’re not lost. We know you’re hard at work - so are we.</h3>

          <p>
            Thanks for your patience while we work on exciting features. Now let’s find a better place for you to get
            back on track!
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default ErrorPage;
