export const messages = {
  ro: {
    funnel: {
      candidate: {
        education: "Nivel educație",
        experience: "Experiență profesională",
        create: "Creează contul tău de candidat",
        createSubtext: "Primeşte oferte de angajare de la cele mai performante companii din domeniul medical",
        name: "Nume",
        namePlacehold: "Nume",
        phone: "Telefon",
        phonePlacehold: "Telefon",
        currentEmployer: "Angajator curent",
        currentEmployerPlacehold: "Angajatorul curent",
        email: "Email",
        emailPlacehold: "Email",
        birthday: "Ziua nașterii",
        birthdayPlacehold: "Ziua nașterii",
        password: "Parola",
        passwordPlacehold: "Parola",
        about: "Te rugăm să ne spui despre tine",
        aboutSubtext:
          "Detaliile despre profilul tău profesional ne vor ajuta să îţi trimitem cele mai relevante oferte de carieră, direct în contul tău",
        category: "Titulatură",
        categoryPlacehold: "Selectează titulatura",
        subcategory: "Specializare",
        subcategoryPlacehold: "Selectează specializarea",
        tags: "Competencies",
        tagsPlacehold: "Selectează competențele",
        preferences: "Te rugăm să ne spui despre jobul pe care-l cauți",
        preferencesPlacehold:
          "Detaliile despre profilul tău profesional ne vor ajuta să îţi trimitem cele mai relevante oferte de carieră, direct în contul tău",
        identification: "Unde te afli în procesul de căutare a unui nou job?",
        schedule: "Ce program de lucru cauți?",
        cities: "În ce orașe dorești să lucrezi?",
        minSalary: "Care este salariul minim pe care dorești sa îl obții? (net, LEI)",
        minSalaryPlacehold: "Salariu full-time",
        minSalaryPartPlacehold: "Salariu part-time",
        block: "Blochează companiile care nu vrei să vadă că îți cauți un job",
        blockSubtext:
          "Aici poți bloca angajatorul tău curent sau orice altă companie care nu vrei să vadă că îți cauți un job nou, pentru a evita eventualele neplăceri",
        company: "Selectează companie",
        companyHint: "Scrie numele companiei pe care vrei să o blochezi",
      },
      company: {
        create: "Creează contul tău de companie",
        createSubtext:
          "Recrutează rapid şi eficient dintr-o bază de specialisti medicali. Incepe cu un contul tău gratuit pe MEDIjobs!",
        name: "Nume companie",
        namePlacehold: "Numele companiei",
        vat: "CIF număr",
        vatPlacehold: "CIF număr",
        city: "Oraș",
        cityPlacehold: "Adaugă orașul",
        representative: "Nume reprezentant companie",
        representativePlacehold: "Numele reprezentantului companiei",
        representativePosition: "Funcție reprezentant",
        representativePositionPlacehold: "Funcția reprezentantului companiei",
        phone: "Telefon",
        phonePlacehold: "Telefon",
        email: "Email",
        emailPlacehold: "Email",
        password: "Parola",
        passwordPlacehold: "Parola",
        need: "Completează nevoile tale de recrutare",
        needSubtext: "Spune-ne care sunt posturile disponibile în compania ta, pentru care te putem ajuta să recrutezi",
        category: "Titulatură",
        categoryPlacehold: "Selectează titulatura",
        subcategory: "Specializare",
        subcategoryPlacehold: "Selectează specializarea",
        salary: "Salariu oferit (net, LEI)",
        salaryPlacehold: "Salariu",
        schedule: "Program",
        consultancy: "Primește consultanță gratuit",
        consutancySubtext:
          "Spune-ne care sunt problemele cu care te-ai confruntat până acum în recrutarea angajaților tăi (poti alege mai multe variante)",
        team: "Primește consultanță gratuit",
        teamSubtext:
          "Ne bucuram ca esti aici pentru ca stim ca-ti putem oferi un serviciu de recrutare profesionist si eficient. Pentru noi fiecare parteneriat conteaza si beneficiaza de o abordare personala",
        chooseDateTime: "Alege data și ora la care vrei să fii contactat",
        teamText:
          "Fa cunostinta cu cei care iti vor fi alaturi in permanenta, pe tot parcursul proceselor tale de recrutare. Specialistii nostri de la departamentul de Client Care si de la departamentul de recrutare vor fi cei care iti vor oferi consultanta si te vor sprijini in gasirea candidatilor ideali pentru posturile tale",
      },
      common: {
        date: "Data",
        time: "Ora",
        agree: "Sunt de acord cu",
        terms: "Termenii si Conditiile",
        platform: "platformei MEDIjobs",
        marketing: "Doresc sa primesc notificari cu noutati privind MEDIjobs si partenerii sai",
        createAccount: "Creează cont",
        alreadyHaveAccount: "Ai deja cont",
        auth: "Autentifică-te",
        location: "Localizare",
        locationPlacehold: "Începe să scrii",
        othercities: "Doresc să primesc oferte și din alte locații",
        termError: "Este necesară acceptarea termenilor",
        requiredError: "Câmpul este obligatoriu",
        emailError: "E-mail-ul trebuie să fie valid",
        phoneError: "Telefonul introdus trebuie să fie valid",
        welcome: "Bine ai venit {name}",
        addAnotherSpec: "Adaugă încă o specializare",
        addAnotherCompany: "Adaugă încă o companie",
        addAnotherNeed: "Adaugă încă o nevoie",
        continue: "Continuă",
        select: "Selectează",
        currency: "LEI",
        finalize: "Finalizează",
        chooseAccountType: "Alege tipul de cont",
        candidate: "Candidat",
        employer: "Angajator",
        need: "Nevoie",
        skip: "Treci peste",
        tags: "Competențe",
      },
      login: {
        email: "Email",
        emailPlacehold: "Email",
        password: "Parola",
        passwordPlacehold: "Parola",
        forgotPassword: "Ți-ai uitat parola?",
        noAccount: "Nu ai cont?",
        createAccount: "Creează-ți un cont chiar acum!",
      },
    },
  },
};
