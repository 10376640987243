/* eslint-disable @typescript-eslint/no-unsafe-return */
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export interface Category {
  category_id: number;
  subcategories?: Array<{ subcategory_id: number; years: number }>;
  tags: number[];
  is_main: boolean;
  years?: number;
}

interface GlobalState {
  user: ApiUser | null;
  setUser: (user: ApiUser | null) => void;
  showPhoneError?: boolean;
  setShowPhoneError: (showPhoneError: boolean) => void;
  categories: Category[];
  setCategories: (categories: Category[]) => void;
}

export const store = create<GlobalState>()(
  persist(
    set => ({
      user: null,
      setUser: user => set({ user }),
      categories: [],
      setCategories: categories => set({ categories }),
      showPhoneError: false,
      setShowPhoneError: showPhoneError => set({ showPhoneError }),
    }),
    {
      name: "user-store",
      storage: createJSONStorage(() => localStorage),
      partialize: state => ({ user: state.user }),
    }
  )
);

type UserType = "company" | "candidate";

interface AuthState {
  isAuthenticated: boolean;
  userType: UserType | null;
  userId: string | null;
  login: (userType: UserType, userId: string) => void;
  logout: () => void;
}

export const useAuthStore = create<AuthState>()(
  persist(
    set => ({
      isAuthenticated: false,
      userType: null,
      userId: null,
      login: (userType, userId) => set({ isAuthenticated: true, userType, userId }),
      logout: () => set({ isAuthenticated: false, userType: null, userId: null }),
    }),
    {
      name: "auth-storage",
    }
  )
);

export const storage = window.localStorage;

export function getItem<T>(key: string): T | null {
  const value = storage.getItem(key);

  if (!value) {
    return null;
  }

  // if it's a token, return the raw value
  if (key === "meditoken" || key === "meditoken_exp") {
    return value as unknown as T;
  }

  // for other values, parse as JSON
  try {
    return JSON.parse(value);
  } catch (error) {
    console.error(`Failed to parse item with key ${key}:`, error);
    return null;
  }
}

export function setItem<T>(key: string, value: T): void {
  // if it's a token, store it as a raw string
  if (key === "meditoken" || key === "meditoken_exp") {
    storage.setItem(key, value as string);
  } else {
    // for other values, continue using JSON.stringify
    storage.setItem(key, JSON.stringify(value));
  }
}

export function removeItem(key: string): void {
  try {
    storage.removeItem(key);
  } catch (error) {
    console.error(`Failed to remove item with key ${key}:`, error);
  }
}
