import { AxiosV2 } from "@/libs/utils/axios-interceptor";
import { createMutation } from "react-query-kit";

interface SeeCandidateProps {
  processId: string;
}

export const useSeeCandidate = createMutation({
  mutationFn: async ({ processId }: SeeCandidateProps) => AxiosV2.post(`/recruitment/process/${processId}/seen/`),
});
