import { createMutation } from "react-query-kit";
import { AxiosV2 } from "@/libs/utils/axios-interceptor";

interface ProposedTime {
  start_time: string;
  end_time: string;
  status: "proposed";
}

export type InterviewLocation = "offline" | "virtual" | "phone" | "physical";
export interface ProposeInterviewTimePayload {
  title: string;
  description: string;
  event_type: number;
  participants: number[];
  status: "proposed";
  requester: number;
  content_type: number;
  related_object: number | null;
  location?: InterviewLocation;
  location_details: string;
  proposed_times: ProposedTime[];
  object_id: number;
  metadata?: {
    interview_type?: string;
    address?: string;
    contact_person?: string;
    phone?: string;
  };
}

interface ProposeInterviewResponse {
  id: number;
  status: string;
}

export const useProposeInterviewTime = createMutation<ProposeInterviewResponse, ProposeInterviewTimePayload>({
  mutationFn: async payload => {
    const data = await AxiosV2.post("/event-management/events/", payload);

    return data.data as ProposeInterviewResponse;
  },
});
