import { Axios } from "@/libs/utils/axios-interceptor";
import { createMutation } from "react-query-kit";

interface AcceptOfferProps {
  offerId: string;
  date: Date;
}

export const useAcceptOffer = createMutation({
  mutationFn: async ({ offerId, date }: AcceptOfferProps) =>
    Axios.post(`/offers/${offerId}/schedulevalidation`, { validation_interview_date: date }),
});
