import Input from "@/components/input/input";
import { FormattedMessage, useIntl } from "react-intl";

import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/form";
import { Checkbox } from "@/components/checkbox";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Select, SelectContent, SelectItem, SelectTrigger } from "@/components/select/select";
import { useFetchCategories } from "@/libs/hooks/useFetchCategories";
import { useState, useMemo, useCallback, useEffect } from "react";
import { SelectValue } from "@radix-ui/react-select";
import { formSchemaType } from "@/components/Layouts/onboarding";
import { Typography } from "@/components/typography";
import {
  MultiSelector,
  MultiSelectorContent,
  MultiSelectorInput,
  MultiSelectorItem,
  MultiSelectorList,
  MultiSelectorTrigger,
} from "@/components/select/multiselect";
import { Badge } from "@/components/badge";
import { IoCloseSharp as RemoveIcon } from "react-icons/io5";
import { cn } from "@/libs/utils/cn";
import MDPhoneInput from "@/components/phoneInput";
import { messages } from "@/pages/auth/auth-data";
import { Link } from "@tanstack/react-router";
import { store } from "@/libs/store";

interface IPAPIResponse {
  country_code: string;
}
const sortCategories = (categories: ApiCategory[]) =>
  categories.sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0));

const sortAlphabetically = <T extends { value: string }>(items: T[]): T[] =>
  [...items].sort((a, b) => a.value.localeCompare(b.value));

const FormOne = () => {
  const { control } = useFormContext<formSchemaType>();
  const { fields } = useFieldArray({
    control,
    name: "categories",
  });
  const intl = useIntl();

  const [defaultCountry, setDefaultCountry] = useState("ro");

  const getCountryFromIP = async () => {
    try {
      const response = await fetch("https://ipapi.co/json/");
      const data = (await response.json()) as IPAPIResponse;

      if (typeof data.country_code === "string") {
        setDefaultCountry(data.country_code.toLowerCase());
      } else {
        setDefaultCountry("ro");
      }
    } catch (error) {
      console.error("Error getting country from IP:", error);
      setDefaultCountry("ro");
    }
  };

  useEffect(() => {
    getCountryFromIP().catch(console.error);
  }, []);

  const [subCategories, setSubCategories] = useState<ApiSubcategory[]>([]);
  const [availableTags, setAvailableTags] = useState<ApiTag[]>([]);
  const { showPhoneError, setShowPhoneError } = store();

  const { data: categories } = useFetchCategories();

  const sorted_cats = useMemo(() => sortCategories(categories), [categories]);

  const handleCategorySelect = useCallback(
    (value: string) => {
      const category = sorted_cats.find(category => category.id === parseInt(value));

      setSubCategories(sortAlphabetically(category?.subcategories ?? []));
      setAvailableTags(sortAlphabetically(category?.tags ?? []));
    },
    [sorted_cats]
  );

  const handleSubCategorySelect = useCallback(
    (values: string[]) => {
      const selectedSubCategories = subCategories.filter(subCat => values.includes(subCat.id.toString()));
      const subCategoryTags = selectedSubCategories.flatMap(subCat => subCat.tags ?? []);

      setAvailableTags(prevTags => {
        const newTags = [...prevTags, ...subCategoryTags];

        return Array.from(new Set([...prevTags, ...subCategoryTags].map(tag => tag.id))).map(
          // eslint-disable-next-line max-nested-callbacks
          id => newTags.find(tag => tag.id === id)!
        );
      });
    },
    [subCategories]
  );

  return (
    <div className="space-y-3">
      <FormField
        control={control}
        name="name"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              <FormattedMessage id="onboarding.candidate.steps.one.name" defaultMessage="Full Name" />
            </FormLabel>
            <FormControl>
              <Input
                {...field}
                placeholder={intl.formatMessage({
                  id: "onboarding.candidate.steps.one.name_placeholder",
                  defaultMessage: "Enter your full name",
                })}
                type="text"
              />
            </FormControl>
            <FormMessage className="text-red-600" />
          </FormItem>
        )}
      />

      {fields[0] && (
        <FormField
          key={fields[0].id}
          control={control}
          name={`categories.${0}.category_id`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <FormattedMessage id="onboarding.candidate.steps.one.category" defaultMessage="Category" />
              </FormLabel>
              <Typography variant="muted">
                <FormattedMessage
                  id="onboarding.candidate.steps.one.category_hint"
                  defaultMessage="What is your current job title?"
                />
              </Typography>
              <FormControl>
                <Select
                  value={field.value}
                  onValueChange={(value: string) => {
                    field.onChange(value);
                    handleCategorySelect(value);
                  }}
                >
                  <SelectTrigger>
                    <SelectValue
                      placeholder={intl.formatMessage({
                        id: "onboarding.candidate.steps.one.category_placeholder",
                        defaultMessage: "Select Category",
                      })}
                    />
                  </SelectTrigger>
                  <SelectContent>
                    {sorted_cats.length > 0 &&
                      sorted_cats.map((option, i) => (
                        <SelectItem value={`${option.id}`} key={i}>
                          {option.value}
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage className="text-red-600" />
            </FormItem>
          )}
        />
      )}

      {subCategories?.length > 0 && (
        <FormField
          key={fields[0].id}
          control={control}
          name={`categories.${0}.subcategories`}
          render={({ field }) => (
            <FormItem className="">
              <FormLabel>
                <FormattedMessage id="onboarding.candidate.steps.one.sub_category" defaultMessage="Sub Category" />
              </FormLabel>
              <Typography variant="muted">
                <FormattedMessage
                  id="onboarding.candidate.steps.one.sub_category_hint"
                  defaultMessage="In which medical specialty do you work or have you worked?"
                />
              </Typography>
              <FormControl>
                <MultiSelector
                  values={field.value?.map(subCat => subCat.subcategory_id.toString()) ?? []}
                  onValuesChange={values => {
                    field.onChange(
                      values.map(value => ({
                        subcategory_id: value,
                      }))
                    );
                    handleSubCategorySelect(values);
                  }}
                >
                  <MultiSelectorTrigger>
                    <MultiSelectorInput
                      placeholder={intl.formatMessage({
                        id: "onboarding.candidate.steps.one.sub_category_placeholder",
                        defaultMessage: "Select Sub Categories",
                      })}
                    />
                  </MultiSelectorTrigger>
                  <MultiSelectorContent>
                    <MultiSelectorList>
                      {subCategories.length > 0 &&
                        subCategories.map((option, i) => (
                          <MultiSelectorItem key={i} value={`${option.id}`}>
                            {option.value}
                          </MultiSelectorItem>
                        ))}
                    </MultiSelectorList>
                  </MultiSelectorContent>
                </MultiSelector>
              </FormControl>
              <FormMessage className="text-red-600" />
              <div className="flex gap-2 items-center flex-wrap mt-2">
                {field.value &&
                  field.value.map((item: { subcategory_id: string; years?: string }) => (
                    <Badge
                      key={item.subcategory_id}
                      className={cn("px-1 rounded-xl flex items-center gap-1 w-max")}
                      variant="default"
                    >
                      <span className="text-xs">
                        {subCategories.find(cat => cat.id.toString() === item.subcategory_id)?.value}
                      </span>
                      <button
                        aria-label={`Remove ${item.subcategory_id} option`}
                        aria-roledescription="button to remove option"
                        type="button"
                        onClick={() => {
                          field.onChange(
                            field.value?.filter(
                              (v: { subcategory_id: string; years?: string }) =>
                                v.subcategory_id !== item.subcategory_id
                            ) ?? []
                          );
                        }}
                      >
                        <span className="sr-only">Remove {item.subcategory_id} option</span>
                        <RemoveIcon className="h-4 w-4 hover:stroke-destructive" />
                      </button>
                    </Badge>
                  ))}
              </div>
            </FormItem>
          )}
        />
      )}

      {availableTags.length > 0 && (
        <FormField
          key={fields[0].id}
          control={control}
          name={`categories.${0}.tags`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <FormattedMessage id="onboarding.candidate.steps.one.tags_label" defaultMessage="Competencies" />
              </FormLabel>
              <Typography variant="muted">
                <FormattedMessage
                  id="onboarding.candidate.steps.one.tags_hint"
                  defaultMessage="Select relevant Competencies for your profile"
                />
              </Typography>
              <FormControl>
                <MultiSelector values={field.value ?? []} onValuesChange={field.onChange}>
                  <MultiSelectorTrigger>
                    <MultiSelectorInput
                      placeholder={intl.formatMessage({
                        id: "onboarding.candidate.steps.one.tags_placeholder",
                        defaultMessage: "Select Tags",
                      })}
                    />
                  </MultiSelectorTrigger>
                  <MultiSelectorContent>
                    <MultiSelectorList>
                      {availableTags.map(tag => (
                        <MultiSelectorItem key={tag.id} value={`${tag.id}`}>
                          {tag.value}
                        </MultiSelectorItem>
                      ))}
                    </MultiSelectorList>
                  </MultiSelectorContent>
                </MultiSelector>
              </FormControl>
              <FormMessage className="text-red-600" />
              <div className="flex gap-2 items-center flex-wrap mt-2">
                {field.value &&
                  field.value.map((tagId: string) => {
                    const tag = availableTags.find(t => t.id === parseInt(tagId));

                    return tag ? (
                      <Badge
                        key={tag.id}
                        className={cn("px-1 rounded-xl flex items-center gap-1 w-max")}
                        variant="default"
                      >
                        <span className="text-xs">{tag.value}</span>
                        <button
                          aria-label={`Remove ${tag.value} tag`}
                          aria-roledescription="button to remove tag"
                          type="button"
                          onClick={() => {
                            field.onChange(field.value?.filter((v: string) => v !== tag.id.toString()) ?? []);
                          }}
                        >
                          <span className="sr-only">Remove {tag.value} tag</span>
                          <RemoveIcon className="h-4 w-4 hover:stroke-destructive" />
                        </button>
                      </Badge>
                    ) : null;
                  })}
              </div>
            </FormItem>
          )}
        />
      )}

      <FormField
        control={control}
        name="phone"
        render={({ field }) => (
          <FormItem className="w-full">
            <FormLabel>
              <FormattedMessage id="onboarding.candidate.steps.one.phone" defaultMessage="Phone" />
            </FormLabel>
            <MDPhoneInput
              onChange={(phone: string) => {
                field.onChange(phone);
              }}
              onBlur={field.onBlur}
              onFocus={() => setShowPhoneError(true)}
              value={field.value}
              defaultCountry={defaultCountry}
              placeholder={messages.ro.funnel.candidate.phonePlacehold}
            />
            {showPhoneError && <FormMessage className="text-red-600" />}
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="email"
        render={({ field }) => (
          <FormItem>
            {/* An Optional label */}
            <FormLabel>
              <FormattedMessage id="onboarding.candidate.steps.one.email" defaultMessage="Email" />
            </FormLabel>
            <FormControl>
              {/* The actual form input element */}
              <Input
                {...field}
                placeholder={intl.formatMessage({
                  id: "onboarding.candidate.steps.one.email_placeholder",
                  defaultMessage: "Enter your email address",
                })}
                type="email"
              />
            </FormControl>
            {/* Error message goes here */}
            <FormMessage className="text-red-600" />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="password"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              <FormattedMessage id="onboarding.candidate.steps.one.password" defaultMessage="Password" />
            </FormLabel>
            <FormControl>
              <Input
                {...field}
                placeholder={intl.formatMessage({
                  id: "onboarding.candidate.steps.one.password_placeholder",
                  defaultMessage: "Enter your password",
                })}
                type="password"
              />
            </FormControl>
            <FormMessage className="text-red-600" />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="confirm_password"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              <FormattedMessage
                id="onboarding.candidate.steps.one.confirm_password"
                defaultMessage="Confirm Password"
              />
            </FormLabel>
            <FormControl>
              <Input
                {...field}
                placeholder={intl.formatMessage({
                  id: "onboarding.candidate.steps.one.confirm_password_placeholder",
                  defaultMessage: "Confirm your password",
                })}
                type="password"
              />
            </FormControl>
            <FormMessage className="text-red-600" />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="terms"
        render={({ field }: { field: { value: boolean; onChange: (value: boolean) => void } }) => (
          <FormItem>
            <FormControl>
              <div className="flex items-center gap-2 mt-6">
                <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                <FormLabel>
                  <FormattedMessage
                    id="onboarding.candidate.steps.one.terms"
                    defaultMessage="To proceed, please confirm that you agree to the <link>MEDIjobs Terms and Conditions</link> *"
                    values={{
                      link: (chunks: React.ReactNode) => (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://app.medijobs.ro/tos"
                          className="text-medi-button underline hover:text-medi-button"
                        >
                          {chunks}
                        </a>
                      ),
                    }}
                  />
                </FormLabel>
              </div>
            </FormControl>
            <FormMessage className="text-red-600" />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="receive_notifications"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <div className="flex items-center gap-2 mt-6">
                <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                <FormLabel>
                  <FormattedMessage
                    id="onboarding.candidate.steps.one.receive_notifications"
                    defaultMessage="Vă rugăm să selectați preferințele de notificare"
                  />
                </FormLabel>
              </div>
            </FormControl>
            <FormMessage className="text-red-600" />
          </FormItem>
        )}
      />
      <div className="flex gap-1">
        <Typography>
          <FormattedMessage id="common.already_have_an_account" defaultMessage="Already have an account?" />
        </Typography>
        <Link to="/login" className="text-medi-blue underline hover:text-medi-blue">
          <FormattedMessage id="common.log_in" defaultMessage="Log in!" />
        </Link>
      </div>
    </div>
  );
};

export default FormOne;
