import { AxiosV2 } from "@/libs/utils/axios-interceptor";
import { createMutation } from "react-query-kit";

interface SaveCompanyInterviewPresenceProps {
  interviewId: string;
  isCompanyPresent: boolean;
}

export const useSaveCompanyInterviewPresence = createMutation({
  mutationFn: async ({ interviewId, isCompanyPresent }: SaveCompanyInterviewPresenceProps) =>
    AxiosV2.post(`/recruitment/interview/${interviewId}/save-company-presence/`, { present: isCompanyPresent }),
});
