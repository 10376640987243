import { BsChatSquareText } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";
import { HiChevronRight } from "react-icons/hi2";
import { Link } from "@tanstack/react-router";
import { Typography } from "@/components/typography";
import { memo } from "react";

interface JobCardItemProps {
  title: string;
  location: string;
  subtitle: string;
  to: string;
}

const JobCardItem = memo(({ title, location, subtitle, to }: JobCardItemProps) => (
  <Link to={to} className="p-4 rounded-lg bg-gray-50 hover:bg-gray-100 transition-colors group">
    <div className="flex items-start justify-between">
      <div className="flex-1">
        <Typography className="text-medi-blue md:text-lg font-medium mb-2">{title}</Typography>
        <div className="flex items-center gap-4 text-gray-600 text-sm">
          <div className="flex items-center gap-2">
            <IoLocationOutline className="text-gray-500" />
            <span>{location}</span>
          </div>
          <div className="flex items-center gap-2">
            <BsChatSquareText className="text-gray-500" />
            <span>{subtitle}</span>
          </div>
        </div>
      </div>
      <HiChevronRight className="text-gray-400 text-xl group-hover:text-medi-blue transition-colors" />
    </div>
  </Link>
));

JobCardItem.displayName = "JobCardItem";

export default JobCardItem;
