import { messages } from "./auth-data";
import CandidatImage from "@/assets/images/candidati.svg";
import CompanyImage from "@/assets/images/angajator.svg";

import { Link } from "@tanstack/react-router";

const RegisterPage = () => (
  <div className="flex items-start justify-center flex-col gap-6 py-10 md:max-w-md mx-auto ">
    <div className="">
      <h1 className="text-2xl font-semibold">{messages.ro.funnel.common.chooseAccountType}</h1>
    </div>

    <div className="flex gap-4 flex-col md:flex-row w-full justify-center">
      <Link
        className="flex items-center justify-center flex-col w-full h-52 md:w-52 md:aspect-square gap-2 shadow-md rounded hover:scale-105 transition-transform duration-300 ease-in-out"
        to="/onboarding/candidate"
      >
        <img alt="" src={CandidatImage} className="" />

        <div className="">
          <b className="no-underline">{messages.ro.funnel.common.candidate}</b>
        </div>
      </Link>

      <Link
        className="flex items-center justify-center flex-col gap-2 w-full h-52 md:w-52 md:aspect-square shadow-md rounded hover:scale-105 transition-transform duration-300 ease-in-out"
        to="/register/company"
      >
        <img alt="" src={CompanyImage} />

        <div className="">
          <b>{messages.ro.funnel.common.employer}</b>
        </div>
      </Link>
    </div>
  </div>
);

export default RegisterPage;
