import { Axios } from "@/libs/utils/axios-interceptor";

import { createMutation } from "react-query-kit";

export interface CreatePasswordPayload {
  email: string;
  password: string;
  confirm_password: string;
  token: string;
}

interface CreatePasswordResponse {
  token: string;
  error?: string;
}

export const useCreatePassword = createMutation<CreatePasswordResponse, CreatePasswordPayload>({
  mutationFn: async payload => {
    const response = await Axios.post<CreatePasswordResponse>("/reset-password", payload);

    return response.data;
  },
});
