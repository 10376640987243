/* eslint-disable import/no-duplicates */
import { cn } from "@/libs/utils/cn";
import { Typography } from "../typography";
import { format } from "date-fns";
import { Button } from "../button/Button";
import { ro } from "date-fns/locale";
import { formatInterviewType } from "@/pages/offer/candidate";

interface InterviewTimeSelectorProps {
  proposedTimes: Array<{
    id: number;
    start_time: string;
    end_time: string;
  }>;
  selectedTimeId?: number;
  onTimeSelect: (timeId: number) => void;
  onAccept: () => void;
  onReject: () => void;
  isLoading?: boolean;
  title?: string;
  interviewType?: "offline" | "phone" | "virtual";
  entityName?: string;
  isCompany?: boolean;
}

export const InterviewTimeSelector = ({
  proposedTimes = [],
  selectedTimeId,
  onTimeSelect,
  onAccept,
  onReject,
  isLoading = false,
  interviewType = "offline",
  entityName,
  title = "Reprogrameaza interviul",
  isCompany = false,
}: InterviewTimeSelectorProps) => (
  <div className="flex-1 bg-white p-4 md:p-6 rounded-lg shadow h-max md:max-w-[400px] max-sm:w-full max-sm:order-1">
    <div className="flex flex-col gap-4">
      {isCompany && (
        <Typography className="text-gray-600 md:text-lg">
          Candidatul {entityName} a propus următoarele intervale pentru interviu.
        </Typography>
      )}
      <div className="flex items-center gap-2">
        <Typography className="text-gray-500 md:text-lg ">Tipul interviului:</Typography>
        <Typography className="text-gray-800 md:text-lg font-semibold capitalize">
          {formatInterviewType(interviewType)}
        </Typography>
      </div>
      <Typography className="text-gray-800 md:text-lg font-semibold">{title} </Typography>

      {isCompany && (
        <Typography className="text-gray-500 md:text-base">
          Te rugam sa alegi una dintre optiunile propuse de candidat pentru interviu sau sa propui o alta data.
        </Typography>
      )}
      {!isCompany && (
        <Typography className="text-gray-500 md:text-base">
          Mai jos sunt noile optiuni pentru interviu de la <b>{entityName}</b>. Te rugam sa alegi una dintre ele sa
          propui alte date daca nu poti participa in intervalele de mai jos.
        </Typography>
      )}
      <div className="relative">
        <Typography className="font-semibold">Alege una dintre optiuni:</Typography>

        <div className="flex flex-col gap-2">
          {proposedTimes.map((slot, index) => (
            <button
              onClick={() => onTimeSelect(slot.id)}
              key={index}
              className={cn(
                "border rounded-md px-3 py-2 w-full",
                selectedTimeId === slot.id && "border-medi-blue bg-medi-blue/10"
              )}
            >
              <div className="flex items-center gap-3 justify-between">
                <span className="">{format(new Date(slot.start_time), "dd MMM. yyyy", { locale: ro })}</span>
                <span>{format(new Date(slot.start_time), "HH:mm")}</span>
              </div>
              <div className="flex items-center justify-between text-gray-400">
                <span className="">{format(new Date(slot.start_time), "EEEE", { locale: ro })}</span>
                <span>{format(new Date(slot.end_time), "HH:mm")}</span>
              </div>
            </button>
          ))}
        </div>

        <div className="flex gap-2 mt-4">
          <Button
            variant="default"
            className="flex-1"
            loading={isLoading}
            disabled={!selectedTimeId || isLoading}
            onClick={onAccept}
          >
            Programează interviu
          </Button>
          <Button variant="outline" className="flex-1" onClick={onReject} loading={isLoading}>
            Seteaza alte date
          </Button>
        </div>
      </div>
    </div>
  </div>
);
