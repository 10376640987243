import { loginSchema } from "@/libs/utils/schemas/login";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import Input from "@/components/input/input";
import { Button } from "@/components/button/Button";
import { Link, useNavigate } from "@tanstack/react-router";
import MedijobsLogo from "@/assets/images/medijobs_logo_subtext.svg";

import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/form";
import { useLogin } from "@/core/hooks/mutations/useLogin";
import { toast } from "sonner";
import { FormattedMessage, useIntl } from "react-intl";
import { Typography } from "@/components/typography";
import { useEffect, useState } from "react";
import { useGetAccount } from "@/core/hooks/useGetAccount";
import { setAngularCredentials } from "@/core/auth/utils";
import { useAuth } from "@/core/auth";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";

type LoginForm = z.infer<typeof loginSchema>;

export enum ProfileType {
  Undefined,
  Company,
  Candidate,
}

const LoginPage = () => {
  const { mutateAsync, isPending, isSuccess } = useLogin();
  const signIn = useAuth.use.signIn();
  const intl = useIntl();
  const navigate = useNavigate();

  const [token, setToken] = useState("");

  const { data: accountData } = useGetAccount(token);

  const form = useForm<LoginForm>({
    resolver: zodResolver(loginSchema),
  });

  useDocumentTitle("Login");

  useEffect(() => {
    const expirationDate = new Date();

    if (isSuccess && accountData) {
      let data: Credentials;

      expirationDate.setFullYear(expirationDate.getFullYear() + 1);
      localStorage.setItem("meditoken_exp", expirationDate.toISOString());

      if (accountData?.user.company) {
        data = {
          name: accountData.user.company.contact_name,
          companyName: accountData.user.company.name,
          credits: accountData.user.company.credits,
          profileCompletion: accountData.completeness,
          profileType: ProfileType.Company,
        };

        setAngularCredentials(data);
        signIn({
          access_token: token,
        });

        void navigate({ to: "/dashboard/company" });
      } else if (accountData?.user.candidate) {
        data = {
          name: accountData.user.candidate.name,
          companyName: undefined,
          credits: 0,
          profileCompletion: accountData.completeness,
          profileType: ProfileType.Candidate,
        };

        setAngularCredentials(data);
        signIn({
          access_token: token,
        });
        void navigate({ to: "/dashboard/candidate" });
      }

      toast.success("Login successful");
    }
  }, [isSuccess, accountData]);

  const handleLoginSubmit = async (data: LoginForm) => {
    await mutateAsync(data, {
      onSuccess: res => {
        setToken(res.token);
        console.log(res);
      },
      onError: () => {
        console.log("error");
        toast.custom(
          t => (
            <div className="flex flex-col gap-2 bg-white p-4 rounded-lg shadow-lg border-l-4 border-red-500">
              <div className="font-medium text-red-600">
                {intl.formatMessage({ id: "login.invalid_email_or_password" })}
              </div>
              <div className="text-sm text-gray-600">
                {intl.formatMessage({ id: "login.try_reset_password" })}:
                <button
                  onClick={() => {
                    toast.dismiss(t);
                    void navigate({ to: "/password/reset" });
                  }}
                  className="text-medi-blue hover:underline ml-1"
                >
                  {intl.formatMessage({ id: "login.reset_password_link" })}
                </button>
              </div>
            </div>
          ),
          {
            duration: 20000,
          }
        );
      },
    });
  };

  return (
    <div className="md:max-w-lg md:mx-auto">
      <Form {...form}>
        <img alt="Medijobs Logo" src={MedijobsLogo} width={150} />

        <div className="mt-10">
          <Typography variant="h2">
            <FormattedMessage id="common.auth" />
          </Typography>

          <form className="mt-4 flex flex-col gap-3" onSubmit={form.handleSubmit(handleLoginSubmit)}>
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <FormattedMessage id="login.email" />
                  </FormLabel>
                  <FormControl>
                    <Input {...field} placeholder={intl.formatMessage({ id: "login.email" })} type="text" />
                  </FormControl>
                  <FormMessage className="text-red-500" />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <FormattedMessage id="login.password" />
                  </FormLabel>
                  <FormControl>
                    <Input {...field} placeholder={intl.formatMessage({ id: "login.password" })} type="password" />
                  </FormControl>
                  <FormMessage className="text-red-500" />
                </FormItem>
              )}
            />

            <Button className="mt-2" variant="default" loading={isPending}>
              {intl.formatMessage({ id: "common.auth" })}
            </Button>
          </form>

          <Link className="mt-4 block text-medi-blue" to="/password/reset">
            <FormattedMessage id="login.forgot_password" />
          </Link>

          <div className="flex mt-4">
            <FormattedMessage id="login.no_account" />
            <Link className="text-medi-blue ml-1" to="/register">
              {intl.formatMessage({ id: "login.create_account" })}
            </Link>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default LoginPage;
