import LoginPage from "@/pages/auth/login";
import { createFileRoute } from "@tanstack/react-router";

import { z } from "zod";

const searchParamsSchema = z.object({
  redirect: z.string().optional(),
});

export type LoginSearch = z.infer<typeof searchParamsSchema>;

export const Route = createFileRoute("/_auth-layout/login")({
  component: () => <LoginPage />,
  validateSearch: searchParamsSchema,
});
