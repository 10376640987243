import { useFetchCandidate } from "@/libs/hooks/useFetchCandidate";
import { useEffect, useState, useMemo } from "react";
import { createIntl } from "react-intl";
import enMessages from "@/i18n/messages/en/dashboard";
import roMessages from "@/i18n/messages/ro/dashboard";
import { useFetchOffers } from "@/libs/hooks/useFetchOffers";

interface UseGetTasksProps {
  entity: "candidate" | "company";
}

interface UseGetTasksReturn {
  urgent: string[];
  important: string[];
  newOffers: IFeedbackModel[];
}

export interface IFeedbackModel {
  offer_id: number;
  offer_title: string;
  company_name: string;
  archived: number;
}

const useGetTasks = ({ entity }: UseGetTasksProps) => {
  const locale = (import.meta.env.VITE_APP_LOCALE as unknown as string) || "ro-RO";
  const messages = locale.startsWith("en") ? enMessages : roMessages;

  const intl = useMemo(
    () =>
      createIntl({
        locale,
        messages: {
          "onboarding.tasks.desired_salary": messages.tasks.desired_salary,
          "onboarding.tasks.job_titles_and_obtained_specializations":
            messages.tasks.job_titles_and_obtained_specializations,
          "onboarding.tasks.cities_where_you_wish_to_work": messages.tasks.cities_where_you_wish_to_work,
          "onboarding.tasks.professional_experience": messages.tasks.professional_experience,
          "onboarding.tasks.education": messages.tasks.education,
        },
      }),
    [locale]
  );

  const { data: candidate } = useFetchCandidate(entity === "candidate");
  const { data: offers } = useFetchOffers();

  const newOffers = useMemo(() => {
    if (!offers) {
      return [];
    }

    return offers
      .filter(offer => offer.interviewCount === 0)
      .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      .map(offer => ({
        offer_id: offer.id,
        offer_title: offer.position,
        company_name: offer.city,
        archived: offer.need.archived ? 1 : 0,
      }))
      .filter(offer => offer.archived === 0);
  }, [offers]);

  const [tasks, setTasks] = useState<UseGetTasksReturn>({
    urgent: [],
    important: [],
    newOffers: newOffers ?? [],
  });

  // first effect to handle candidate-related tasks
  useEffect(() => {
    if (!candidate) {
      return;
    }

    const tasksToAdd = {
      urgent: [] as string[],
      important: [] as string[],
    };

    const taskChecks = [
      {
        condition: !candidate?.domain_experiences?.length,
        message: "onboarding.tasks.job_titles_and_obtained_specializations",
        type: "urgent" as const,
      },
      {
        condition: !candidate?.desired_salary,
        message: "onboarding.tasks.desired_salary",
        type: "urgent",
      },
      {
        condition: !candidate?.cities?.length,
        message: "onboarding.tasks.cities_where_you_wish_to_work",
        type: "urgent",
      },
      {
        condition: !candidate?.experience?.length,
        message: "onboarding.tasks.professional_experience",
        type: "important",
      },
      {
        condition: !candidate?.education?.length,
        message: "onboarding.tasks.education",
        type: "important",
      },
    ] as const;

    taskChecks.forEach(({ condition, message, type }) => {
      if (condition) {
        const formattedMessage = intl.formatMessage({ id: message });

        tasksToAdd[type].push(formattedMessage);
      }
    });

    setTasks(prev => ({
      ...prev,
      urgent: [...new Set(tasksToAdd.urgent)],
      important: [...new Set(tasksToAdd.important)],
    }));
  }, [candidate, intl]);

  return tasks;
};

export default useGetTasks;
