export const TESTIMONIALS = [
  {
    name: "Dr. Andreea Sima",
    title: "Medic specialist Medicină de laborator",
    image: "https://app.medijobs.ro/assets/andreea_sima.png",
    quote:
      " Am fost plăcut impresionată să văd implicarea şi promptitudinea de care a dat dovadă echipa celor de la MEDIjobs. Cu siguranţă îi voi recomanda şi celor interesaţi să-şi găsească un loc de muncă rapid şi sigur!",
  },
  {
    name: "Elena Georgescu",
    title: "Asistent medical generalist",
    image: "https://app.medijobs.ro/assets/elena_georgescu.png",
    quote:
      "La MEDIjobs găsești bunătate, înțelegere și o mână întinsă pentru un job pe care unii oameni îl găsesc foarte greu. Aici ţi se dă şansa de a fi ascultat şi poţi primi ce îţi doreşti, atât profesional cât și financiar. Îmi place și respect echipa MEDIjobs!",
  },
];
