import { useState } from "react";

import { Button } from "@/components/button/Button";
import MedijobsLogo from "@/assets/images/medijobs_logo_subtext.svg";

import Input from "@/components/input/input";
import { Link } from "@tanstack/react-router";
import { Typography } from "@/components/typography";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { resetPasswordSchema } from "@/libs/utils/schemas/reset-password";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useIntl } from "react-intl";
import { useForgotPassword } from "@/core/hooks/mutations/useForgotPassword";
import { toast } from "sonner";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";

type FormType = z.infer<typeof resetPasswordSchema>;

const PasswordResetPage = () => {
  const form = useForm<FormType>({
    resolver: zodResolver(resetPasswordSchema),
  });

  const { mutateAsync, isPending } = useForgotPassword();

  const intl = useIntl();

  useDocumentTitle("Restabilire parola");

  const [formSuccess, setFormSuccess] = useState(false);

  const handleLoginSubmit = async (data: FormType) => {
    await mutateAsync(data, {
      onSuccess: () => {
        setFormSuccess(true);
      },
      onError: () => {
        toast.error(intl.formatMessage({ id: "errors.something_went_wrong" }));
      },
    });
  };

  return (
    <div className="md:max-w-lg md:mx-auto">
      <img alt="Medijobs Logo" src={MedijobsLogo} width={150} />

      <div className="mt-6">
        <Typography variant="h2">Restabilește parola</Typography>

        <Typography variant="p">{intl.formatMessage({ id: "login.reset_password_description" })}</Typography>

        {!formSuccess ? (
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleLoginSubmit)} className="flex flex-col mt-2  gap-6">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{intl.formatMessage({ id: "login.email" })}</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder={intl.formatMessage({ id: "login.email" })} type="text" />
                    </FormControl>
                    <FormMessage className="text-red-500" />
                  </FormItem>
                )}
              />

              <Button disabled={isPending} loading={isPending} type="submit" variant="default">
                Restabiliește
              </Button>
            </form>

            <div className="flex mt-4 gap-1">
              {intl.formatMessage({ id: "login.no_account" })}
              <Link className="text-medi-blue" to="/register">
                {intl.formatMessage({ id: "login.create_account" })}
              </Link>
            </div>
          </Form>
        ) : (
          <Typography className="mt-4" variant="h2">
            {intl.formatMessage({ id: "login.reset_password_success" })}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default PasswordResetPage;
