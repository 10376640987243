import { AxiosV2 } from "@/libs/utils/axios-interceptor";
import { useQuery } from "@tanstack/react-query";

interface UseGetAvailabilityProps {
  processId: string;
  shouldFetch?: boolean;
}

export interface useGetAvailabilityResponse {
  start_date: string;
  end_date: string;
  intervals: ApiAvailability[];
}

export const useGetAvailability = ({ processId, shouldFetch = false }: UseGetAvailabilityProps) => {
  const { data, isLoading } = useQuery({
    queryKey: ["availability", processId],
    queryFn: async () => {
      const { data } = await AxiosV2.get<useGetAvailabilityResponse>(`recruitment/need/${processId}/availability/`);

      return data;
    },
    enabled: !!processId && shouldFetch,
  });

  return { data, isLoading };
};
