import { removeItem, getItem, setItem } from "@/libs/store";

const SESSION = "user-session";
const ANGULAR_TOKEN = "meditoken";
const ANGULAR_CREDENTIALS = "credentials";

export const getSession = () => getItem<Session>(SESSION);
export const removeSession = () => removeItem(SESSION);
export const setSession = (value: Session) => setItem<Session>(SESSION, value);

// angular token
export const getAngularToken = () => getItem<string>(ANGULAR_TOKEN);
export const removeAngularToken = () => removeItem(ANGULAR_TOKEN);
export const setAngularToken = (value: string) => setItem<string>(ANGULAR_TOKEN, value);

// angular Credentials
export const getAngularCredentials = () => getItem<Credentials>(ANGULAR_CREDENTIALS);
export const removeAngularCredentials = () => removeItem(ANGULAR_CREDENTIALS);
export const setAngularCredentials = (value: Credentials) => setItem<Credentials>(ANGULAR_CREDENTIALS, value);
