import { AxiosV2 } from "@/libs/utils/axios-interceptor";
import { createMutation } from "react-query-kit";

interface CancelEventPayload {
  event_id: number;
  reason: string;
}

interface CancelEventResponse {
  id: number;
  status: string;
}

export const useCancelEvent = createMutation<CancelEventResponse, CancelEventPayload>({
  mutationFn: async payload => {
    const data = await AxiosV2.post(`/event-management/events/${payload.event_id}/cancel/`, {
      reason: payload.reason,
    });

    return data.data as CancelEventResponse;
  },
});
