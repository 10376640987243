"use client";

import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { FaSquareCheck } from "react-icons/fa6";
import clsx from "clsx";

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={clsx(
      "h-4 w-4 shrink-0 rounded-sm border  focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-medi-blue disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-white data-[state=checked]:text-white",
      className
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator className={clsx("flex items-center justify-center text-current")}>
      <FaSquareCheck className="h-4 w-4 text-medi-blue" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));

Checkbox.displayName = "Checkbox";
Checkbox.propTypes = CheckboxPrimitive.Root.propTypes;
export { Checkbox };
