import { Typography } from "@/components/typography";

import { FormField, FormItem, FormMessage } from "@/components/form";
import {
  MultiSelector,
  MultiSelectorContent,
  MultiSelectorInput,
  MultiSelectorItem,
  MultiSelectorList,
  MultiSelectorTrigger,
} from "@/components/select/multiselect";
import { Badge } from "@/components/badge";
import { cn } from "@/libs/utils/cn";

import { IoCloseSharp as RemoveIcon } from "react-icons/io5";
import { FormattedMessage, useIntl } from "react-intl";
import { useFormContext } from "react-hook-form";
import { formSchemaType } from "@/components/Layouts/onboarding";

const StepTenForm = () => {
  const intl = useIntl();
  const form = useFormContext<formSchemaType>();

  const communicationOptions = [
    {
      label: intl.formatMessage({ id: "common.contact_methods.phone_call", defaultMessage: "Phone call" }),
      value: "call",
    },
    { label: intl.formatMessage({ id: "common.contact_methods.email", defaultMessage: "Email" }), value: "email" },
    {
      label: intl.formatMessage({ id: "common.contact_methods.text_message", defaultMessage: "Text Message" }),
      value: "sms",
    },
    {
      label: intl.formatMessage({ id: "common.contact_methods.whatsapp", defaultMessage: "WhatsApp" }),
      value: "whatsapp",
    },
  ];

  return (
    <div className="flex flex-col w-full">
      <Typography className="text-center">
        <FormattedMessage
          id="onboarding.candidate.steps.ten.notification_preferences_label"
          defaultMessage="How do you prefer we contact you?"
        />
      </Typography>

      <div className="flex mt-10 items-center gap-4 justify-center">
        <FormField
          control={form.control}
          name="notification_preferences"
          render={({ field }) => (
            <FormItem>
              <MultiSelector values={field.value ?? []} onValuesChange={field.onChange}>
                <MultiSelectorTrigger>
                  <MultiSelectorInput
                    placeholder={intl.formatMessage({
                      id: "onboarding.candidate.steps.ten.notification_preferences_placeholder",
                      defaultMessage: "Search and select your preferences",
                    })}
                  />
                </MultiSelectorTrigger>
                <MultiSelectorContent>
                  <MultiSelectorList>
                    {communicationOptions.map((option, i) => (
                      <MultiSelectorItem key={i} value={`${option.value}`}>
                        {option.label}
                      </MultiSelectorItem>
                    ))}
                  </MultiSelectorList>
                </MultiSelectorContent>
              </MultiSelector>
              <FormMessage className="text-red-600" />
              <div className="flex gap-2 items-center">
                {field.value &&
                  field.value.map(item => (
                    <Badge key={item} className={cn("px-1 rounded-xl flex items-center gap-1 w-max")} variant="default">
                      <span className="text-xs">{communicationOptions.find(city => city.value === item)?.label}</span>
                      <button
                        aria-label={`Remove ${item} option`}
                        aria-roledescription="button to remove option"
                        type="button"
                        onClick={() => {
                          field.onChange(field.value!.filter(v => v !== item));
                        }}
                      >
                        <span className="sr-only">Remove {item} option</span>
                        <RemoveIcon className="h-4 w-4 hover:stroke-destructive" />
                      </button>
                    </Badge>
                  ))}
              </div>
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};

export default StepTenForm;
