import { pageView } from "@/libs/utils/analytics";
import { createFileRoute, Navigate } from "@tanstack/react-router";

export const Route = createFileRoute("/")({
  component: () => <App />,
  beforeLoad: ({ location }) => {
    if (import.meta.env.VITE_ENABLE_TRACKING === "1") {
      pageView(String(location.pathname) + String(location.search || ""));
    }
  },
});

const App = () => <Navigate to="/register" />;
