/* eslint-disable no-loops/no-loops */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

// eslint-disable-next-line import/no-duplicates
import ro from "date-fns/locale/ro";
import { Calendar, dateFnsLocalizer, Views, DateFormat, Formats, SlotInfo } from "react-big-calendar";
// eslint-disable-next-line import/no-duplicates
import { parse, startOfWeek, getDay, format } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogHeader } from "@/components/dialog";
import { Button } from "@/components/button/Button";
import { Label } from "@/components/label";
import Input from "@/components/input/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/select/select";
import { createEventSchema } from "@/core/schemas/create-event";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/form";
import { useGetEvents } from "@/core/hooks/company/useGetEvents";
import { getAngularToken } from "@/core/auth/utils";
import { useGetAccount } from "@/core/hooks/useGetAccount";

const locales = {
  "ro-RO": ro,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const generateTimeOptions = () => {
  const times = [];

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const hourStr = hour.toString().padStart(2, "0");
      const minStr = minute.toString().padStart(2, "0");
      const timeStr = `${hourStr}:${minStr}`;
      const label = format(parse(timeStr, "HH:mm", new Date()), "hh:mm a");

      times.push({ value: timeStr, label });
    }
  }
  return times;
};

type FormType = z.infer<typeof createEventSchema>;
export const Events = () => {
  const AngulaToken = getAngularToken();
  const { data: user } = useGetAccount(AngulaToken ?? "");
  const { data } = useGetEvents({ participants_id: Number(user?.user?.id.toString()) });

  const form = useForm<FormType>({
    resolver: zodResolver(createEventSchema),
    defaultValues: {
      start_time: "",
      end_time: "",
    },
  });

  const handleSubmit = useCallback((data: FormType) => {
    const formattedData = {
      ...data,
      start_time: `${data.start_time}:00`,
      end_time: `${data.end_time}:00`,
    };

    console.log(formattedData);
  }, []);

  const [open, setOpen] = useState(false);

  const { defaultDate, formats, views, events } = useMemo(() => {
    // convert API events to calendar events
    const apiEvents =
      data?.flatMap(evt => {
        if (evt.status === "rejected") {
          // for rejected events, show all proposed times in red
          return evt.proposed_times.map(time => ({
            id: time.id,
            title: evt.title,
            start: new Date(time.start_time),
            end: new Date(time.end_time),
            status: "rejected",
            className: "bg-red-200 border-red-500 text-red-700",
          }));
        }

        if (evt.status === "accepted") {
          // for accepted events, show only the accepted time in green
          return [
            {
              id: evt.id,
              title: evt.title,
              start: new Date(evt.start_time),
              end: new Date(evt.end_time),
              status: "accepted",
              className: "bg-green-200 border-green-500 text-green-700",
            },
          ];
        }

        // for proposed/pending events, show in blue
        return evt.proposed_times.map(time => ({
          id: time.id,
          title: evt.title,
          start: new Date(time.start_time),
          end: new Date(time.end_time),
          status: "proposed",
          className: "bg-blue-200 border-blue-500 text-blue-700",
        }));
      }) ?? [];

    return {
      defaultDate: new Date(),
      formats: {
        dayHeaderFormat: (date: Date, culture: Locale): DateFormat =>
          format(date, "dddd MMMM Do", { locale: culture }) ?? "",
        dayFormat: (date: Date, culture: Locale): DateFormat => format(date, "EEEE", { locale: culture }) ?? "",
      },
      views: [Views.MONTH, Views.WEEK, Views.DAY],
      events: apiEvents,
    };
  }, [data]);

  const handleSelectSlot = useCallback(({ start, end }: SlotInfo) => {
    // format the dates to match the time format used in the selects
    const formattedStart = format(start, "HH:mm");
    const formattedEnd = format(end, "HH:mm");

    form.setValue("start_time", formattedStart);
    form.setValue("end_time", formattedEnd);

    setOpen(true);
  }, []);

  const getFilteredEndTimeOptions = useCallback((startTime: string | undefined) => {
    if (!startTime) {
      return generateTimeOptions();
    }
    const options = generateTimeOptions();
    const startIndex = options.findIndex(option => option.value === startTime);

    return options.slice(startIndex + 1);
  }, []);

  return (
    <div className="flex flex-col gap-4">
      {/* <Link to="/company/schedule">
        <Button variant="outline" className="border-medi-blue text-medi-blue">
          Edit Availability
        </Button>
      </Link> */}
      <Calendar
        defaultDate={defaultDate}
        localizer={localizer}
        view={Views.MONTH}
        formats={formats as unknown as Formats}
        views={views}
        events={events}
        onSelectSlot={handleSelectSlot}
        selectable
        startAccessor="start"
        endAccessor="end"
        style={{ height: "calc(100vh - 250px)" }}
        toolbar
        eventPropGetter={event => ({
          className: event.className,
        })}
      />

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="w-full max-w-2xl bg-white flex flex-col">
          <DialogHeader className="flex !flex-row justify-between items-center">
            <DialogTitle className="">Create interview slot</DialogTitle>
            <button
              onClick={() => setOpen(false)}
              className="w-max px-2.5 flex items-center justify-center text-xl rounded-full hover:bg-medi-blue/20"
            >
              x
            </button>
          </DialogHeader>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col gap-4 md:min-w-96">
              <FormField
                name="title"
                render={({ field }) => (
                  <div className="flex flex-col gap-2 flex-1">
                    <Label>Title (optional)</Label>
                    <FormItem>
                      <FormControl>
                        <Input placeholder="Enter title" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  </div>
                )}
              />
              <div className="flex gap-2">
                <FormField
                  name="start_time"
                  render={({ field }) => (
                    <div className="flex flex-col gap-2 flex-1">
                      <Label>Start time *</Label>
                      <FormItem>
                        <FormControl>
                          <Select value={field.value} onValueChange={field.onChange}>
                            <SelectTrigger>
                              <SelectValue placeholder="Select time" />
                            </SelectTrigger>
                            <SelectContent>
                              {generateTimeOptions().map(time => (
                                <SelectItem key={time.value} value={time.value}>
                                  {time.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    </div>
                  )}
                />

                <FormField
                  name="end_time"
                  render={({ field }) => (
                    <div className="flex flex-col gap-2 flex-1">
                      <Label>End time *</Label>
                      <FormItem>
                        <FormControl>
                          <Select
                            value={field.value}
                            onValueChange={field.onChange}
                            disabled={!form.getValues("start_time")}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Select time" />
                            </SelectTrigger>
                            <SelectContent>
                              {getFilteredEndTimeOptions(form.getValues("start_time")).map(time => (
                                <SelectItem key={time.value} value={time.value}>
                                  {time.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    </div>
                  )}
                />
              </div>
              <Button type="submit">Create</Button>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
};
