import { AxiosV2 } from "@/libs/utils/axios-interceptor";
import { createMutation } from "react-query-kit";

interface AcceptProposedTimePayload {
  event_id: number;
  proposed_time_id: number;
}

interface AcceptProposedTimeResponse {
  id: number;
  status: string;
}

export const useAcceptProposedTime = createMutation<AcceptProposedTimeResponse, AcceptProposedTimePayload>({
  mutationFn: async payload => {
    const data = await AxiosV2.post(
      `/event-management/events/${payload.event_id}/accept-proposed-time/${payload.proposed_time_id}/`
    );

    return data.data as AcceptProposedTimeResponse;
  },
});
