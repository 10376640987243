import onboarding from "./onboarding";
import common from "./common";
import errors from "./errors";
import login from "./login";
export default {
  ...onboarding,
  ...common,
  ...errors,
  ...login,
};
