/* eslint-disable no-loops/no-loops */

// import { addMinutes } from "date-fns";
import { TZDate } from "@date-fns/tz";

interface TimeSlot {
  start: Date;
  end: Date;
}

const SLOT_DURATION = 30; // minutes
const BUFFER_TIME = 10; // minutes

export const generateAvailableTimeSlots = (
  date: Date,
  availability: ApiAvailability[],
  events: ApiEvent[],
  slotDuration = SLOT_DURATION,
  bufferTime = BUFFER_TIME
): TimeSlot[] => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // convert input date to user's timezone
  const localDate = new TZDate(date, userTimeZone);

  const weekday = localDate.getDay();
  // convert weekday from Sunday=0 to Monday=0
  const adjustedWeekday = weekday === 0 ? 6 : weekday - 1;

  // get availability for the selected day
  const dayAvailability = availability.filter(a => a.weekday === adjustedWeekday);

  if (!dayAvailability.length) {
    return [];
  }

  // merge overlapping availability windows
  const mergedAvailability = mergeTimeRanges(dayAvailability);

  // convert events to user's timezone and filter for the selected date
  const dateEvents = events
    .filter(event => {
      const eventDate = new TZDate(event.start_time, userTimeZone);

      return (
        eventDate.getDate() === localDate.getDate() &&
        eventDate.getMonth() === localDate.getMonth() &&
        eventDate.getFullYear() === localDate.getFullYear()
      );
    })
    .map(event => ({
      ...event,
      start_time: new TZDate(event.start_time, userTimeZone),
      end_time: new TZDate(event.end_time, userTimeZone),
    }));

  // generate all possible time slots
  const allSlots: TimeSlot[] = [];

  mergedAvailability.forEach(availability => {
    const startTime = parseTimeString(availability.start_time);
    const endTime = parseTimeString(availability.end_time);

    let currentSlot = new TZDate(localDate);

    currentSlot.setHours(startTime.hours, startTime.minutes, 0, 0);

    const dayEnd = new TZDate(localDate);

    dayEnd.setHours(endTime.hours, endTime.minutes, 0, 0);

    while (addMinutes(currentSlot, slotDuration) <= dayEnd) {
      const slotEnd = addMinutes(currentSlot, slotDuration);

      // check if slot overlaps with any events
      const isOverlapping = dateEvents.some(event => {
        const eventStart = event.start_time;
        const eventEnd = event.end_time;

        return (currentSlot >= eventStart && currentSlot < eventEnd) || (slotEnd > eventStart && slotEnd <= eventEnd);
      });

      // check if slot has enough buffer time from adjacent events
      const hasBuffer = dateEvents.every(event => {
        const eventStart = event.start_time;
        const eventEnd = event.end_time;

        return addMinutes(slotEnd, bufferTime) <= eventStart || addMinutes(eventEnd, bufferTime) <= currentSlot;
      });

      if (!isOverlapping && hasBuffer) {
        allSlots.push({
          start: currentSlot,
          end: slotEnd,
        });
      }

      currentSlot = new TZDate(addMinutes(currentSlot, slotDuration + bufferTime), userTimeZone);
    }
  });

  return allSlots;
};

// helper functions
const parseTimeString = (timeString: string) => {
  const [hours, minutes] = timeString.split(":").map(Number);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // create a UTC date with the time
  const utcDate = new Date();

  utcDate.setUTCHours(hours, minutes, 0, 0);

  // convert to local time
  const localDate = new TZDate(utcDate, userTimeZone);

  return {
    hours: localDate.getHours(),
    minutes: localDate.getMinutes(),
  };
};

const addMinutes = (date: Date, minutes: number): Date => new Date(date.getTime() + minutes * 60000);

const mergeTimeRanges = (availability: ApiAvailability[]): ApiAvailability[] => {
  if (!availability.length) {
    return [];
  }

  const sorted = [...availability].sort((a, b) => {
    const timeA = parseTimeString(a.start_time);
    const timeB = parseTimeString(b.start_time);

    return timeA.hours * 60 + timeA.minutes - (timeB.hours * 60 + timeB.minutes);
  });

  const merged: ApiAvailability[] = [sorted[0]];

  for (let i = 1; i < sorted.length; i++) {
    const current = sorted[i];
    const previous = merged[merged.length - 1];

    const prevEnd = parseTimeString(previous.end_time);
    const currStart = parseTimeString(current.start_time);

    if (prevEnd.hours * 60 + prevEnd.minutes >= currStart.hours * 60 + currStart.minutes) {
      // merge overlapping ranges
      const currEnd = parseTimeString(current.end_time);

      if (currEnd.hours * 60 + currEnd.minutes > prevEnd.hours * 60 + prevEnd.minutes) {
        previous.end_time = current.end_time;
      }
    } else {
      merged.push(current);
    }
  }

  return merged;
};

export const localToUTCTime = (date: Date, timeString: string): Date => {
  // const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [hours, minutes] = timeString.split(":").map(Number);

  // create local date with selected time
  const localDate = new Date(date);

  localDate.setHours(hours, minutes, 0, 0);

  // convert to UTC
  const utcDate = new TZDate(localDate.toISOString(), "UTC");

  return utcDate;
};

/**
 * converts a UTC date string/Date to local timezone date
 * @param dateString UTC date string or Date object
 * @returns Date object in user's local timezone
 */
export const utcToLocalTime = (dateString: string | Date): Date => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // convert to user's timezone
  const localDate = new TZDate(new Date(dateString), userTimeZone);

  return localDate;
};

export const getDurationInMinutes = (timeString: string) => {
  const [hours, minutes] = timeString.split(":").map(Number);

  return hours * 60 + minutes;
};
