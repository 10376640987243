import { getSchedules } from "@/services/api/auth";
import { useQuery } from "@tanstack/react-query";

const useFetchSchedules = () => {
  const { data, isLoading, isError } = useQuery<ApiScheduleResponse, Error>({
    queryKey: ["schedules"],
    queryFn: getSchedules,
  });

  return { data, isLoading, isError };
};

export { useFetchSchedules };
