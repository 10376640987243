import { CreateCandidatePayload } from "@/libs/hooks/mutations/useCreateCandidate";
import { http } from "./http";
import { Axios } from "@/libs/utils/axios-interceptor";
import { UpdateCandidatePayload } from "@/libs/hooks/mutations/useUpdateCandidate";
import { UploadCvPayload } from "@/libs/hooks/mutations/useUploadCv";

export const login = async (email: string, password: string) =>
  (await http.post("/login", { email, password })) as Promise<ApiLoginResponse>;

export const resetPassword = async (email: string) => http.post("/forgot-password", { email });

export const getCategories = async () =>
  (await http.get("/publicnomenclatures/domain")) as Promise<ApiCategoryResponse>;

export const getAbroadCountries = async () =>
  (await http.get("/publicnomenclatures/abroad-work-countries")) as Promise<{
    data: ApiAbroadCountriesResponse;
  }>;

export const registerCandidate = async (data: unknown) =>
  (await http.post("/register/candidate", data)) as Promise<ApiRegisterCandidateResponse>;

export const getPositions = async () => (await http.get("/publicnomenclatures/functions")) as Promise<string[]>;

export const getEducations = async () =>
  (await http.get("/publicnomenclatures/educations")) as Promise<ApiEducationResponse>;

export const getLocations = async (val: string) =>
  (await http.get(`/publicnomenclatures/places?val=${val}`)) as Promise<ApiPlacesResponse>;

export const getCities = async () => (await http.get("/publicnomenclatures/cities")) as Promise<ApiCitiesResponse>;

export const registerCompany = async (data: unknown) =>
  (await http.post("/register/company", data)) as Promise<ApiRegisterCompanyResponse>;

export const getExperiences = async () =>
  (await http.get("/publicnomenclatures/experiences")) as Promise<ApiExperienceResponse>;

export const getSchedules = async () =>
  (await http.get("/publicnomenclatures/schedules")) as Promise<ApiScheduleResponse>;

export const getIdentifications = async () =>
  (await http.get("/publicnomenclatures/identifications")) as Promise<ApiIdentificationResponse>;

export const getCompanies = async () =>
  (await http.get("/publicnomenclatures/companies")) as Promise<ApiCompanyResponse>;

export const updateCandidateOld = async (data: unknown, candidateID: string) =>
  (await http.patch(`/register/candidate/${candidateID}`, data)) as Promise<ApiUpdateCandidateResponse>;

export const createNewPassword = async (email: string, password: string, token: string) =>
  http.post("/reset-password", {
    email,
    password,
    confirmed_password: password,
    token,
  }) as Promise<ApiPasswordResetResponse>;

export const createCandidate = async (data: CreateCandidatePayload) => {
  try {
    const res = await Axios.post(`/register/candidate`, data);

    return res;
  } catch (error) {
    console.error(error);

    throw error;
  }
};

export const updateCandidate = async (data: UpdateCandidatePayload) => {
  const { id, access_token, ...rest } = data;

  try {
    const res = await Axios.patch(
      `/register/candidate/${id}`,
      { ...rest },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    return res;
  } catch (error) {
    console.error(error);

    throw error;
  }
};

export const uploadCv = async (data: UploadCvPayload) => {
  const formData = new FormData();

  formData.append("cv", data.cv);

  try {
    const res = await Axios.post(`/cv`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${data.access_token}`,
      },
    });

    return res;
  } catch (error) {
    console.error(error);

    throw error;
  }
};

export const getAccount = async (token: string) => {
  try {
    const res = await Axios.get(`/user`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res;
  } catch (error) {
    console.error(error);

    throw error;
  }
};
