/* eslint-disable import/no-duplicates */
import { IoLocationOutline } from "react-icons/io5";
import { HiChevronRight } from "react-icons/hi2";
import { BsClock } from "react-icons/bs";
import { Link } from "@tanstack/react-router";
import { Typography } from "@/components/typography";
import { memo } from "react";
import { format } from "date-fns";
import { ro } from "date-fns/locale";

interface InterviewCardProps {
  title: string;
  location: string;
  role: string;
  interviewType?: "offline" | "virtual" | "phone";
  startTime?: Date;
  endTime?: Date;
  contactPerson?: string;
  address?: string;
  phone?: string;
  to: string;
}

const InterviewCard = memo(
  ({
    title,
    location,
    role,
    interviewType,
    startTime,
    endTime,
    contactPerson,
    address,
    phone,
    to,
  }: InterviewCardProps) => (
    <Link to={to} className="p-4 rounded-lg bg-gray-50 hover:bg-gray-100 transition-colors group">
      <div className="flex items-start justify-between">
        <div className="flex-1">
          <div className="flex items-center gap-2 mb-2">
            <Typography className="text-medi-blue md:text-lg font-medium">{title}</Typography>
            <Typography className="text-gray-600 text-sm">•</Typography>
            <Typography className="text-gray-600 text-sm">{role}</Typography>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-4 text-gray-600 text-sm">
              <div className="flex items-center gap-2">
                <IoLocationOutline className="text-gray-500" />
                <span>{location}</span>
              </div>
              {startTime && endTime && (
                <div className="flex items-center gap-2">
                  <BsClock className="text-gray-500" />
                  <span>
                    {format(new Date(startTime), "HH:mm", { locale: ro })} -{" "}
                    {format(new Date(endTime), "HH:mm", { locale: ro })}{" "}
                    {format(new Date(startTime), "d MMMM", { locale: ro })}
                  </span>
                </div>
              )}
            </div>

            {interviewType && (
              <div className="text-gray-600 text-sm">
                <span className="font-medium">Type: </span>
                <span className="capitalize">{interviewType === "offline" ? "on-site" : interviewType}</span>
              </div>
            )}

            {contactPerson && (
              <div className="text-gray-600 text-sm">
                <span className="font-medium">Contact: </span>
                {contactPerson}
              </div>
            )}

            {address && interviewType === "offline" && <div className="text-gray-600 text-sm">{address}</div>}

            {phone && interviewType === "phone" && (
              <div className="text-gray-600 text-sm">
                <span className="font-medium">Phone: </span>
                {phone}
              </div>
            )}
          </div>
        </div>
        <HiChevronRight className="text-gray-400 text-xl group-hover:text-medi-blue transition-colors" />
      </div>
    </Link>
  )
);

InterviewCard.displayName = "InterviewCard";

export default InterviewCard;
