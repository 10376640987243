import { AxiosV2 } from "@/libs/utils/axios-interceptor";
import { createMutation } from "react-query-kit";

interface SendPositiveFeedbackProps {
  interview_id: number;
}

export const useSendPositiveFeedback = createMutation({
  mutationFn: async ({ interview_id }: SendPositiveFeedbackProps) =>
    AxiosV2.post(`/recruitment/interview/${interview_id}/candidate-feedback/positive/`),
});
