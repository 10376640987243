import { FormField, FormItem, FormMessage } from "@/components/form";
import { formSchemaType } from "@/components/Layouts/onboarding";
import { PillSelector } from "@/components/pillSelector";

import { Typography } from "@/components/typography";
import { useFetchSchedules } from "@/libs/hooks/useFetchSchedules";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";

const StepTwoForm = () => {
  const form = useFormContext<formSchemaType>();

  const { data } = useFetchSchedules();

  const workScheduleOptions = data?.data.slice(0, 2).map(option => ({ label: option.value, value: option.id })) ?? [];

  return (
    <div className="flex flex-col w-full">
      <div className="">
        <Typography className="text-center">
          <FormattedMessage
            id="onboarding.candidate.steps.two.work_type_label"
            defaultMessage="Would you like full-time or part-time work?"
          />
        </Typography>

        <div className="flex mt-10 items-center gap-4 justify-center">
          <FormField
            control={form.control}
            name="work_type"
            render={({ field }) => (
              <FormItem>
                <div className="flex gap-4 items-center">
                  {workScheduleOptions.map(({ label, value }) => (
                    <PillSelector
                      key={value}
                      onClick={() => {
                        field.onChange(value);
                      }}
                      selected={field.value === value}
                      onClearSelect={() => field.onChange(undefined)}
                    >
                      {label}
                    </PillSelector>
                  ))}
                </div>
                <FormMessage className="text-red-600" />
              </FormItem>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default StepTwoForm;
