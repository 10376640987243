import { AxiosV2 } from "@/libs/utils/axios-interceptor";
import { createMutation } from "react-query-kit";

interface UseCreateAvailabilityResponse {
  data: ApiEvent;
}

interface UseCreateAvailabilityPayload {
  start_time: string;
  end_time: string;
  weekday: string;
  user: string;
}

export const useCreateAvailability = createMutation<UseCreateAvailabilityResponse, UseCreateAvailabilityPayload>({
  mutationFn: async payload => {
    const response = await AxiosV2.post<UseCreateAvailabilityResponse>("/event-management/availability/", payload);

    return response.data;
  },
});
