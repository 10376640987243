import { useQuery } from "@tanstack/react-query";
import { getAbroadCountries } from "@/services/api/auth";

const useFetchAboardCountries = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["abroad_countries"],
    queryFn: getAbroadCountries,
  });

  return {
    data: data?.data ?? null,
    isLoading,
    error,
  };
};

export { useFetchAboardCountries };
