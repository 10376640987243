import { useCallback, useEffect, useState } from "react";
import { isBefore, parseISO, addMinutes } from "date-fns";

export enum ProcessStatus {
  Undefined,
  New,
  OfferAcceptedByCandidate,
  OfferRejectedByCandidate,
  InterviewAcceptedByMediJobs,
  InterviewRejectedByMediJobs,
  InterviewAcceptedByCompany,
  InterviewRejectedByCompany,
  InterviewScheduled,
  InterviewRescheduled,
  CandidatePositiveFeedback,
  CandidateNegativeFeedback,
  FollowUpInterviewRequested,
  FinalOffer,
  Hire,
  InterviewPassed,
  CompanyInterviewPresent,
  CompanyInterviewMissed,
  CandidateInterviewPresent,
  CandidateInterviewMissed,
  NotPresented,
  Trial,
}

export const useGetProcessStatus = (process?: ApiOffers) => {
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.Undefined);

  const getLatestInterview = useCallback((offer: ApiOffers) => {
    const { interviews } = offer;
    const latestInterview = interviews.sort(
      (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    )[0];

    return latestInterview;
  }, []);

  const getLatestEvent = useCallback((events: ApiProcessEvent[]) => {
    const latestEvent = events.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0];

    return latestEvent;
  }, []);

  useEffect(() => {
    if (!process) {
      return;
    }

    const latestInterview = getLatestInterview(process);
    const latestEvent = getLatestEvent(process.events);

    if (latestEvent?.type === "SYSTEM_CREATED") {
      setStatus(ProcessStatus.New);
    }

    if (latestInterview && latestInterview.status_old && latestInterview.status_old !== "") {
      if (process.valid) {
        setStatus(ProcessStatus.InterviewAcceptedByMediJobs);
      }

      if (latestInterview.status_old.toLowerCase() === "pending") {
        setStatus(ProcessStatus.InterviewScheduled);

        if (isBefore(addMinutes(parseISO(latestInterview.scheduled_date_time.toString()), 30), new Date())) {
          setStatus(ProcessStatus.InterviewPassed);
        }
      } else if (latestInterview.status_old.toLowerCase() === "refuz candidat") {
        setStatus(ProcessStatus.OfferRejectedByCandidate);
      } else if (latestInterview.status_old.toLowerCase() === "refuz companie") {
        setStatus(ProcessStatus.InterviewRejectedByCompany);
      } else if (latestInterview.status_old.toLowerCase() === "angajat") {
        setStatus(ProcessStatus.Hire);
      } else if (latestInterview.status_old.toLowerCase() === "np") {
        setStatus(ProcessStatus.NotPresented);
      } else if (latestInterview.status_old.toLowerCase() === "proba") {
        setStatus(ProcessStatus.Trial);
      }
    } else if (process.valid) {
      setStatus(ProcessStatus.InterviewAcceptedByMediJobs);
    }

    if (latestEvent?.type === "CANDIDATE_ACCEPTED") {
      setStatus(ProcessStatus.OfferAcceptedByCandidate);
    }

    if (latestEvent?.type === "VALIDATED") {
      setStatus(ProcessStatus.InterviewAcceptedByMediJobs);
    }

    if (latestEvent?.type === "COMPANY_ACCEPTED") {
      setStatus(ProcessStatus.InterviewAcceptedByCompany);
    }

    if (latestEvent?.type === "INTERVIEW_SCHEDULED") {
      setStatus(ProcessStatus.InterviewScheduled);

      if (isBefore(addMinutes(parseISO(latestInterview.scheduled_date_time.toString()), 30), new Date())) {
        setStatus(ProcessStatus.InterviewPassed);
      }

      if (latestInterview.missed) {
        setStatus(ProcessStatus.NotPresented);
      }
    }

    if (latestEvent?.type === "INTERVIEW_RESCHEDULED") {
      setStatus(ProcessStatus.InterviewRescheduled);
    }

    if (latestEvent?.type === "COMPANY_INTERVIEW_PRESENT") {
      setStatus(ProcessStatus.CompanyInterviewPresent);
    }

    if (latestEvent?.type === "COMPANY_INTERVIEW_MISSED") {
      setStatus(ProcessStatus.CompanyInterviewMissed);
    }

    if (latestEvent?.type === "INTERVIEW_PRESENT") {
      setStatus(ProcessStatus.CandidateInterviewPresent);
    }

    if (latestEvent?.type === "INTERVIEW_MISSED") {
      setStatus(ProcessStatus.CandidateInterviewMissed);
    }

    if (latestEvent?.type === "CANDIDATE_INTERVIEW_POSITIVE") {
      setStatus(ProcessStatus.CandidatePositiveFeedback);
    }

    if (latestEvent?.type === "CANDIDATE_INTERVIEW_NEGATIVE") {
      setStatus(ProcessStatus.CandidateNegativeFeedback);
    }

    if (latestEvent?.type === "FOLLOWUP_INTERVIEW_REQUESTED") {
      setStatus(ProcessStatus.FollowUpInterviewRequested);
    }

    if (latestEvent?.type === "FINAL_OFFER_SENT") {
      setStatus(ProcessStatus.FinalOffer);
    }

    if (latestEvent?.type === "CANDIDATE_ACCEPT_FINAL_OFFER" || latestEvent?.type === "HIRED") {
      setStatus(ProcessStatus.Hire);
    }

    if (latestEvent?.type === "CANDIDATE_REJECTED") {
      setStatus(ProcessStatus.OfferRejectedByCandidate);
    }

    if (latestEvent?.type === "COMPANY_REJECTED") {
      setStatus(ProcessStatus.InterviewRejectedByCompany);
    }

    if (latestEvent?.type === "INVALIDATED") {
      setStatus(ProcessStatus.InterviewRejectedByMediJobs);
    }
  }, [process, getLatestInterview]);

  return status;
};
