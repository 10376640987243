import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/form";
import Input from "@/components/input/input";
import { formSchemaType } from "@/components/Layouts/onboarding";

import { Typography } from "@/components/typography";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

const StepFiveForm = () => {
  const form = useFormContext<formSchemaType>();
  const intl = useIntl();

  return (
    <div className="flex flex-col w-full">
      <Typography className="text-center">
        <FormattedMessage
          id="onboarding.candidate.steps.five.salary_expectations_label"
          defaultMessage="What is your desired salary for a full-time job?"
        />
      </Typography>

      <div className="flex mt-10 items-center gap-10 justify-center flex-col">
        <FormField
          control={form.control}
          name="min_salary"
          render={({ field }) => (
            <FormItem className="flex flex-col w-full">
              <FormLabel className="flex items-center">
                <FormattedMessage id="onboarding.candidate.steps.five.min_salary_label" defaultMessage="Min" />
                :RON
              </FormLabel>
              <FormControl>
                <Input
                  defaultValue={field.value}
                  onChange={field.onChange}
                  type="number"
                  placeholder={intl.formatMessage({
                    id: "onboarding.candidate.steps.five.min_salary_placeholder",
                    defaultMessage: "Enter Minimum Amount",
                  })}
                  className="border border-gray-300 rounded-md p-2 w-full"
                />
              </FormControl>
              <FormMessage className="text-red-500" />
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};

export default StepFiveForm;
