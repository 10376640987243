/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    dataLayer: any[];
    gtag: (
      command: "event" | "config" | "set" | "js",
      action: string,
      params?: { [key: string]: string | number | boolean | object }
    ) => void;
    fbq: (arg0: string | null, arg1: string | null) => void;
  }
}

export const pageView = (url: string) => {
  if (typeof window.gtag !== "undefined") {
    window.gtag("config", "G-DM4WCTZBSD", {
      page_path: url,
    });

    console.log("original page view", url);
  }
};

export const event = (action: string, params: Record<string, any>) => {
  if (typeof window.gtag !== "undefined") {
    window.gtag("event", action, params);
  }
};
