/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-loops/no-loops */
import { StoreApi, UseBoundStore } from "zustand";

type WithSelectors<S> = S extends { getState: () => infer T } ? S & { use: { [K in keyof T]: () => T[K] } } : never;

export const createSelectors = <S extends UseBoundStore<StoreApi<object>>>(_store: S) => {
  const store = _store as WithSelectors<typeof _store>;

  store.use = {};
  for (const k of Object.keys(store.getState())) {
    (store.use as any)[k] = () => store(s => s[k as keyof typeof s]);
  }

  return store;
};

export const isHolidayDate = (date: Date) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();

  // define holiday patterns as month/day combinations
  const holidayPatterns = [
    { month: 1, day: 1 },
    { month: 1, day: 2 },
    { month: 1, day: 24 },
    { month: 4, day: 14 },
    { month: 4, day: 16 },
    { month: 4, day: 17 },
    { month: 5, day: 1 },
    { month: 6, day: 1 },
    { month: 6, day: 4 },
    { month: 6, day: 5 },
    { month: 8, day: 15 },
    { month: 11, day: 30 },
    { month: 12, day: 1 },
    { month: 12, day: 25 },
    { month: 12, day: 26 },
    { month: 12, day: 27 },
    { month: 12, day: 28 },
    { month: 12, day: 29 },
    { month: 12, day: 30 },
    { month: 12, day: 31 },
  ];

  return holidayPatterns.some(holiday => holiday.month === month && holiday.day === day);
};

export const isDateInAvailability = (date: Date, availability: ApiAvailability[]) => {
  // get day of week (0-6, where 0 is Sunday)
  const dayOfWeek = date.getDay();

  // convert to availability format where 0 is Monday and 4 is Friday
  const availabilityDay = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

  // check if this day exists in availability
  return availability?.some((slot: ApiAvailability) => slot.weekday === availabilityDay);
};
