import { create } from "zustand";
import { createSelectors } from "../utils";
import {
  getSession,
  setSession,
  removeSession,
  setAngularToken,
  removeAngularToken,
  removeAngularCredentials,
} from "./utils";

interface AuthState {
  session: Session | null;
  status: "idle" | "signOut" | "signIn";
  signIn: (session: Session) => void;
  signOut: () => void;
  hydrate: () => void;
}

const _useAuth = create<AuthState>((set, get) => ({
  session: null,
  status: "idle",
  signIn: (session: Session) => {
    setSession(session);
    setAngularToken(session.access_token);
    set({ session, status: "signIn" });
  },
  signOut: () => {
    removeAngularCredentials();
    removeAngularToken();
    removeSession();

    // clear any stored redirects
    window.history.replaceState(null, "", window.location.pathname);

    set({ session: null, status: "signOut" });
  },
  hydrate: () => {
    try {
      const session = getSession();

      if (session !== null) {
        get().signIn(session);
      } else {
        get().signOut();
      }
    } catch (error) {
      get().signOut();
    }
  },
}));

export const useAuth = createSelectors(_useAuth);

export const signOut = () => _useAuth.getState().signOut();
export const signIn = (session: Session) => _useAuth.getState().signIn(session);
export const hydrateAuth = () => _useAuth.getState().hydrate();
