import { useEffect, useState } from "react";
import { store } from "@/libs/store";
import StepperIndicator from "../stepperIndicator";
import { Route as OnboardingCandidateRoute } from "@/routes/_auth-layout/onboarding/candidate/";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { onBoardingFormSchema } from "@/libs/utils/schemas/onboarding";
import { Typography } from "../typography";
import { Form } from "../form";
import { AnimatePresence, motion } from "framer-motion";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { z } from "zod";
import { CreateCandidatePayload, useCreateCandidate } from "@/libs/hooks/mutations/useCreateCandidate";
import { UpdateCandidatePayload, useUpdateCandidate } from "@/libs/hooks/mutations/useUpdateCandidate";
import { useNavigate } from "@tanstack/react-router";
import { useUploadCv } from "@/libs/hooks/mutations/useUploadCv";
import { FormattedMessage, useIntl } from "react-intl";

interface OnboardingWrapperProps {
  stepsArray: ProgressStep[];
}

type StepFieldKeysUnion =
  | "email"
  | "password"
  | "confirm_password"
  | "name"
  | "work_type"
  | "city"
  | "certification"
  | "job_title"
  | "min_salary"
  | "years_of_experience"
  | "terms"
  | "work_locations"
  | "max_distance_in_km"
  | "agrees_to_abroad_work"
  | "abroad_work_countries"
  | "phone"
  | "notification_preferences"
  | "job_search_status"
  | "resume"
  | "categories"
  | "main_work_city_id"
  | "receive_notifications"
  | "secondary_category"
  | "secondary_sub_categories"
  | "secondary_tags";

export type formSchemaType = z.infer<typeof onBoardingFormSchema>;

const OnboardingWrapper = ({ stepsArray }: OnboardingWrapperProps) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const [isMounted, setIsMounted] = useState(false);

  const {
    utm_campaign,
    utm_content,
    utm_medium,
    utm_source,
    step,
    email = "",
    name = "",
  } = OnboardingCandidateRoute.useSearch();

  const currentStepIndex =
    Math.min(Math.max(Number(step) || 0, 0), stepsArray.length) === 0
      ? 1
      : Math.min(Math.max(Number(step) || 1, 1), stepsArray.length);

  const { user, setShowPhoneError, setUser } = store();

  const { mutate, isPending } = useCreateCandidate();
  const { mutateAsync: updateCandidate, isSuccess } = useUpdateCandidate({
    current_step: currentStepIndex,
    setCurrentStep: async (newStep: number) =>
      navigate({ search: (prev: Record<string, unknown>) => ({ ...prev, step: newStep }) }),
  });
  const { mutate: uploadCv } = useUploadCv();

  const redirectToDashboard = () => {
    const URL = import.meta.env.VITE_RECRUITER_APP_URL;

    window.location.href = `${URL}/auth/callback#access_token=${user?.access_token}&token_type=Bearer&expires_in=31622400`;
    setUser(null);
  };

  const form = useForm<formSchemaType>({
    resolver: zodResolver(onBoardingFormSchema),
    reValidateMode: "onBlur",
    mode: "onChange",
    defaultValues: {
      email,
      name,
      categories: [
        {
          category_id: "",
          subcategories: [],
          tags: [],
          is_main: true,
        },
        {
          category_id: "",
          subcategories: [],
          tags: [],
          is_main: false,
        },
      ],
      work_locations: [],
    },
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleNextStep = async () => {
    setShowPhoneError(true);
    if (form) {
      const { trigger } = form;

      const stepFieldKeys = stepsArray[currentStepIndex - 1].form_fields_key;
      const isPasswordMatch = form.getValues("password") === form.getValues("confirm_password");

      if (!isPasswordMatch) {
        form.setError("confirm_password", {
          message: intl.formatMessage({ id: "errors.password_mismatch" }),
          type: "manual",
        });
        return;
      }

      const isValid = await trigger(stepFieldKeys as StepFieldKeysUnion[]);

      const currentStepFieldsAndValues = stepFieldKeys.reduce(
        (acc, field) => {
          acc[field] = form.getValues(field as StepFieldKeysUnion);
          return acc;
        },
        {} as { [key: string]: unknown }
      );

      const { categories, receive_notifications, ...rest } = currentStepFieldsAndValues;

      const stepOnePayload = {
        ...rest,
        categories: JSON.stringify(Array.isArray(categories) ? [categories[0]] : []),
        utm_campaign,
        utm_content,
        utm_medium,
        utm_source,
        accept_marketing: receive_notifications ? 1 : 0,
      };

      if (isValid) {
        setIsSubmitting(true);

        if (currentStepIndex === 1) {
          mutate(stepOnePayload as unknown as CreateCandidatePayload);
          setIsSubmitting(false);
          return;
        }

        if (currentStepIndex === stepsArray.length - 1) {
          const restFields = stepsArray.slice(1, -1).reduce(
            (acc, step) => {
              const stepFields = step.form_fields_key.reduce(
                (fieldAcc, field) => {
                  fieldAcc[field] = form.getValues(field as StepFieldKeysUnion);
                  return fieldAcc;
                },
                {} as { [key: string]: unknown }
              );

              return { ...acc, ...stepFields };
            },
            {} as { [key: string]: unknown }
          );

          const {
            agrees_to_abroad_work,
            language_ability,
            abroad_work_countries,
            receive_notifications,
            categories,
            min_salary,
            job_search_status,
            work_locations,
            work_type,
            certification,
            years_of_experience,
            black_listed,
            ...rest
          } = restFields;

          const finalPayload = {
            schedule_ids: [work_type],
            categories: JSON.stringify(categories),
            home_city_id: form.getValues("city"),
            work_city_ids: work_locations,
            agrees_to_abroad_work: agrees_to_abroad_work === "yes" ? 1 : 0,
            abroad_work_countries,
            education_id: certification,
            desired_salary: min_salary,
            identification_id: job_search_status,
            english_language_ability: language_ability,
            id: user?.candidate_id,
            access_token: user?.access_token,
            experience_id: years_of_experience === "0 - 1" ? "1" : years_of_experience === "1 - 3" ? "2" : "3",
            utm_campaign,
            utm_content,
            utm_medium,
            utm_source,
            company_ids: black_listed,
            marketing: receive_notifications,
            ...rest,
          };

          await updateCandidate(finalPayload as unknown as UpdateCandidatePayload);

          if (isSuccess) {
            await navigate({ search: (prev: Record<string, unknown>) => ({ ...prev, step: currentStepIndex + 1 }) });
          }

          setIsSubmitting(false);
          return;
        }
        if (currentStepIndex === stepsArray.length) {
          if (hasCvUploaded()) {
            const resume = (form.getValues("resume") as File[]) ?? [];
            const cvPayload = {
              cv: resume[0],
              access_token: user?.access_token as string,
            };

            uploadCv(cvPayload);
            setUser(null);
          } else {
            // if no CV uploaded, redirect to dashboard
            redirectToDashboard();
          }
          setIsSubmitting(false);
          return;
        }

        await navigate({ search: (prev: Record<string, unknown>) => ({ ...prev, step: currentStepIndex + 1 }) });
        setIsSubmitting(false);
      }
    }
  };

  useEffect(() => {
    if (!isSubmitting) {
      if (user) {
        void navigate({
          search: (prev: Record<string, unknown>) => ({ ...prev, step: Math.max(currentStepIndex, 2) }),
        });
      } else {
        // void navigate({ search: prev => ({ ...prev, step: 1 }) });
      }
    }
  }, [user, navigate, currentStepIndex, isSubmitting]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  const hasCvUploaded = () => {
    const resume = form.getValues("resume") as File[];

    return resume && resume.length > 0;
  };

  return (
    <div className="md:px-20">
      <StepperIndicator activeStep={currentStepIndex} steps={stepsArray.length} />

      <Form {...form}>
        <form className="flex flex-col gap-6">
          <AnimatePresence>
            <div className="mt-16">
              <div className="flex flex-col items-center my-10">
                <Typography variant="h1" className="text-center">
                  {stepsArray[currentStepIndex - 1].title}
                </Typography>
                {stepsArray[currentStepIndex - 1].description && (
                  <Typography variant="p">{stepsArray[currentStepIndex].description}</Typography>
                )}
              </div>

              <motion.div
                key={currentStepIndex}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                {stepsArray[currentStepIndex - 1].content}
              </motion.div>
            </div>
          </AnimatePresence>
        </form>
      </Form>

      <div className="flex justify-between items-center w-full">
        {currentStepIndex > 0 && (
          <button
            onClick={async () =>
              navigate({
                search: (prev: Record<string, unknown>) => ({
                  ...prev,
                  step:
                    currentStepIndex === 7 &&
                    user?.candidate_object.domain_experiences[0].category.abroad_work_countries_selectable === 0
                      ? 5
                      : currentStepIndex - 1,
                }),
              })
            }
            disabled={isPending || currentStepIndex === 0}
            className="flex items-center gap-2 text-medi-blue mt-10 disabled:text-medi-blue/40"
          >
            <FaAngleLeft /> <FormattedMessage id="common.prev_step" />
          </button>
        )}

        <button
          onClick={handleNextStep}
          disabled={isPending}
          className="flex items-center gap-2 text-medi-blue mt-10 disabled:text-medi-blue/40 ml-auto"
        >
          {currentStepIndex === stepsArray.length ? (
            hasCvUploaded() ? (
              <FormattedMessage id="common.finish" />
            ) : (
              <FormattedMessage id="common.skip_to_dashboard" />
            )
          ) : (
            <FormattedMessage id="common.next_step" />
          )}
          <FaAngleRight />
        </button>
      </div>
    </div>
  );
};

export default OnboardingWrapper;
