import clsx from "clsx";
import { MdClose } from "react-icons/md";
import { HTMLProps } from "react";

interface PillSelectorProps extends HTMLProps<HTMLButtonElement> {
  selected: boolean;
  onClearSelect?: () => void;
}
export const PillSelector = ({ selected = false, children, onClearSelect, ...props }: PillSelectorProps) => (
  <button
    className={clsx(
      "bg-blue-200/30 px-5 py-1.5 text-medi-blue rounded-md font-medium hover:opacity-70 transition inline-flex w-max items-center gap-2",
      selected ? "bg-medi-blue text-white " : ""
    )}
    {...props}
    type="button"
  >
    {children}
    {selected && (
      <button onClick={() => onClearSelect?.()}>
        <MdClose className="text-lg hover:bg-red-300" />
      </button>
    )}
  </button>
);
