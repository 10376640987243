import { Typography } from "@/components/typography";
import { IoCloseSharp as RemoveIcon } from "react-icons/io5";

import { FormField, FormItem, FormMessage } from "@/components/form";

import {
  MultiSelector,
  MultiSelectorContent,
  MultiSelectorInput,
  MultiSelectorItem,
  MultiSelectorList,
  MultiSelectorTrigger,
} from "@/components/select/multiselect";
import { useFetchCompanies } from "@/libs/hooks/useFetchCompanies";
import { Badge } from "@/components/badge";
import { cn } from "@/libs/utils/cn";
import { FormattedMessage, useIntl } from "react-intl";
import { useFormContext } from "react-hook-form";
import { formSchemaType } from "@/components/Layouts/onboarding";

const StepTwelveForm = () => {
  const form = useFormContext<formSchemaType>();
  const intl = useIntl();

  const { data } = useFetchCompanies();

  const getLabel = (value: string) => data?.find(company => company.id === Number(value))?.name;

  return (
    <div className="flex flex-col w-full">
      <Typography className="text-center">
        <FormattedMessage
          id="onboarding.candidate.steps.twelve.black_listed_label"
          defaultMessage="Block employers you do not wish to connect with."
        />
      </Typography>

      <div className="flex mt-10 items-center gap-4 justify-center">
        <FormField
          control={form.control}
          name="black_listed"
          render={({ field }) => (
            <FormItem className="w-full">
              <MultiSelector values={field.value ?? []} onValuesChange={field.onChange}>
                <MultiSelectorTrigger>
                  <MultiSelectorInput
                    placeholder={intl.formatMessage({
                      id: "onboarding.candidate.steps.twelve.black_listed_placeholder",
                      defaultMessage: "Select Companies",
                    })}
                  />
                </MultiSelectorTrigger>
                <MultiSelectorContent>
                  <MultiSelectorList>
                    {data &&
                      data.map((option, i) => (
                        <MultiSelectorItem key={i} value={`${option.id}`}>
                          {option.name}
                        </MultiSelectorItem>
                      ))}
                  </MultiSelectorList>
                </MultiSelectorContent>
              </MultiSelector>
              <FormMessage className="text-red-600" />
              <div className="flex gap-2 items-center">
                {field.value &&
                  field.value.map(item => (
                    <Badge key={item} className={cn("px-1 rounded-xl flex items-center gap-1 w-max")} variant="default">
                      <span className="text-xs">{getLabel(item)}</span>
                      <button
                        aria-label={`Remove ${item} option`}
                        aria-roledescription="button to remove option"
                        type="button"
                        onClick={() => {
                          field.onChange(field.value!.filter(v => v !== item));
                        }}
                      >
                        <span className="sr-only">Remove {item} option</span>
                        <RemoveIcon className="h-4 w-4 hover:stroke-destructive" />
                      </button>
                    </Badge>
                  ))}
              </div>
              <FormMessage className="text-red-600" />
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};

export default StepTwelveForm;
