import { z } from "zod";

import { createIntl } from "react-intl";

import { PhoneNumberUtil } from "google-libphonenumber";

/*
 * this is where you define the schema for each step of the onboarding form
 * You can add the necessary fields for each step
 * You can also add validation rules for each field
 */

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  if (!phone) {
    return false;
  }
  if (phone.trim().length <= 3) {
    return false;
  }
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const intl = createIntl({
  locale: "ro-RO",
  messages: {
    "errors.required": "Acest câmp este obligatoriu",
    "errors.invalid_email": "Adresa de email este invalidă",
    "errors.password_too_short": "Parola trebuie să aibă minim 8 caractere",
    "errors.password_mismatch": "Parolele nu se potrivesc",
    "errors.min_salary": "Salariul minim trebuie să fie mai mare decât 0",
    "errors.min_salary_number": "Salariul minim trebuie să fie un număr",
    "errors.phone_number_too_short": "Numărul de telefon trebuie să aibă minim 10 caractere",
    "errors.invalid_phone_number": "Numărul de telefon este invalid",
  },
});

export const MAX_FILE_SIZE = 5000000;
export const ACCEPTED_FILE_TYPES = ["pdf", "doc", "docx", "text/plain", "text/csv"];

const categorySchema = z.object({
  category_id: z.string().optional(),
  tags: z.array(z.string()).optional(),
  is_main: z.boolean().optional(),
  years: z.string().optional(),
  subcategories: z
    .array(
      z.object({
        subcategory_id: z.string().min(1, { message: intl.formatMessage({ id: "errors.required" }) }),
        years: z.string().optional(),
      })
    )
    .optional(),
});

export const onBoardingFormSchema = z.object({
  name: z.string({ required_error: intl.formatMessage({ id: "errors.required" }) }).min(1, {
    message: intl.formatMessage({ id: "errors.required" }),
  }),
  email: z
    .string({ required_error: intl.formatMessage({ id: "errors.required" }) })
    .email({ message: intl.formatMessage({ id: "errors.invalid_email" }) }),
  work_type: z.number(),
  city: z.string({ required_error: intl.formatMessage({ id: "errors.required" }) }),
  work_locations: z
    .array(
      z.string({
        required_error: intl.formatMessage({ id: "errors.required" }),
        invalid_type_error: intl.formatMessage({ id: "errors.required" }),
      })
    )
    .nonempty({ message: intl.formatMessage({ id: "errors.required" }) }),
  categories: z.array(categorySchema).min(1, { message: intl.formatMessage({ id: "errors.required" }) }),
  max_distance_in_km: z.string().optional().default("0"),
  agrees_to_abroad_work: z.enum(["yes", "no"]).optional().default("no"),
  phone: z
    .string()
    .min(1, { message: intl.formatMessage({ id: "errors.required" }) })
    .refine(isPhoneValid, {
      message: intl.formatMessage({ id: "errors.invalid_phone_number" }),
    }),
  certification: z.string().optional(),
  job_title: z.string({ required_error: intl.formatMessage({ id: "errors.required" }) }),
  secondary_category: z.string().optional(),
  secondary_sub_categories: z.array(z.string()).optional(),
  secondary_tags: z.array(z.string()).optional(),
  years_of_experience: z.string({ required_error: intl.formatMessage({ id: "errors.required" }) }),
  notification_preferences: z.string().array().optional(),
  min_salary: z.string({ required_error: intl.formatMessage({ id: "errors.required" }) }).min(1, {
    message: intl.formatMessage({ id: "errors.required" }),
  }),
  job_search_status: z.string().optional(),
  resume: z.any().optional(),
  terms: z.boolean({ required_error: intl.formatMessage({ id: "errors.required" }) }),
  black_listed: z.string().array().optional(),
  abroad_work_countries: z.string().array().optional(),
  language_ability: z.string().optional(),
  password: z
    .string({ required_error: intl.formatMessage({ id: "errors.required" }) })
    .min(8, { message: intl.formatMessage({ id: "errors.password_too_short" }) }),
  confirm_password: z.string({ required_error: intl.formatMessage({ id: "errors.required" }) }),
  receive_notifications: z.boolean().optional(),
  main_work_city_id: z.string().optional(),
});
