import { getAngularCredentials } from "@/core/auth/utils";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/_dashboard-layout/needs/add")({
  component: () => <div />,
  beforeLoad: () => {
    const AngularCredentials = getAngularCredentials();
    const ngApp = import.meta.env.VITE_RECRUITER_APP_URL;

    if (!AngularCredentials) {
      return redirect({
        to: "/login",
        replace: true,
      });
    }
    if (AngularCredentials?.profileType !== 1) {
      return redirect({
        to: "/dashboard/candidate",
      });
    }
    window.location.href = `${ngApp}/needs/add`;
  },
});
