import { Axios } from "@/libs/utils/axios-interceptor";
import { createMutation } from "react-query-kit";

interface RejectCandidateProps {
  process_id: string;
  reasons: string;
}

export const useRejectCandidate = createMutation({
  mutationFn: async ({ process_id, reasons }: RejectCandidateProps) =>
    Axios.post(`/processes/${process_id}/rejectcandidate`, {
      note: reasons,
    }),
});
