import clsx from "clsx";

interface StepperProps {
  steps: number;
  activeStep: number;
  // this prop will likely be removed or changed in the future depending on the requirements
  onStepChange?: (step: number) => void;
}

const StepperIndicator = ({ activeStep, steps }: StepperProps) => {
  const getActiveColor = (step: number) => (step === activeStep - 1 ? "bg-medi-blue/50" : "bg-medi-blue/10");

  return (
    <div className="flex gap-2 w-full">
      {Array.from({ length: steps }, (_, index) => (
        <span
          key={index}
          className={clsx(
            "h-1.5 flex-1 transition-all",
            getActiveColor(index),
            index < activeStep - 1 && "bg-medi-blue/100"
          )}
        />
      ))}
    </div>
  );
};

export default StepperIndicator;
