import { AxiosV2 } from "@/libs/utils/axios-interceptor";
import { useQuery } from "@tanstack/react-query";

export const useGetEventConfig = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["event-config"],
    queryFn: async () => {
      const { data } = await AxiosV2.get<ApiEventConfig[]>(`/event-management/event-type/`);

      return data;
    },
  });

  return { data, isLoading };
};
