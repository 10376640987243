import { createIntl } from "react-intl";
import { z } from "zod";

const intl = createIntl({
  locale: "ro-RO",
  messages: {
    "errors.required": "Acest câmp este obligatoriu",
    "errors.invalid_email": "Adresa de email este invalidă",
    "errors.password_too_short": "Parola trebuie să aibă minim 8 caractere",
    "errors.password_mismatch": "Parolele nu se potrivesc",
  },
});

export const changePasswordSchema = z
  .object({
    email: z.string({ required_error: intl.formatMessage({ id: "errors.required" }) }).email({
      message: intl.formatMessage({ id: "errors.invalid_email" }),
    }),
    password: z.string({ required_error: intl.formatMessage({ id: "errors.required" }) }).min(8, {
      message: intl.formatMessage({ id: "errors.password_too_short" }),
    }),
    confirm_password: z.string({ required_error: intl.formatMessage({ id: "errors.required" }) }).min(3, {
      message: intl.formatMessage({ id: "errors.required" }),
    }),
  })
  .refine(data => data.password === data.confirm_password, {
    message: intl.formatMessage({ id: "errors.password_mismatch" }),
    path: ["confirm_password"],
  });
