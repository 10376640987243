import { Axios } from "@/libs/utils/axios-interceptor";
import { createMutation } from "react-query-kit";

interface SendFinalOfferProps {
  offer_id: string;
}

export interface Value {
  salary: string;
  startDate: Date;
  contractPeriod: string;
  benefits?: string;
}

export const useAcceptFinalOffer = createMutation({
  mutationFn: async ({ offer_id }: SendFinalOfferProps) => Axios.post(`/offers/${offer_id}/acceptfinaloffer`),
});
