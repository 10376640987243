import { getAngularToken } from "@/core/auth/utils";

import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_dashboard-layout/dashboard/company")({
  component: () => <div />,
  beforeLoad: () => {
    const token = getAngularToken();
    const ngApp = import.meta.env.VITE_RECRUITER_APP_URL;

    if (token) {
      window.location.href = `${ngApp}/dashboard`;
    }
  },
});
