import { AxiosV2 } from "@/libs/utils/axios-interceptor";
import { createMutation } from "react-query-kit";

interface RejectProposedTimePayload {
  event_id: number;
}

interface RejectProposedTimeResponse {
  id: number;
  status: string;
}

export const useRejectProposedTime = createMutation<RejectProposedTimeResponse, RejectProposedTimePayload>({
  mutationFn: async payload => {
    const data = await AxiosV2.post(`/event-management/events/${payload.event_id}/reject-times/`);

    return data.data as RejectProposedTimeResponse;
  },
});
