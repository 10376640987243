export default {
  common: {
    yes: "Da",
    no: "Nu",
    next_step: "Pasul următor",
    back: "Înapoi",
    prev_step: "Pasul anterior",
    finish: "Finalizare",
    skip_to_dashboard: "Sari peste acest pas",
    already_have_an_account: "Ai deja cont?",
    log_in: "Autentifică-te!",
    auth: "Autentifică-te",
    error: {
      required: "Acest câmp este obligatoriu",
      email: "Vă rugăm să introduceți o adresă de email validă",
      password: "Parola trebuie să aibă cel puțin 8 caractere",
      password_mismatch: "Parolele nu se potrivesc",
      min: "Valoarea minimă este {min}",
      max: "Valoarea maximă este {max}",
      invalid: "Valoare invalidă",
      invalid_file_type: "Tip de fișier invalid",
      invalid_file_size: "Dimensiunea fișierului este prea mare. Dimensiunea maximă este {size}",
      invalid_file: "Fișier invalid",
      invalid_date: "Dată invalidă",
      invalid_number: "Număr invalid",
      invalid_url: "URL invalid",
      invalid_phone: "Număr de telefon invalid",
      invalid_postal_code: "Cod poștal invalid",
      invalid_credit_card: "Număr de card de credit invalid",
      invalid_cv: "CV invalid",
      invalid_password: "Parolă invalidă",
      invalid_username: "Nume de utilizator invalid",
      invalid_name: "Nume invalid",
      invalid_address: "Adresă invalidă",
      invalid_city: "Oraș invalid",
      invalid_country: "Țară invalidă",
      invalid_state: "Județ invalid",
      invalid_zip: "Cod poștal invalid",
      invalid_phone_number: "Număr de telefon invalid",
      invalid_email: "Adresă de email invalidă",
    },
    contact_methods: {
      phone_call: "Apel telefonic",
      email: "Email",
      text_message: "Mesaj text",
      whatsapp: "WhatsApp",
    },
    job_search_status: {
      ready: "Sunt gata să încep interviurile",
      interviewing: "În proces de intervievare",
      not_ready: "Nu caut în prezent",
    },
  },
};
