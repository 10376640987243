import { messages } from "./auth-data";
import { Button } from "@/components/button/Button";
import MedijobsLogo from "@/assets/images/medijobs_logo_subtext.svg";

import { Link, useNavigate } from "@tanstack/react-router";
import { Route } from "@/routes/_auth-layout/password/reset/$token/index";

import Input from "@/components/input/input";
import { Typography } from "@/components/typography";
import { useIntl } from "react-intl";

import { z } from "zod";
import { changePasswordSchema } from "@/libs/utils/schemas/change-password";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormField, FormItem, FormControl, FormLabel, FormMessage, Form } from "@/components/form";
import { useCreatePassword } from "@/core/hooks/mutations/useCreatePassword";
import { toast } from "sonner";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";

type FormType = z.infer<typeof changePasswordSchema>;

const PasswordFormPage = () => {
  const intl = useIntl();

  const form = useForm<FormType>({
    resolver: zodResolver(changePasswordSchema),
  });

  useDocumentTitle("Restabilire parola");

  const { token } = Route.useParams();

  const { mutateAsync, isPending } = useCreatePassword();
  const navigate = useNavigate();

  const handleLoginSubmit = async (data: FormType) => {
    if (!token) {
      toast.error(intl.formatMessage({ id: "errors.something_went_wrong" }));
    }
    const payload = { ...data, token };

    await mutateAsync(payload, {
      onSuccess: () => {
        toast.success(intl.formatMessage({ id: "login.password_reset_success" }));
        void navigate({
          to: "/login",
        });
      },
      onError: error => {
        toast.error(error.message);
      },
    });
  };

  return (
    <div className="md:max-w-lg md:mx-auto">
      <img alt="Medijobs Logo" src={MedijobsLogo} width={150} />

      <div className="mt-6">
        <Typography variant="h2">Restabilește parola</Typography>

        <Typography variant="p">{intl.formatMessage({ id: "login.set_new_password" })}</Typography>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleLoginSubmit)} className="flex flex-col gap-4">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{messages.ro.funnel.login.email}</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder={messages.ro.funnel.login.emailPlacehold} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{messages.ro.funnel.login.password}</FormLabel>
                  <FormControl>
                    <Input {...field} type="password" placeholder="Parola nouă" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="confirm_password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Confirmă parola</FormLabel>
                  <FormControl>
                    <Input {...field} type="password" placeholder="Confirmă parola" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button disabled={isPending} loading={isPending} type="submit" variant="default">
              Restabiliește
            </Button>
          </form>
        </Form>

        <div className="flex mt-4 gap-1">
          {intl.formatMessage({ id: "login.no_account" })}
          <Link className="text-medi-blue" to="/register">
            {intl.formatMessage({ id: "login.create_account" })}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PasswordFormPage;
