import { AxiosV2 } from "@/libs/utils/axios-interceptor";
import { createMutation } from "react-query-kit";

interface RequestFollowUpInterviewProps {
  process_id: string;
}

export const useRequestFollowUpInterview = createMutation({
  mutationFn: async ({ process_id }: RequestFollowUpInterviewProps) =>
    AxiosV2.post(`/recruitment/process/${process_id}/request-followup-interview/`),
});
