import clsx from "clsx";
import { PhoneInput, PhoneInputProps } from "react-international-phone";
import "react-international-phone/style.css";

interface MDPhoneInputProps extends PhoneInputProps {}

const MDPhoneInput = ({ value, defaultCountry, inputRef, className, ...props }: MDPhoneInputProps) => (
  <PhoneInput
    inputClassName="flex-1 !h-full"
    countrySelectorStyleProps={{ buttonClassName: "!h-full !px-2" }}
    className={clsx("flex w-full h-12 shadow-md rounded-sm", className)}
    defaultCountry={defaultCountry ?? "ro"}
    value={value}
    inputRef={inputRef}
    {...props}
  />
);

export default MDPhoneInput;
