import { FormField, FormItem } from "@/components/form";
import { formSchemaType } from "@/components/Layouts/onboarding";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/select/select";

import { Typography } from "@/components/typography";
import { getEducations } from "@/services/api/auth";
import { useQuery } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

const StepFourForm = () => {
  const form = useFormContext<formSchemaType>();
  const intl = useIntl();

  const { data } = useQuery({
    queryKey: ["educations"],
    queryFn: getEducations,
  });

  const educations = data?.data ?? [];

  return (
    <div className="flex flex-col w-full">
      <Typography className="text-center">
        <FormattedMessage
          id="onboarding.candidate.steps.four.certification_label"
          defaultMessage="What is the most recent educational institution you graduated from?"
        />
      </Typography>

      <div className="flex mt-10 items-center gap-4 justify-center">
        <FormField
          control={form.control}
          name="certification"
          render={({ field }) => (
            <FormItem className="w-full">
              <Select defaultValue={field.value} onValueChange={field.onChange}>
                <SelectTrigger>
                  <SelectValue
                    placeholder={intl.formatMessage({
                      id: "onboarding.candidate.steps.four.certification_placeholder",
                      defaultMessage: "Select Certification",
                    })}
                  />
                </SelectTrigger>
                <SelectContent>
                  {educations.length &&
                    educations.map((option, i) => (
                      <SelectItem value={`${option.id}`} key={i}>
                        {option.value}
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};

export default StepFourForm;
