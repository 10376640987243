import { FormControl, FormField, FormItem, FormLabel } from "@/components/form";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/select/select";
import { Typography } from "@/components/typography";
import { useFetchCategories } from "@/libs/hooks/useFetchCategories";
import { store } from "@/libs/store";
import { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useFieldArray, useFormContext } from "react-hook-form";
import { formSchemaType } from "@/components/Layouts/onboarding";
import { useFetchExperience } from "@/libs/hooks/useFetchExperience";

const StepEightForm = () => {
  const intl = useIntl();

  const { user } = store();
  const [isMounted, setIsMounted] = useState(false);
  const [yearsOptionsMap, setYearsOptionsMap] = useState<Record<string, number[]>>({});

  const { data: fetchedExperience } = useFetchExperience();
  const { data: fetchedCategories } = useFetchCategories();

  const { control } = useFormContext<formSchemaType>();
  const { fields, append, update } = useFieldArray({
    control,
    name: "categories",
  });

  const getYearsOptions = (experienceLevel: number, fieldId: string) => {
    let options: number[];

    switch (experienceLevel) {
      case 0:
        options = [0, 1];
        break;
      case 1:
        options = [2, 3, 4, 5];
        break;
      case 5:
        options = [6, 7, 8, 9, 10];
        break;
      case 10:
        options = [11, 12, 13, 14, 30];
        break;
      default:
        options = [];
    }
    setYearsOptionsMap(prev => ({ ...prev, [fieldId]: options }));
  };

  const domainExperiences = user?.candidate_object?.domain_experiences || [];
  const mainJobRole = domainExperiences.find(exp => exp.is_main);
  const mainJobSubcategories =
    domainExperiences
      .filter(exp => exp.is_main === 0)
      .map(exp => ({
        subcategory_id: String(exp.subcategory?.id) || undefined,
        years: exp.years || undefined,
      })) || [];

  useEffect(() => {
    if (mainJobRole) {
      update(0, {
        category_id: mainJobRole.category.id.toString(),
        subcategories: mainJobSubcategories.map(sub => ({
          subcategory_id: sub.subcategory_id || "",
          years: sub.years?.toString(),
        })),
        is_main: true,
        years: undefined,
      });
      setIsMounted(true);
    }
  }, [mainJobRole, domainExperiences, update, append]);

  const getSubcategoryTitle = (subcategory: string, mainCategory: string) => {
    const subcategoryTitle = fetchedCategories
      ?.filter(category => category.id === Number(mainCategory))
      .flatMap(category => category.subcategories)
      .find(sub => sub.id === Number(subcategory))?.value;

    return subcategoryTitle;
  };

  const getCategoryTitle = (category: string) => {
    const categoryTitle = fetchedCategories?.find(cat => cat.id === Number(category))?.value;

    return categoryTitle;
  };

  if (!isMounted) {
    return null;
  }

  return (
    <div className="flex flex-col w-full space-y-4">
      <Typography variant="h3" className="font-semibold">
        {mainJobRole?.category.value}
      </Typography>
      <div className="flex flex-col gap-3">
        {fields[0].subcategories && fields[0].subcategories?.length > 0 ? (
          fields[0].subcategories?.map((sub, index) => (
            <div key={index}>
              <FormLabel>
                <FormattedMessage
                  id="onboarding.candidate.steps.eight.years_of_experience_label"
                  defaultMessage="How many years of experience do you have in {category}?"
                  values={{
                    category: `${mainJobRole?.category.value} ${getSubcategoryTitle(sub.subcategory_id, String(mainJobRole?.category_id ?? ""))}`,
                  }}
                />
              </FormLabel>

              <FormField
                key={fields[0]?.id}
                control={control}
                name={`categories.${0}.subcategories`}
                render={({ field }) => (
                  <FormItem className="flex items-center gap-4 w-full">
                    <Select
                      onValueChange={value => {
                        getYearsOptions(Number(value), `0_${index}`);
                      }}
                    >
                      <div className="flex-1">
                        <SelectTrigger>
                          <SelectValue
                            placeholder={intl.formatMessage({
                              id: "onboarding.candidate.steps.eight.experience_level_placeholder",
                              defaultMessage: "Select Experience Level",
                            })}
                          />
                        </SelectTrigger>

                        <SelectContent>
                          {fetchedExperience.map((option, i) => (
                            <SelectItem value={String(option.value)} key={i}>
                              {option.title}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </div>
                    </Select>
                    <Select
                      value={field.value?.[index]?.years || ""}
                      onValueChange={value => {
                        const updatedSubcategories = field.value ? [...field.value] : [];

                        updatedSubcategories[index] = {
                          ...updatedSubcategories[index],
                          years: String(value),
                        };
                        field.onChange(updatedSubcategories);
                      }}
                    >
                      <div className="flex-1 pb-2">
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue
                              placeholder={intl.formatMessage({
                                id: "onboarding.candidate.steps.eight.years_of_experience_placeholder",
                                defaultMessage: "Select Years of Experience",
                              })}
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {yearsOptionsMap[`0_${index}`]?.map((option, i) => (
                            <SelectItem value={String(option)} key={i}>
                              {option}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </div>
                    </Select>
                  </FormItem>
                )}
              />
            </div>
          ))
        ) : (
          <div className="flex flex-col">
            <FormLabel>
              <FormattedMessage
                id="onboarding.candidate.steps.eight.years_of_experience_label"
                defaultMessage="How many years of experience do you have in {category}?"
                values={{ category: mainJobRole?.category.value }}
              />
            </FormLabel>
            <FormField
              control={control}
              name={`categories.${0}.years`}
              render={({ field }) => (
                <FormItem className="flex items-center gap-4 w-full">
                  <Select
                    onValueChange={value => {
                      getYearsOptions(Number(value), "0");
                    }}
                  >
                    <div className="flex-1">
                      <SelectTrigger>
                        <SelectValue
                          placeholder={intl.formatMessage({
                            id: "onboarding.candidate.steps.eight.experience_level_placeholder",
                            defaultMessage: "Select Years of Experience",
                          })}
                        />
                      </SelectTrigger>

                      <SelectContent>
                        {fetchedExperience.map((option, i) => (
                          <SelectItem value={String(option.value)} key={i}>
                            {option.title}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </div>
                  </Select>
                  <Select value={String(field.value || "")} onValueChange={value => field.onChange(String(value))}>
                    <div className="flex-1 pb-2">
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue
                            placeholder={intl.formatMessage({
                              id: "onboarding.candidate.steps.eight.years_of_experience_placeholder",
                              defaultMessage: "Select Years of Experience",
                            })}
                          />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {yearsOptionsMap["0"]?.map((option, i) => (
                          <SelectItem value={String(option)} key={i}>
                            {option}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </div>
                  </Select>
                </FormItem>
              )}
            />
          </div>
        )}
      </div>
      {fields[1].category_id && (
        <div className="flex flex-col gap-3">
          <Typography variant="h3" className="font-semibold">
            {getCategoryTitle(fields[1].category_id)}
          </Typography>
          <div className="flex flex-col gap-3">
            {fields[1].subcategories && fields[1].subcategories?.length > 0 ? (
              fields[1].subcategories?.map((sub, index) => (
                <div key={index}>
                  <FormLabel>
                    <FormattedMessage
                      id="onboarding.candidate.steps.eight.years_of_experience_label"
                      defaultMessage="How many years of experience do you have in {category}?"
                      values={{
                        category: `${getCategoryTitle(fields[1]?.category_id ?? "")} ${getSubcategoryTitle(sub.subcategory_id, String(fields[1]?.category_id ?? ""))}`,
                      }}
                    />
                  </FormLabel>
                  <FormField
                    control={control}
                    name={`categories.${1}.subcategories`}
                    render={({ field }) => (
                      <FormItem className="flex items-center gap-4 w-full">
                        <Select
                          onValueChange={value => {
                            getYearsOptions(Number(value), `1_${index}`);
                          }}
                        >
                          <div className="flex-1">
                            <SelectTrigger>
                              <SelectValue
                                placeholder={intl.formatMessage({
                                  id: "onboarding.candidate.steps.eight.years_of_experience_placeholder",
                                  defaultMessage: "Select Years of Experience",
                                })}
                              />
                            </SelectTrigger>

                            <SelectContent>
                              {fetchedExperience.map((option, i) => (
                                <SelectItem value={String(option.value)} key={i}>
                                  {option.title}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </div>
                        </Select>
                        <Select
                          value={field.value?.[index]?.years || ""}
                          onValueChange={value => {
                            const updatedSubcategories = field.value ? [...field.value] : [];

                            updatedSubcategories[index] = {
                              ...updatedSubcategories[index],
                              years: String(value),
                            };
                            field.onChange(updatedSubcategories);
                          }}
                        >
                          <div className="flex-1 pb-2">
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue
                                  placeholder={intl.formatMessage({
                                    id: "onboarding.candidate.steps.eight.years_of_experience_placeholder",
                                    defaultMessage: "Select Years of Experience",
                                  })}
                                />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {yearsOptionsMap[`1_${index}`]?.map((option, i) => (
                                <SelectItem value={String(option)} key={i}>
                                  {option}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </div>
                        </Select>
                      </FormItem>
                    )}
                  />
                </div>
              ))
            ) : (
              <div className="flex flex-col">
                <FormLabel>
                  <FormattedMessage
                    id="onboarding.candidate.steps.eight.years_of_experience_label"
                    defaultMessage="How many years of experience do you have in {category}?"
                    values={{ category: getCategoryTitle(fields[1]?.category_id ?? "") }}
                  />
                </FormLabel>
                <FormField
                  control={control}
                  name={`categories.${1}.years`}
                  render={({ field }) => (
                    <FormItem className="flex items-center gap-4 w-full">
                      <Select
                        onValueChange={value => {
                          getYearsOptions(Number(value), "1");
                        }}
                      >
                        <div className="flex-1">
                          <SelectTrigger>
                            <SelectValue
                              placeholder={intl.formatMessage({
                                id: "onboarding.candidate.steps.eight.experience_level_placeholder",
                                defaultMessage: "Select Years of Experience",
                              })}
                            />
                          </SelectTrigger>

                          <SelectContent>
                            {fetchedExperience.map((option, i) => (
                              <SelectItem value={String(option.value)} key={i}>
                                {option.title}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </div>
                      </Select>

                      <Select value={String(field.value ?? "")} onValueChange={value => field.onChange(String(value))}>
                        <div className="flex-1 pb-2">
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue
                                placeholder={intl.formatMessage({
                                  id: "onboarding.candidate.steps.eight.years_of_experience_placeholder",
                                  defaultMessage: "Select Years of Experience",
                                })}
                              />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {yearsOptionsMap["1"]?.map((option, i) => (
                              <SelectItem value={String(option)} key={i}>
                                {option}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </div>
                      </Select>
                    </FormItem>
                  )}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default StepEightForm;
