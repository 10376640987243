import { Typography } from "@/components/typography";

import { FormField, FormItem, FormMessage } from "@/components/form";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/select/select";
import { FormattedMessage } from "react-intl";
import { useFormContext } from "react-hook-form";
import { formSchemaType } from "@/components/Layouts/onboarding";
import useFetchIdentifications from "@/libs/hooks/useFetchIdentifications";

const StepElevenForm = () => {
  const form = useFormContext<formSchemaType>();

  const { data: identifications } = useFetchIdentifications();

  return (
    <div className="flex flex-col w-full">
      <Typography className="text-center">
        <FormattedMessage
          id="onboarding.candidate.steps.eleven.job_search_status_label"
          defaultMessage="Let us know how actively you are looking for a new job so we can connect you with opportunities more quickly."
        />
      </Typography>

      <div className="flex mt-10 items-center gap-4 justify-center">
        <FormField
          control={form.control}
          name="job_search_status"
          render={({ field }) => (
            <FormItem className="w-full">
              <Select defaultValue={field.value} onValueChange={field.onChange}>
                <SelectTrigger>
                  <SelectValue
                    placeholder={
                      <FormattedMessage
                        id="onboarding.candidate.steps.eleven.job_search_status_placeholder"
                        defaultMessage="Select Job Search Status"
                      />
                    }
                  />
                </SelectTrigger>
                <SelectContent>
                  {identifications.length &&
                    identifications.map((option, i) => (
                      <SelectItem value={`${option.id}`} key={i}>
                        {option.value}
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
              <FormMessage className="text-red-600" />
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};

export default StepElevenForm;
