interface NestedMessages {
  [key: string]: string | NestedMessages;
}

export function flattenMessages(nestedMessages: NestedMessages, prefix = ""): Record<string, string> {
  return Object.keys(nestedMessages).reduce(
    (messages, key) => {
      const translatedValue = nestedMessages[key];
      const prefixedKey = prefix ? `${prefix}.${key}` : key;

      if (typeof translatedValue === "string") {
        messages[prefixedKey] = translatedValue;
      } else {
        Object.assign(messages, flattenMessages(translatedValue, prefixedKey));
      }

      return messages;
    },
    {} as Record<string, string>
  );
}
