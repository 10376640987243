import { Axios } from "@/libs/utils/axios-interceptor";
import { createMutation } from "react-query-kit";

export interface AcceptCandidateProps {
  process_id: string;
  interview_type: string;
  salary?: number;
  address?: string;
  contact_person?: string;
  phone?: string;
}

export const useAcceptCandidate = createMutation({
  mutationFn: async ({ process_id, interview_type, salary, address, contact_person, phone }: AcceptCandidateProps) =>
    Axios.post(`/processes/${process_id}/acceptcandidate`, {
      type: interview_type,
      salary,
      address,
      contact_person,
      phone,
    }),
});
