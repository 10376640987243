import { Outlet } from "@tanstack/react-router";
import { TESTIMONIALS } from "./data";

export const AuthLayout = () => (
  <main className="grid grid-cols-12 h-screen">
    <section className="col-span-12 md:col-span-9 px-4 md:px-10 xl:px-40 h-max md:h-screen md:overflow-y-scroll py-10 md:py-0 md:pt-10 md:pb-20">
      <Outlet />
    </section>
    <div className="flex flex-col col-span-12  md:col-span-3 p-8 bg-medi-banner">
      <p className="font-light">
        Mulțumim pentru încrederea oferită de
        <br />
        <span className="font-medium text-lg">
          <b>2593 companii &amp; 94521 candidați</b>
        </span>
      </p>
      <div className="flex flex-col mt-10 gap-8">
        {TESTIMONIALS.map(({ title, name, quote, image }, idx) => (
          <div key={idx} className="flex gap-4 flex-col">
            <img src={image} alt={name} className="w-12 h-12 rounded-full" />
            <p className="text-slate-500">{quote}</p>
            <div>
              <p className="text-sm font-medium">{name}</p>
              <p className="text-xs font-light">{title}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </main>
);
