import { createIntl } from "react-intl";
import { z } from "zod";

const intl = createIntl({
  locale: "ro-RO",
  messages: {
    "errors.required": "Acest câmp este obligatoriu",
    "errors.invalid_email": "Adresa de email este invalidă",
  },
});

export const resetPasswordSchema = z.object({
  email: z.string({ required_error: intl.formatMessage({ id: "errors.required" }) }).email({
    message: intl.formatMessage({ id: "errors.invalid_email" }),
  }),
});
