import { getAccount } from "@/services/api/auth";
import { useQuery } from "@tanstack/react-query";

interface UseGetAccountResponse {
  data: ApiAccount;
}

const useGetAccount = (token: string) => {
  const { data, isLoading, isError } = useQuery<UseGetAccountResponse, Error>({
    queryKey: ["user"],
    queryFn: async () => getAccount(token),
    enabled: !!token,
  });

  return { data: data?.data, isLoading, isError };
};

export { useGetAccount };
