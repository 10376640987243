import { useQuery } from "@tanstack/react-query";
import { getIdentifications } from "@/services/api/auth";

const useFetchIdentifications = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["identifications"],
    queryFn: getIdentifications,
  });

  return {
    data: data?.data ?? [],
    isLoading,
    isError,
  };
};

export default useFetchIdentifications;
