import CandidatePage from "@/pages/company/candidate";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const searchParamsSchema = z.object({
  cancel: z.union([z.literal("true"), z.literal(true)]).optional(),
  finalize: z.union([z.literal("true"), z.literal(true)]).optional(),
});

export type SearchParams = z.infer<typeof searchParamsSchema>;

export const Route = createFileRoute("/_dashboard-layout/needs/$id/candidate/$slug")({
  component: () => <CandidatePage />,
  validateSearch: searchParamsSchema,
});
