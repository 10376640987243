import { Axios } from "@/libs/utils/axios-interceptor";

import { createMutation } from "react-query-kit";

export interface LoginPayload {
  email: string;
  password: string;
}

interface LoginResponse {
  token: string;
  error?: string;
}

export const useLogin = createMutation<LoginResponse, LoginPayload>({
  mutationFn: async payload => {
    const response = await Axios.post("/login", { email: payload.email, password: payload.password });

    return response.data as LoginResponse;
  },
});
