import OfferDetailsPage from "@/pages/offer/candidate";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const searchParamsSchema = z.object({
  cancel: z.union([z.literal("true"), z.literal(true)]).optional(),
  reject: z.union([z.literal("true"), z.literal(true)]).optional(),
});

export type SearchParams = z.infer<typeof searchParamsSchema>;

export const Route = createFileRoute("/_dashboard-layout/offers/$id")({
  component: () => <OfferDetailsPage />,
  validateSearch: searchParamsSchema,
});
