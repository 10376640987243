import { Axios } from "@/libs/utils/axios-interceptor";
import { createMutation } from "react-query-kit";

interface SendFinalOfferProps {
  process_id: string;
  value?: Value;
}

export interface Value {
  salary: string;
  startDate: Date;
  contractPeriod: string;
  benefits?: string;
}

export const useSendFinalOffer = createMutation({
  mutationFn: async ({ process_id, value }: SendFinalOfferProps) =>
    Axios.post(`/processes/${process_id}/makefinaloffer`, {
      ...value,
    }),
});
