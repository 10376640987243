import { useQuery } from "@tanstack/react-query";

import { Axios } from "@/libs/utils/axios-interceptor";

export interface DashboardOffer {
  id: number;
  position: string;
  city: string;
  county: string;
  created_at: Date;
  interviewCount: number;
  need: ApiNeed;
}
interface FetchOffersResponse {
  data: ApiOffers;
}
const useGetOfferByProcessId = (shouldFetch: boolean = true, id?: number) => {
  const { data, isLoading, error } = useQuery<FetchOffersResponse, Error>({
    queryKey: ["offer", id],
    queryFn: async () => Axios.get(`/offers?process=${id}`),
    enabled: shouldFetch,
  });

  return {
    data: data?.data,
    isLoading,
    error,
  };
};

export { useGetOfferByProcessId };
