import { AxiosV2 } from "@/libs/utils/axios-interceptor";
import { createMutation } from "react-query-kit";

interface UseUpdateAvailabilityResponse {
  id: string;
}

interface UseUpdateAvailabilityPayload {
  id: string;
  user: string;
  start_time: string;
  end_time: string;
  title: string;
  weekday: string;
}

export const useUpdateAvailability = createMutation<UseUpdateAvailabilityResponse, UseUpdateAvailabilityPayload>({
  mutationFn: async payload => {
    const { id, ...rest } = payload;
    const response = await AxiosV2.patch<UseUpdateAvailabilityResponse>(
      `/event-management/availability/${id}/?user=${rest.user}`,
      rest
    );

    return response.data;
  },
});
