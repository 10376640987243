import { Axios } from "@/libs/utils/axios-interceptor";

import { createMutation } from "react-query-kit";

export interface LoginPayload {
  email: string;
}

interface LoginResponse {
  token: string;
  error?: string;
}

export const useForgotPassword = createMutation<LoginResponse, LoginPayload>({
  mutationFn: async payload => {
    const response = await Axios.post<LoginResponse>("/forgot-password", payload);

    return response.data;
  },
});
