import { useQuery } from "@tanstack/react-query";

import { Axios } from "@/libs/utils/axios-interceptor";

interface FetchProcessesResponse {
  data: ApiOffers[];
}
const useGetProcesses = (shouldFetch: boolean = true, candidateId?: number, needId?: number) => {
  const query = new URLSearchParams();

  if (candidateId) {
    query.set("candidate_id", candidateId.toString());
  }

  if (needId) {
    query.set("need_id", needId.toString());
  }

  const { data, isLoading, error } = useQuery<FetchProcessesResponse, Error>({
    queryKey: ["processes", candidateId, needId],
    queryFn: async () => Axios.get(`/processes?${query.toString()}`),
    enabled: shouldFetch,
  });

  return {
    data: data?.data ?? [],
    isLoading,
    error,
  };
};

export { useGetProcesses };
