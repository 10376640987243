import { store } from "@/libs/store";
import { createCandidate } from "@/services/api/auth";
import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";

export interface CreateCandidatePayload {
  name: string;
  email: string;
  password: string;
  terms: boolean;
}

interface CreateCandidateResponse {
  data: {
    candidate_id: string;
    access_token: string;
  };
}

declare global {
  interface Window {
    gtag: (
      command: "event" | "config" | "set" | "js",
      action: string,
      params?: { [key: string]: string | number | boolean | object }
    ) => void;
    fbq: (arg0: string | null, arg1: string | null) => void;
  }
}

const useCreateCandidate = () => {
  const { setUser } = store();

  const { data, mutate, isPending, isSuccess, error, isError } = useMutation<
    CreateCandidateResponse,
    ApiError,
    CreateCandidatePayload
  >({
    mutationFn: async (payload: CreateCandidatePayload) => createCandidate(payload),
    onSuccess: resp => {
      toast.success("Account created successfully");

      // trigger events for google and facebook
      if (import.meta.env.VITE_ENABLE_TRACKING === "1") {
        window.gtag("event", "conversion", { send_to: "AW-944951917/PtjmCOv8yWcQ7aTLwgM" });
        window.fbq("track", "GM-Candidat-V3-Inregistreaza");
      }

      setUser(resp.data as unknown as ApiUser);
    },
    onError: error => {
      console.log(error.message);
      toast.error(error.message);
    },
  });

  return { data, mutate, isPending, isSuccess, error, isError };
};

export { useCreateCandidate };
