import { ChangeEvent, FormEvent, useEffect, useState } from "react";

import { messages } from "./auth-data";
import { Button } from "@/components/button/Button";
import MedijobsLogo from "@/assets/images/medijobs_logo_subtext.svg";
import { getLocations, getPositions, registerCompany } from "@/services/api/auth";

import Input from "@/components/input/input";
import { Link } from "@tanstack/react-router";
import { Select, SelectContent, SelectItem, SelectValue, SelectTrigger } from "@/components/select/select";

const RegisterCompanyPage = () => {
  const [email, setEmail] = useState("");
  const [utm_campaign, setUtmCampaign] = useState("");
  const [utm_source, setUtmSource] = useState("");
  const [utm_medium, setUtmMedium] = useState("");
  const [utm_content, setUtmContent] = useState("");
  const [http_referer, setHttpReferer] = useState("website");
  const [position, setPosition] = useState("");
  const [positionError, setPositionError] = useState(false);
  const [adsValue, setAdsValue] = useState(false);
  const [, setTermsError] = useState(false);
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState(false);
  const [locationSearchInput, setLocationSearchInput] = useState("" as string);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [vatError, setVatError] = useState(false);
  const [positions, setPositions] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState<ApiPlace[]>([]);
  const [vat, setVat] = useState("");

  // const [selectedSubCategory, setSelectedSubcategory] = useState("" as string);
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyNameError, setCompanyNameError] = useState(false);
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);

  // const [categories, setCategories] = useState([]);
  const [passwordError, setPasswordError] = useState(false);
  const [serverErrors, setServerErrors] = useState([] as string[]);
  const handleEmailChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setEmail(ev.target.value);
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  };

  const handlePasswordChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setPassword(ev.target.value);
  };
  const locationSearch = async (val: string) => {
    setLocationSearchInput(val);
    if (!val || (val && val.length < 3)) {
      return;
    }

    // items have already been requested
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const response = await getLocations(val);

    if (response) {
      setLocations(response.predictions);
      setIsLoading(false);
    }
  };
  const loadPositions = async () => {
    const response = await getPositions();

    setPositions(response);
  };
  const handleRegisterSubmit = async (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    let formHasErrors = false;

    if (email === "" || !isValidEmail(email)) {
      setEmailError(true);
      formHasErrors = true;
    }

    if (password === "") {
      setPasswordError(true);
      formHasErrors = true;
    }
    if (name === "") {
      setNameError(true);
      formHasErrors = true;
    }
    if (vat === "") {
      setVatError(true);
      formHasErrors = true;
    }
    if (position === "") {
      setPositionError(true);
      formHasErrors = true;
    }

    if (companyName === "") {
      setCompanyNameError(true);
      formHasErrors = true;
    }
    if (!termsAccepted) {
      setTermsError(true);
      formHasErrors = true;
    }
    if (!city) {
      setCityError(true);
      formHasErrors = true;
    }
    if (formHasErrors) {
      return;
    }

    /*
     *     the required params are:
     * 'name'      => 'required',
     * 'phone'     => 'required|unique:users|min:10',
     * 'email'     => 'required|email|unique:users',
     * 'password'  => 'required|min:8',
     * 'terms'     => 'required|in:1',
     * 'marketing' => 'required|in:0,1',
     * 'referer'   => 'required' (this the http_referer, the source url, in the current registration process, this is also saved for each new company or candidate)
     */

    const payload = {
      email,
      password,
      contact_name: name,
      vat,
      name: companyName,
      contact_function: position,
      place_id: city,
      terms: termsAccepted ? 1 : 0,
      marketing: adsValue ? 1 : 0,
      ads: adsValue ? 1 : 0,
      utm_campaign,
      utm_source,
      utm_medium,
      utm_content,
      referer: http_referer || "website",
    };
    const response = await registerCompany(payload);

    if (response.errors) {
      setServerErrors(Object.values(response.errors));
    } else {
      const token = response.access_token;
      const URL = import.meta.env.VITE_RECRUITER_APP_URL;

      // entering funnel here
      window.location.href = `${URL}/auth/callback#access_token=${token}&token_type=Bearer&expires_in=31622400`;
    }
  };

  const handleTermsChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(ev.target.checked);
  };
  const handlePositionChange = (value: string) => {
    setPosition(value);
  };
  const setUTMValues = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const utm_campaign = urlParams.get("utm_campaign") ?? "";
    const utm_source = urlParams.get("utm_source") ?? "";
    const utm_medium = urlParams.get("utm_medium") ?? "";
    const utm_content = urlParams.get("utm_content") ?? "";
    const http_referer = urlParams.get("http_referer") ?? "";

    setUtmCampaign(utm_campaign);
    setUtmSource(utm_source);
    setHttpReferer(http_referer);
    setUtmMedium(utm_medium);
    setUtmContent(utm_content);
  };
  const handleSetLocation = (loc: ApiPlace) => {
    setLocationSearchInput(loc.description);
    setCity(loc.place_id);
    setLocations([]);
  };

  useEffect(() => {
    loadPositions().catch(console.error);
    setUTMValues();
  }, []);
  return (
    <div>
      <div className="flex justify-between align-center">
        <img alt="Medijobs" src={MedijobsLogo} width={150} />

        <Link to="/register/candidate">Creează profil candidat</Link>
      </div>

      <div className="mt-10">
        {serverErrors.length > 0 && (
          <div className="container">
            <div className="layout column">
              {serverErrors.map((error, idx) => (
                <div key={`errors-${idx}`} className="error-alert">
                  {" "}
                  {error}
                </div>
              ))}
            </div>
          </div>
        )}

        <h2>{messages.ro.funnel.company.create}</h2>

        <p className="larger-text">{messages.ro.funnel.company.createSubtext}</p>

        <form className="flex flex-col gap-5 mt-6" onSubmit={handleRegisterSubmit}>
          <div className="input-container">
            <label htmlFor="name">{messages.ro.funnel.company.name}</label>

            <Input
              className="medijobs-input"
              name="name"
              placeholder={messages.ro.funnel.company.namePlacehold}
              type="text"
              value={companyName}
              onChange={ev => setCompanyName(ev.target.value)}
            />

            {companyNameError && (
              <span className="input-container__error">{messages.ro.funnel.common.requiredError}</span>
            )}
          </div>

          <div className="flex flex-col gap-1.5">
            <label htmlFor="vat">{messages.ro.funnel.company.vat}</label>

            <Input
              id="vat"
              name="name"
              placeholder={messages.ro.funnel.company.vatPlacehold}
              type="text"
              value={vat}
              onChange={ev => setVat(ev.target.value)}
            />

            {vatError && <span className="input-container__error">{messages.ro.funnel.common.requiredError}</span>}
          </div>

          <div className="flex flex-col gap-1.5">
            <label htmlFor="vat">{messages.ro.funnel.common.location}</label>

            <Input
              id="vat"
              name="name"
              placeholder={messages.ro.funnel.common.locationPlacehold}
              type="text"
              value={locationSearchInput}
              onChange={async ev => locationSearch(ev.target.value)}
            />

            {locations.length > 0 && (
              <div className="location-results">
                {locations.map(loc => (
                  <div
                    key={loc.place_id}
                    aria-hidden
                    className="location-result"
                    role="button"
                    tabIndex={0}
                    onClick={() => handleSetLocation(loc)}
                  >
                    {loc.description}
                  </div>
                ))}
              </div>
            )}

            {cityError && <span className="input-container__error">{messages.ro.funnel.common.requiredError}</span>}
          </div>

          <div className="flex flex-col gap-1.5">
            <label htmlFor="name">{messages.ro.funnel.company.representative}</label>

            <Input
              name="name"
              placeholder={messages.ro.funnel.company.representativePlacehold}
              type="text"
              value={name}
              onChange={ev => setName(ev.target.value)}
            />

            {nameError && <span className="input-container__error">{messages.ro.funnel.common.requiredError}</span>}
          </div>

          <div className="flex flex-col gap-1.5">
            <label htmlFor="job">{messages.ro.funnel.company.representativePosition}</label>

            <Select onValueChange={value => handlePositionChange(value)}>
              <SelectTrigger>
                <SelectValue placeholder={messages.ro.funnel.company.representativePositionPlacehold} />
              </SelectTrigger>
              <SelectContent>
                {positions.map((pos, idx) => (
                  <SelectItem key={`positions-idx-${idx}`} value={pos}>
                    {pos}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            {/* <select className="medijobs-input" value={position} onChange={handlePositionChange}>
              <option value="">{messages.ro.funnel.company.representativePositionPlacehold}</option>

              {positions.map((pos: string, idx) => (
                <option key={`positions-idx-${idx}`} value={pos}>
                  {pos}
                </option>
              ))}
            </select> */}

            {positionError && <span className="input-container__error">{messages.ro.funnel.common.requiredError}</span>}
          </div>

          <div className="flex flex-col gap-1.5">
            <label htmlFor="email">{messages.ro.funnel.candidate.email} *</label>

            <Input
              name="email"
              placeholder={messages.ro.funnel.candidate.emailPlacehold}
              type="text"
              value={email}
              onChange={handleEmailChange}
            />

            {emailError && (
              <span className="input-container__error">
                {email === "" ? messages.ro.funnel.common.requiredError : messages.ro.funnel.common.emailError}
              </span>
            )}
          </div>

          <div className="flex flex-col gap-1.5">
            <label htmlFor="password">{messages.ro.funnel.login.password} *</label>

            <Input
              name="password"
              placeholder={messages.ro.funnel.login.passwordPlacehold}
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />

            {passwordError && <span className="input-container__error">{messages.ro.funnel.common.requiredError}</span>}
          </div>

          <label className="flex items-center gap-2" htmlFor="terms">
            <input id="terms" name="terms" type="checkbox" onChange={handleTermsChange} />
            {messages.ro.funnel.common.agree}
            <a href="/tos" target="_blank">
              {messages.ro.funnel.common.terms}
            </a>
            {messages.ro.funnel.common.platform} *
          </label>

          <label className="flex gap-2 items-center" htmlFor="ads">
            <input id="ads" name="ads" type="checkbox" onChange={ev => setAdsValue(ev.target.checked)} />

            {messages.ro.funnel.common.marketing}
          </label>

          <Button variant="default" type="submit">
            {messages.ro.funnel.common.createAccount}
          </Button>
        </form>

        <div className="flex mt-4">
          {messages.ro.funnel.common.alreadyHaveAccount}

          {"? "}

          <Link to="/register">{messages.ro.funnel.common.auth}</Link>
        </div>
      </div>
    </div>
  );
};

export default RegisterCompanyPage;
