import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { NotFoundRoute, RouterProvider, createRouter } from "@tanstack/react-router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "sonner";
import { Route as rootRoute } from "@/routes/__root";

import "react-big-calendar/lib/css/react-big-calendar.css";

const queryClient = new QueryClient();

// import the generated route tree
import { routeTree } from "./routeTree.gen";

import "./index.css";
import Provider from "./i18n/provider";
// create a new router instance

const notFoundRoute = new NotFoundRoute({
  getParentRoute: () => rootRoute,
  component: () => "404 Not Found",
});
const router = createRouter({ routeTree, notFoundRoute });

// register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

// render the app
const rootElement = document.getElementById("root")!;

if (!rootElement.innerHTML) {
  const root = createRoot(rootElement);

  root.render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <Provider>
          <RouterProvider router={router} basepath="/app/" />
          <Toaster />
        </Provider>
      </QueryClientProvider>
    </StrictMode>
  );
}

router.history.subscribe(() => {
  const url = router.history.location.href;

  if (import.meta.env.VITE_ENABLE_TRACKING === "1") {
    window.gtag("event", "page_view", { page_location: url });
    console.log("navigate to url: ", url);
  }
});
