/* eslint-disable import/no-duplicates */

import { Avatar, AvatarFallback, AvatarImage } from "@/components/avatar";
import { Button } from "@/components/button/Button";
import { Checkbox } from "@/components/checkbox";
import { Label } from "@/components/label";
import { Tabs, TabsList, TabsContent, TabsTrigger } from "@/components/tab";
import { Typography } from "@/components/typography";
import { Route } from "@/routes/_dashboard-layout/needs/$id/candidate/$slug";
import { getAngularToken } from "@/core/auth/utils";
import { useGetAccount } from "@/core/hooks/useGetAccount";
import { useGetProcessStatus, ProcessStatus } from "@/core/hooks/useGetProcessStatus";
import {
  generateAvailableTimeSlots,
  getDurationInMinutes,
  localToUTCTime,
  utcToLocalTime,
} from "@/libs/utils/timeSlots";

import { FiCheckCircle, FiThumbsUp } from "react-icons/fi";
import { RiCloseCircleLine } from "react-icons/ri";
import { LuArrowLeft, LuCircleGauge } from "react-icons/lu";

import MDPhoneInput from "@/components/phoneInput";

import { useEffect, useState } from "react";
import { IoLocationOutline, IoMedicalOutline, IoRibbonOutline } from "react-icons/io5";
import { RadioGroup, RadioGroupItem } from "@/components/radio";
import { useGetProcesses } from "@/core/hooks/company/useGetProcesses";
import { AcceptCandidateProps, useAcceptCandidate } from "@/core/hooks/company/mutations/useAcceptCandidate";
import { toast } from "sonner";
import { useRejectCandidate } from "@/core/hooks/company/mutations/useRejectCandidate";
import { useGetEvents } from "@/core/hooks/company/useGetEvents";
import { useGetCandidateById } from "@/core/hooks/useGetCandidateById";
import { InterviewLocation, useProposeInterviewTime } from "@/core/hooks/mutations/useProposeInterviewTime";
import { useQueryClient } from "@tanstack/react-query";
import { useGetAvailability } from "@/core/hooks/useGetAvailability";
import { differenceInYears, format } from "date-fns";
import { ro } from "date-fns/locale";
import { Select, SelectItem, SelectContent, SelectValue, SelectTrigger } from "@/components/select/select";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/popover";
import { LuCalendar, LuX } from "react-icons/lu";
import { Calendar } from "@/components/calendar";
import { cn } from "@/libs/utils/cn";
import { useAcceptProposedTime } from "@/core/hooks/mutations/useAcceptProposedTime";
import { useRejectProposedTime } from "@/core/hooks/mutations/useRejectProposedTimes";
import { isDateInAvailability } from "@/core/utils";
import Loading from "@/components/loading";
import { Dialog, DialogTitle, DialogContent, DialogHeader } from "@/components/dialog";
import { useCancelEvent } from "@/core/hooks/mutations/useCancelEvent";
import { Textarea } from "@/components/textarea";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/accordion";
import Input from "@/components/input/input";
import { useForm } from "react-hook-form";
import { sendFinalOfferSchema } from "@/core/schemas/send-final-offer";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem, FormLabel } from "@/components/form";
import { useSendFinalOffer } from "@/core/hooks/company/mutations/useSendFinalOffer";
import { useRequestFollowUpInterview } from "@/core/hooks/company/mutations/useRequestFollowUpInterview";
import { useGetEventConfig } from "@/core/hooks/useGetEventConfig";
import { useSaveCandidateInterviewPresence } from "@/core/hooks/company/mutations/useSaveCandidateInterviewPresence";
import { InterviewProposalForm } from "@/components/interview/interview-proposal-form";
import { InterviewTimeSelector } from "@/components/interview/interview-time-selector";
import { useSeeCandidate } from "@/core/hooks/company/mutations/useSeeCandidate";
import { formatInterviewType } from "../offer/candidate";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";

export type FinalOfferFormType = z.infer<typeof sendFinalOfferSchema>;
const CandidatePage = () => {
  const { slug, id } = Route.useParams();
  const { cancel, finalize } = Route.useSearch();
  const navigate = Route.useNavigate();

  // add this helper function
  const determineInterviewType = (
    isFirstPhase: boolean,
    process: ApiOffers,
    rejectedEvt: ApiEvent
  ): "offline" | "virtual" | "phone" => {
    if (isFirstPhase) {
      return process?.interview_type as "offline" | "virtual" | "phone";
    }

    if (rejectedEvt?.metadata?.address) {
      return "offline";
    }
    if (rejectedEvt?.metadata?.phone) {
      return "phone";
    }
    return "virtual";
  };

  const finalOfferForm = useForm<FinalOfferFormType>({
    resolver: zodResolver(sendFinalOfferSchema),
  });

  const queryClient = useQueryClient();
  const [reasonForReject, setReasonForReject] = useState<string[]>([]);
  const [otherReason, setOtherReason] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [contactPerson, setContactPerson] = useState<string>("");
  const [newLocationSet, setNewLocationSet] = useState(false);
  const [showFollowUpProposeForm, setShowFollowUpProposeForm] = useState(false);

  const [selectedTimeId, setSelectedTimeId] = useState<number | undefined>(undefined);
  const [interviewType, setInterviewType] = useState<string>("offline");
  const [interviewDates, setInterviewDates] = useState<Array<{ date?: Date; time: string }>>([
    { date: undefined, time: "" },
    { date: undefined, time: "" },
    { date: undefined, time: "" },
  ]);

  const { mutateAsync: acceptCandidate, isPending: isAcceptingCandidate } = useAcceptCandidate();
  const { mutateAsync: rejectCandidate, isPending: isRejectingCandidate } = useRejectCandidate();

  const { mutateAsync: sendFinalOffer, isPending: isSendingFinalOffer } = useSendFinalOffer();
  const { mutateAsync: requestFollowUpInterview, isPending: isRequestingFollowUpInterview } =
    useRequestFollowUpInterview();

  const { data: currentProcess, isLoading: isLoadingProcess } = useGetProcesses(true, Number(slug), Number(id));
  const { data: candidate, isLoading: isLoadingCandidate } = useGetCandidateById(Number(slug));

  useDocumentTitle(`Candidat ${candidate?.name}`);

  const status = useGetProcessStatus(currentProcess ? currentProcess[0] : undefined);

  const { mutateAsync: seeCandidate } = useSeeCandidate();

  const AngularToken = getAngularToken();
  const { data: account, isLoading: isLoadingAccount } = useGetAccount(AngularToken ?? "");

  const { data: eventConfig, isLoading: isLoadingEventConfig } = useGetEventConfig();

  const { data: proposedEvents, isLoading: isLoadingProposedEvents } = useGetEvents({
    status: "proposed",
    content_type: eventConfig?.[0]?.content_type ?? 68,
    process_id: Number(currentProcess?.[0]?.id),
    shouldFetch: !!currentProcess?.[0]?.id,
  });

  const { data: cancelledEvents, isLoading: isLoadingCancelledEvents } = useGetEvents({
    status: "canceled",
    content_type: eventConfig?.[0]?.content_type,
    process_id: Number(currentProcess?.[0]?.id),
    shouldFetch: !!currentProcess?.[0]?.id,
  });

  const { data: rejectedEvents, isLoading: isLoadingRejectedEvents } = useGetEvents({
    status: "rejected",
    content_type: eventConfig?.[0]?.content_type,
    process_id: Number(currentProcess?.[0]?.id),
    shouldFetch: !!currentProcess?.[0]?.id,
  });

  const { data: acceptedEvents, isLoading: isLoadingAcceptedEvents } = useGetEvents({
    status: "accepted",
    content_type: eventConfig?.[0]?.content_type,
    process_id: Number(currentProcess?.[0]?.id),
    shouldFetch: !!currentProcess?.[0]?.id,
  });

  const { data: availability, isLoading: isLoadingAvailability } = useGetAvailability({
    processId: id,
    shouldFetch: !!currentProcess?.[0]?.id,
  });

  const { data: blockedSlots, isLoading: isLoadingBlockedSlots } = useGetEvents({
    participants_id: account?.user.id ?? 0,
    status: "accepted",
  });

  const { data: candidateBlockedSlots, isLoading: isLoadingCandidateBlockedSlots } = useGetEvents({
    participants_id: candidate?.user_id ?? 0,
    status: "accepted",
  });

  const latestEvent = currentProcess?.[0]?.events.sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  )[0];
  const latestInterview = currentProcess?.[0]?.interviews?.sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  )[0];

  const hasCandidatePositiveFeedback = currentProcess?.[0]?.events.some(event => {
    if (
      event.type !== "CANDIDATE_INTERVIEW_POSITIVE" ||
      (JSON.parse(event.metadata) as ApiProcessEventMetadata).interview_id !== latestInterview?.id
    ) {
      return false;
    }

    return (
      latestEvent?.type === "CANDIDATE_INTERVIEW_POSITIVE" ||
      (latestEvent?.type === "FOLLOWUP_INTERVIEW_REQUESTED" &&
        new Date(latestEvent.updated_at) > new Date(event.updated_at))
    );
  });

  const isFirstInterviewPhase = latestEvent?.type === "COMPANY_ACCEPTED";

  const combinedBlockedSlots = [...(blockedSlots ?? []), ...(candidateBlockedSlots ?? [])];

  const eventProposedByCandidate = proposedEvents?.find(event => event.requester === candidate?.user_id);

  const eventProposedByCompany = proposedEvents?.find(event => event.requester === account?.user.id);

  const acceptedEvent = acceptedEvents?.[0];
  const cancelledEvent = cancelledEvents?.[0];

  const rejectedEvent = rejectedEvents?.[0];

  const eventRejectedByCandidate =
    rejectedEvents?.[0]?.status_updated_by === candidate?.user_id &&
    rejectedEvent?.updated_at &&
    latestEvent?.updated_at &&
    rejectedEvent.updated_at > latestEvent.updated_at;
  const eventRejectedByCompany = rejectedEvents?.[0]?.status_updated_by === account?.user.id;

  const eventCancelledByCandidate = cancelledEvents?.[0]?.status_updated_by === candidate?.user_id;
  // const eventCancelledByCompany = cancelledEvents?.[0]?.status_updated_by === account?.user.id;

  const interviewMeta = isFirstInterviewPhase
    ? (JSON.parse(
        currentProcess?.[0]?.events.find(event => event.type === "COMPANY_ACCEPTED")?.metadata ?? ""
      ) as ApiProcessEventMetadata)
    : rejectedEvents?.[0]?.metadata;

  const { mutateAsync: proposeInterview, isPending: isProposing } = useProposeInterviewTime();

  const showProposeForm =
    !eventProposedByCandidate &&
    !acceptedEvent &&
    !cancelledEvent &&
    (eventRejectedByCompany || eventProposedByCompany || eventRejectedByCandidate);

  const { mutateAsync: acceptProposedTime, isPending: isAcceptingProposedTime } = useAcceptProposedTime();
  const { mutateAsync: rejectProposedTime, isPending: isRejectingProposedTime } = useRejectProposedTime();
  const { mutateAsync: cancelEvent, isPending: isCancellingEvent } = useCancelEvent();
  const { mutateAsync: saveCandidateInterviewPresence, isPending: isSavingCandidateInterviewPresence } =
    useSaveCandidateInterviewPresence();

  const rejectionReasons = [
    { id: "negative_references", value: "Referinte negative" },
    { id: "inefficient_past_collaboration", value: "Am colaborat ineficient in trecut" },
    { id: "insufficient_experience", value: "Experienta insuficienta" },
    { id: "high_salary_requirements", value: "Cerinte salariale prea mari" },
    { id: "schedule_mismatch", value: "Nu corespunde programului de lucru" },
    { id: "other", value: "Altul" },
  ];

  const rejectionReasonsTwo = [
    { id: "negative_references", value: "Referinte negative" },
    { id: "inefficient_past_collaboration", value: "Am colaborat ineficient in trecut" },
    { id: "insufficient_experience", value: "Experienta insuficienta" },
    { id: "high_salary_requirements", value: "Cerinte salariale prea mari" },
    { id: "schedule_mismatch", value: "Prefera alt program de lucru" },
    { id: "other", value: "Altul" },
  ];

  const generateTimeOptions = (selectedDate?: Date) => {
    if (!selectedDate || !availability || !blockedSlots || !eventConfig) {
      return [];
    }

    // convert HH:mm:ss format to minutes

    const durationMinutes = getDurationInMinutes(eventConfig[0]?.duration);
    const bufferMinutes = getDurationInMinutes(eventConfig[0]?.buffer_before);

    const availableSlots = generateAvailableTimeSlots(
      selectedDate,
      availability?.intervals ?? [],
      combinedBlockedSlots,
      durationMinutes,
      bufferMinutes
    );

    return availableSlots.map(slot => ({
      value: `${format(slot.start, "HH:mm")}-${format(slot.end, "HH:mm")}`,
      label: `${format(slot.start, "HH:mm")} - ${format(slot.end, "HH:mm")}`,
    }));
  };

  const handleAcceptProposedTime = async () => {
    if (!selectedTimeId) {
      toast.error("Te rugam sa selectezi o optiune");
      return;
    }

    await acceptProposedTime(
      {
        event_id: eventProposedByCandidate?.id ?? 0,
        proposed_time_id: selectedTimeId ?? 0,
      },
      {
        onSuccess: async () => {
          toast.success("Propunerea a fost acceptata");
          await queryClient.invalidateQueries({ queryKey: ["events", currentProcess?.[0]?.id, "proposed"] });
          await queryClient.invalidateQueries({ queryKey: ["events", currentProcess?.[0]?.id, "accepted"] });
          await queryClient.invalidateQueries({ queryKey: ["events", currentProcess?.[0]?.id, "rejected"] });
          await queryClient.invalidateQueries({ queryKey: ["events", currentProcess?.[0]?.id, "canceled"] });
          await queryClient.invalidateQueries({ queryKey: ["processes", Number(slug), Number(id)] });
        },
        onError: () => {
          toast.error("A apărut o eroare la acceptarea propunerii");
        },
      }
    );
  };

  const handleRejectProposedTime = async () => {
    await rejectProposedTime(
      {
        event_id: acceptedEvent?.id ?? eventProposedByCandidate?.id ?? 0,
      },
      {
        onSuccess: async () => {
          toast.success("Propunerea a fost respinsa");
          await queryClient.invalidateQueries({ queryKey: ["events", currentProcess?.[0]?.id, "proposed"] });
          await queryClient.invalidateQueries({ queryKey: ["events", currentProcess?.[0]?.id, "accepted"] });
          await queryClient.invalidateQueries({ queryKey: ["events", currentProcess?.[0]?.id, "rejected"] });
          await queryClient.invalidateQueries({ queryKey: ["events", currentProcess?.[0]?.id, "canceled"] });
          await queryClient.invalidateQueries({ queryKey: ["processes", Number(slug), Number(id)] });
        },
        onError: () => {
          toast.error("A apărut o eroare la refuzarea propunerii");
        },
      }
    );
  };

  const handleAcceptCandidate = async () => {
    let payload: AcceptCandidateProps = {
      process_id: currentProcess?.[0]?.id?.toString() ?? "",
      interview_type: interviewType,
    };

    if (interviewType === "phone") {
      payload = { ...payload, contact_person: contactPerson, phone };
    } else if (interviewType === "offline") {
      payload = { ...payload, address, contact_person: contactPerson };
    }

    await acceptCandidate(payload, {
      onSuccess: async () => {
        toast.success("Candidatul a fost acceptat");
        await queryClient.invalidateQueries({ queryKey: ["processes"] });
      },
      onError: () => {
        toast.error("Eroare la acceptarea candidatului");
      },
    });
  };

  const handleRejectCandidate = async () => {
    await rejectCandidate(
      {
        process_id: currentProcess?.[0]?.id?.toString() ?? "",
        reasons: reasonForReject.join(", "),
      },
      {
        onSuccess: async () => {
          toast.success("Candidatul a fost respins");
          await queryClient.invalidateQueries({ queryKey: ["processes"] });
        },
      }
    );
  };

  const handleCancelEvent = async () => {
    await cancelEvent(
      {
        event_id: acceptedEvent?.id ?? 0,
        reason: otherReason ? otherReason : reasonForReject.join(","),
      },
      {
        onSuccess: async () => {
          toast.success("Interviu a fost anulat");
          await queryClient.invalidateQueries({ queryKey: ["events", currentProcess?.[0]?.id, "proposed"] });
          await queryClient.invalidateQueries({ queryKey: ["events", currentProcess?.[0]?.id, "accepted"] });
          await queryClient.invalidateQueries({ queryKey: ["events", currentProcess?.[0]?.id, "rejected"] });
          await queryClient.invalidateQueries({ queryKey: ["events", currentProcess?.[0]?.id, "canceled"] });
          await queryClient.invalidateQueries({ queryKey: ["processes", Number(slug), Number(id)] });
          await navigate({ search: {} });
        },
      }
    );
  };

  const handleProposeTime = async () => {
    const proposedTimes = interviewDates
      .filter(slot => slot.date && slot.time)
      .map(slot => {
        const [startTime] = slot.time.split("-");
        const durationMinutes = getDurationInMinutes(eventConfig?.[0]?.duration ?? "30");
        const startDate = localToUTCTime(slot.date!, startTime);
        const endDate = new Date(startDate.getTime() + durationMinutes * 60 * 1000);

        return {
          start_time: startDate.toISOString(),
          end_time: endDate.toISOString(),
          status: "proposed" as const,
        };
      });

    await proposeInterview(
      {
        title: `Interview with ${candidate?.name}`,
        description: "",
        event_type: 1,
        participants: [candidate?.user_id ?? 0, account?.user.id ?? 0],
        status: "proposed",
        requester: account?.user.id ?? 0,
        content_type: eventConfig?.[0]?.content_type ?? 68,
        object_id: Number(currentProcess?.[0]?.id),
        related_object: null,
        location: newLocationSet ? "offline" : (currentProcess?.[0]?.interview_type as InterviewLocation),
        location_details: "",
        proposed_times: proposedTimes,
        metadata: newLocationSet
          ? {
              address,
              contact_person: contactPerson,
              phone,
              interview_type: interviewType,
            }
          : interviewMeta,
      },
      {
        onSuccess: async () => {
          toast.success("Propunerile au fost trimise cu succes!");
          await queryClient.invalidateQueries({ queryKey: ["events", currentProcess?.[0]?.id, "proposed"] });
          await queryClient.invalidateQueries({ queryKey: ["events", currentProcess?.[0]?.id, "accepted"] });
          await queryClient.invalidateQueries({ queryKey: ["events", currentProcess?.[0]?.id, "rejected"] });
          await queryClient.invalidateQueries({ queryKey: ["events", currentProcess?.[0]?.id, "canceled"] });
          await queryClient.invalidateQueries({ queryKey: ["processes", Number(slug), Number(id)] });
        },
        onError: error => {
          toast.error(error.message);
        },
      }
    );
  };

  const handleSendFinalOffer = async (data: FinalOfferFormType) => {
    await sendFinalOffer(
      {
        process_id: currentProcess?.[0]?.id?.toString() ?? "",
        value: data,
      },
      {
        onSuccess: async () => {
          toast.success("Oferta finala a fost trimisa cu succes!");
          await queryClient.invalidateQueries({ queryKey: ["processes"] });
          await navigate({ search: {} });
        },
        onError: () => {
          toast.error("A apărut o eroare la trimiterea ofertei finale");
        },
      }
    );
  };

  const handleRequestFollowUpInterview = async () => {
    await requestFollowUpInterview(
      { process_id: currentProcess?.[0]?.id?.toString() ?? "" },
      {
        onSuccess: async () => {
          toast.success("Interviu a fost solicitat");
          await queryClient.invalidateQueries({ queryKey: ["events", currentProcess?.[0]?.id, "proposed"] });
          await queryClient.invalidateQueries({ queryKey: ["events", currentProcess?.[0]?.id, "accepted"] });
          await queryClient.invalidateQueries({ queryKey: ["events", currentProcess?.[0]?.id, "rejected"] });
          await queryClient.invalidateQueries({ queryKey: ["events", currentProcess?.[0]?.id, "canceled"] });
          await queryClient.invalidateQueries({ queryKey: ["processes", Number(slug), Number(id)] });
        },
      }
    );
  };

  const handleSaveCandidateInterviewPresence = async (isPresent: boolean) => {
    await saveCandidateInterviewPresence(
      {
        interviewId: latestInterview?.id.toString() ?? "",
        isCandidatePresent: isPresent,
      },
      {
        onSuccess: async () => {
          toast.success("Prezența a fost salvată cu succes!");
          await queryClient.invalidateQueries({ queryKey: ["processes", Number(slug), Number(id)] });
        },
      }
    );
  };

  useEffect(() => {
    if (status === ProcessStatus.InterviewAcceptedByMediJobs) {
      void seeCandidate({ processId: currentProcess?.[0]?.id?.toString() ?? "" }, {});
    }
  }, [status]);

  const isAllDatesAndTimesSet = interviewDates.every(slot => slot.date && slot.time);

  if (
    isLoadingProcess ||
    isLoadingCandidate ||
    isLoadingAccount ||
    isLoadingProposedEvents ||
    isLoadingRejectedEvents ||
    isLoadingAvailability ||
    isLoadingBlockedSlots ||
    isLoadingCandidateBlockedSlots ||
    isLoadingAcceptedEvents ||
    isLoadingCancelledEvents ||
    isLoadingEventConfig
  ) {
    return <Loading />;
  }

  const showFeedbackForm =
    !proposedEvents?.length &&
    (status === ProcessStatus.CompanyInterviewPresent ||
      status === ProcessStatus.CandidateInterviewPresent ||
      status === ProcessStatus.CompanyInterviewMissed ||
      status === ProcessStatus.CandidateInterviewMissed ||
      status === ProcessStatus.CandidatePositiveFeedback);

  const hasFeedback =
    status === ProcessStatus.CompanyInterviewMissed ||
    status === ProcessStatus.CandidateInterviewMissed ||
    status === ProcessStatus.CompanyInterviewPresent ||
    status === ProcessStatus.CandidateInterviewPresent;

  const oneIsNotPresent =
    status === ProcessStatus.CompanyInterviewMissed || status === ProcessStatus.CandidateInterviewMissed;

  const bothArePresent =
    status === ProcessStatus.CompanyInterviewPresent || status === ProcessStatus.CandidateInterviewPresent;

  const isArchived = currentProcess?.[0]?.need.archived;

  function getDomainExperienceTags(domainExperiences: ApiDomainExperience[], experienceId: number): string {
    const experience = domainExperiences.find(exp => exp.id === experienceId);

    if (!experience) {
      return "";
    }

    return experience.candidate_tag_associations
      .map(ta => ta.tag_association.tag.value)
      .filter((val, ind, self) => self.indexOf(val) === ind)
      .join(", ");
  }

  function hasDomainExperienceTags(domainExperiences: ApiDomainExperience[]): boolean {
    return domainExperiences.some(exp => exp.candidate_tag_associations.length > 0);
  }

  console.log(rejectedEvent);

  return (
    <div className="flex flex-col md:flex-row gap-4 w-full">
      <div className="md:max-w-[65%]  flex-1 order-2 md:order-none">
        {/* Profile Header */}
        <div className="flex flex-col gap-6 items-start bg-white px-6 py-4 rounded-lg">
          <div className="flex justify-between items-center gap-4 w-full">
            <Typography variant="h2">PROFILE DE BAZA</Typography>
            {candidate?.cv ? (
              <Button variant="secondary" size="sm" asChild>
                <a
                  href={`${import.meta.env.VITE_AWS_S3_BUCKET}/${candidate.cv}`}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Descarca CV
                </a>
              </Button>
            ) : null}
          </div>
          <div className="w-full">
            <div className="text-center">
              <h1 className="text-2xl font-semibold">{candidate?.name}</h1>
              <p className="text-gray-800 font-medium">{candidate?.domain_experiences[0]?.category.value}</p>
              {candidate?.birthday ? (
                <p className="text-gray-600">
                  {differenceInYears(new Date(), new Date(candidate?.birthday as string))} ani
                </p>
              ) : null}
              <p className="text-gray-600 text-sm mt-1">
                <span className="text-gray-400">In acest moment:</span> Particip la interviuri
              </p>
              {candidate?.desired_salary && (
                <p className="text-gray-800 mt-1">
                  <span className="text-gray-400">Prefer joburi full time:</span> la salariu de{" "}
                  {candidate?.desired_salary} RON.
                </p>
              )}
              {candidate?.desired_salary_part && (
                <p className="text-gray-800 mt-1">
                  <span className="text-gray-400">Prefer joburi part time:</span> la salariu de{" "}
                  {candidate?.desired_salary_part} RON.
                </p>
              )}
            </div>
            <Avatar className={cn("size-24 ml-10", candidate?.user.avatar ? "bg-transparent" : "")}>
              <AvatarImage src={`${import.meta.env.VITE_AWS_S3_BUCKET}/${candidate?.user.avatar}`} />
              <AvatarFallback>
                <Typography>{candidate?.name.charAt(0)}</Typography>
              </AvatarFallback>
            </Avatar>
          </div>
        </div>

        {/* About Section */}
        <section className="space-y-4 bg-white px-6 py-4 rounded-lg">
          <h2 className="text-xl font-semibold text-gray-800">DESPRE MINE</h2>
          <p>{candidate?.about}</p>
          <div className="space-y-3">
            <div className="flex items-start gap-2">
              <IoLocationOutline className="w-5 h-5 text-gray-500 mt-1" />
              <div>
                <p className="text-gray-400 text-sm">Orase in care as vrea sa profesez</p>
                <Typography className="font-semibold">
                  {candidate?.cities.map(c => `${c.name} (${c.county})`).join(", ")}
                </Typography>
              </div>
            </div>
            <div className="flex items-start gap-2">
              <IoLocationOutline className="w-5 h-5 text-gray-500 mt-1" />
              <div>
                <p className="text-gray-400 text-sm">Program preferat</p>
                <Typography className="font-semibold">
                  {candidate?.schedules.map(schedule => schedule.value).join(", ")}
                </Typography>
              </div>
            </div>
          </div>
        </section>

        {/* Specialties Section */}
        <section className="space-y-4 bg-white px-6 py-4 rounded-lg">
          <h2 className="text-xl font-semibold text-gray-800">SPECIALITATE SI COMPETENTE MEDICALE</h2>
          <div className="space-y-6">
            {candidate?.domain_experiences.map((specialty, index) => (
              <div key={index} className="space-y-2 flex border-b border-gray-100 pb-4">
                <div className="flex items-start gap-2 flex-1">
                  <IoMedicalOutline className="w-5 h-5 text-gray-500 mt-1" />
                  <div>
                    <p className="text-gray-600 text-sm">Specialitate</p>
                    <p>
                      {specialty.category.value} {specialty.subcategory && `, ${specialty.subcategory.value}`}
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-2 flex-1">
                  <IoRibbonOutline className="w-5 h-5 text-gray-500 mt-1" />
                  <div className="">
                    <p className="text-gray-600 text-sm">Experienta</p>
                    <Typography className="font-semibold">{specialty.years} ANI</Typography>
                  </div>
                </div>

                {getDomainExperienceTags(candidate?.domain_experiences ?? [], specialty.id) ? (
                  <div className="flex items-start gap-2 flex-1">
                    <IoRibbonOutline className="w-5 h-5 text-gray-500 mt-1 shrink-0" />
                    <div className="">
                      <p className="text-gray-600 text-sm">Competente</p>
                      <Typography className="font-semibold">
                        {getDomainExperienceTags(candidate?.domain_experiences ?? [], specialty.id)}
                      </Typography>
                    </div>
                  </div>
                ) : hasDomainExperienceTags(candidate?.domain_experiences ?? []) ? (
                  <div className="flex items-start gap-2 flex-1" />
                ) : null}
              </div>
            ))}
          </div>
        </section>

        {/* Experience Section */}
        <section className="space-y-4 bg-white px-6 py-4 rounded-lg">
          <h2 className="text-xl font-semibold text-gray-800">EXPERIENTA PROFESIONALA</h2>
          {candidate?.experience.map((experience, index) => (
            <div key={index}>
              <p className="font-medium">{experience.company_name}</p>
              <p className="text-gray-600">{experience.category}</p>
              <p className="text-gray-600 text-sm">
                {experience.start_date ? format(new Date(experience.start_date), "MM/yyyy") : "N/A"} -{" "}
                {experience.end_date ? format(new Date(experience.end_date), "MM/yyyy") : "Prezent"}
              </p>
            </div>
          ))}
        </section>

        {/* Education Section */}
        <section className="space-y-4 bg-white px-6 py-4 rounded-lg">
          <h2 className="text-xl font-semibold text-gray-800">EDUCATIE</h2>
          <div className="flex items-start gap-2">
            <div>
              {candidate?.education.map((education, index) => (
                <div key={index}>
                  <p className="font-medium">{education.institution_name}</p>
                  <p className="text-gray-600">{education.location}</p>
                  <p className="text-gray-600 text-sm">Anul absolvirii: {education.graduation_year}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>

      {isArchived ? (
        <div className="bg-white p-6 rounded-lg h-max w-[28%]">
          <Typography className="text-gray-600 text-lg font-light">Procesul a fost arhivat.</Typography>
          <Typography className="text-gray-600 text-lg font-light">
            Ultima stare inregistrata: <b>{currentProcess?.[0]?.status}</b>
          </Typography>
          <br />
          <Typography className="text-gray-600 text-lg font-light">
            Pentru Intrebari In legatura cu acest proces sau dezarhivarea lui va rugam sa contactati specialistul
            customer care MEDIjobs.
          </Typography>
        </div>
      ) : null}

      {!isArchived ? (
        <>
          {status === ProcessStatus.InterviewAcceptedByMediJobs && (
            <div className="bg-white p-6 rounded-lg h-max md:w-[28%]">
              <Tabs defaultValue="accept" className=" w-full">
                <TabsList className=" w-full flex">
                  <TabsTrigger className="flex-1" value="accept">
                    Accepta candidat
                  </TabsTrigger>
                  <TabsTrigger className="flex-1" value="reject">
                    Refuza candidat
                  </TabsTrigger>
                </TabsList>
                <TabsContent value="accept">
                  <div className="flex flex-col gap-4">
                    <Typography className="text-gray-600 text-lg font-light">
                      Daca profilul acestui candidat este potrivit pentru postul disponibil, bifeaza tipul de interviu
                      dorit si trimite-i invitatia.
                    </Typography>

                    <RadioGroup className="w-full" value={interviewType} onValueChange={setInterviewType}>
                      <div className="flex flex-col gap-4 w-full">
                        <div className="w-full">
                          <div className="flex items-center gap-2">
                            <RadioGroupItem value="offline" id="office" />
                            <Label className="text-base" htmlFor="office">
                              Interviu la sediu
                            </Label>
                          </div>
                          {interviewType === "offline" && (
                            <div className="pl-5 space-y-2 mt-2">
                              <div className="space-y-2">
                                <Label className="text-gray-600 text-base">Adresa *</Label>
                                <Input
                                  value={address}
                                  onChange={e => setAddress(e.target.value)}
                                  placeholder="Str. Nume, nr. 3, Bucuresti"
                                />
                              </div>
                              <div className="space-y-2">
                                <Label className="text-gray-600 text-base">Persoana de contact *</Label>
                                <Input
                                  value={contactPerson}
                                  onChange={e => setContactPerson(e.target.value)}
                                  placeholder="Nume si prenume"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div>
                          <div className="flex items-center gap-2">
                            <RadioGroupItem value="phone" id="phone" />
                            <Label className="text-base" htmlFor="phone">
                              Interviu telefonic
                            </Label>
                          </div>
                          {interviewType === "phone" && (
                            <div className="pl-5 space-y-2 mt-2">
                              <div className="space-y-2">
                                <Label className="text-gray-600 text-base">Numar de telefon *</Label>
                                <MDPhoneInput value={phone} onChange={e => setPhone(e)} />
                              </div>
                              <div className="space-y-2">
                                <Label className="text-gray-600 text-base">Persoana de contact *</Label>
                                <Input
                                  value={contactPerson}
                                  onChange={e => setContactPerson(e.target.value)}
                                  placeholder="Nume si prenume"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </RadioGroup>

                    <Button
                      disabled={
                        !interviewType ||
                        (interviewType === "offline" && !address) ||
                        (interviewType === "offline" && !contactPerson) ||
                        (interviewType === "phone" && !phone)
                      }
                      onClick={handleAcceptCandidate}
                      loading={isAcceptingCandidate}
                      className="mt-4"
                    >
                      Trimite invitatia la interviu
                    </Button>
                  </div>
                </TabsContent>
                <TabsContent value="reject">
                  <div className="flex flex-col gap-4">
                    <Typography className="text-gray-600 text-lg font-light">
                      Inainte de a refuza acest candidat, te rugam sa ne oferi motivul refuzului. Feedback-ul tau ne va
                      ajuta sa iti facem recomandari mai relevante pentru posturile tale viitoare.
                    </Typography>

                    <div className="flex flex-col gap-6">
                      {rejectionReasons.map(reason => (
                        <div className="flex items-center gap-2" key={reason.id}>
                          <Checkbox
                            checked={reasonForReject.includes(reason.id)}
                            onCheckedChange={value =>
                              setReasonForReject(
                                value ? [...reasonForReject, reason.id] : reasonForReject.filter(id => id !== reason.id)
                              )
                            }
                          />
                          <Label className="text-gray-600 text-lg font-light">{reason.value}</Label>
                        </div>
                      ))}
                    </div>
                    {reasonForReject.find(id => id === "other") && (
                      <Textarea
                        placeholder="Motivul anularii"
                        value={otherReason}
                        onChange={e => setOtherReason(e.target.value)}
                      />
                    )}
                    <Button
                      onClick={handleRejectCandidate}
                      loading={isRejectingCandidate}
                      disabled={reasonForReject.length === 0}
                      variant="destructive"
                    >
                      Refuza
                    </Button>
                  </div>
                </TabsContent>
              </Tabs>
            </div>
          )}

          {status === ProcessStatus.InterviewAcceptedByCompany && (
            <>
              {!eventProposedByCandidate && !eventProposedByCompany && !acceptedEvent && !rejectedEvent && (
                <div className="bg-white p-6 rounded-lg h-max md:w-[28%]">
                  <Typography className="text-gray-600 text-lg font-light">
                    Invitatia la interviu a fost trimisa cu succes. In functie de disponibilitatea indicata de tine,
                    candidatul va trimite 3 optiuni de zi si ora pentru interviu.
                  </Typography>
                </div>
              )}
              {eventProposedByCandidate && (
                <InterviewTimeSelector
                  proposedTimes={eventProposedByCandidate?.proposed_times ?? []}
                  selectedTimeId={selectedTimeId}
                  onTimeSelect={setSelectedTimeId}
                  onAccept={handleAcceptProposedTime}
                  onReject={handleRejectProposedTime}
                  isLoading={isAcceptingProposedTime || isRejectingProposedTime}
                  entityName={currentProcess[0].candidate.name}
                  interviewType={determineInterviewType(isFirstInterviewPhase, currentProcess?.[0], rejectedEvent!)}
                  isCompany
                  title="Programeaza interviul"
                />
              )}
              {showProposeForm ? (
                <InterviewProposalForm
                  entityName={currentProcess[0].need.company.name}
                  interviewType={determineInterviewType(isFirstInterviewPhase, currentProcess?.[0], rejectedEvent!)}
                  availability={availability}
                  interviewDates={interviewDates}
                  setInterviewDates={setInterviewDates}
                  generateTimeOptions={generateTimeOptions}
                  onPropose={handleProposeTime}
                  onReject={async () => navigate({ search: { cancel: "true" } })}
                  isProposing={isProposing}
                  eventProposedByCompany={!!eventProposedByCompany}
                  eventRejectedByCandidate={!!eventRejectedByCandidate}
                  rejectedEvent={rejectedEvent}
                  isCompany
                />
              ) : null}
            </>
          )}

          {status === ProcessStatus.InterviewScheduled && latestInterview && (
            <div className="flex-1 bg-white p-6 rounded-lg shadow h-max md:max-w-96 space-y-2">
              <div className="flex flex-col  gap-4">
                <Typography className="md:text-lg font-bold">Mentiunea expertului MEDIjobs</Typography>
                <Typography className="text-gray-600 md:text-lg">
                  Este o persoană dedicată, cu o atitudine proactivă și o capacitate remarcabilă de a colabora eficient
                  în echipă.
                </Typography>

                <Typography className="text-gray-800 md:text-lg font-semibold">Interviul a fost programat</Typography>
                <div className="flex bg-medi-blue/5 p-2 rounded-lg gap-2">
                  <div className="flex flex-col flex-1">
                    <div className="flex items-center justify-between">
                      <Typography className="text-gray-600 text-sm">
                        <b>{format(new Date(latestInterview?.scheduled_date_time), "dd MMM, yyyy")}</b>
                      </Typography>
                      <Typography className="text-gray-600 text-sm">
                        <b>{format(utcToLocalTime(latestInterview?.scheduled_date_time ?? new Date()), "HH:mm")}</b>
                      </Typography>
                    </div>
                    <div className="flex items-center justify-between font-light">
                      <Typography className="text-gray-500 text-sm capitalize">
                        <b>
                          {format(new Date(latestInterview?.scheduled_date_time ?? new Date()), "EEEE", { locale: ro })}
                        </b>
                      </Typography>
                      <Typography className="text-gray-500 text-sm">
                        <b>
                          {format(
                            new Date(
                              new Date(latestInterview.scheduled_date_time).getTime() +
                                getDurationInMinutes(eventConfig?.[0]?.duration ?? "30") * 60 * 1000
                            ),
                            "HH:mm"
                          )}
                        </b>
                      </Typography>
                    </div>
                  </div>
                  <div className="flex flex-col flex-1 pl-2 border-l-2 border-medi-blue">
                    <Button
                      onClick={handleRejectProposedTime}
                      loading={isRejectingProposedTime}
                      variant="outline"
                      className="w-full bg-white border-medi-blue text-medi-blue h-10"
                    >
                      Reprogrameaza
                    </Button>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <Typography className="text-gray-600">
                    Daca nu poti participa la interviu, te rugam sa anulezi acest interviu.
                  </Typography>
                  <Button onClick={async () => navigate({ search: { cancel: true } })} variant="destructive">
                    Anuleaza interviu
                    <LuX className="size-4" />
                  </Button>
                </div>
              </div>
            </div>
          )}

          {status === ProcessStatus.InterviewRescheduled && (
            <>
              {!eventProposedByCandidate ? (
                <InterviewProposalForm
                  entityName={currentProcess[0].need.company.name}
                  interviewType={determineInterviewType(isFirstInterviewPhase, currentProcess?.[0], rejectedEvent!)}
                  availability={availability}
                  interviewDates={interviewDates}
                  setInterviewDates={setInterviewDates}
                  generateTimeOptions={generateTimeOptions}
                  onPropose={handleProposeTime}
                  onReject={async () => navigate({ search: { cancel: "true" } })}
                  isProposing={isProposing}
                  eventProposedByCompany={!!eventProposedByCompany}
                  eventRejectedByCandidate={!!eventRejectedByCandidate}
                  rejectedEvent={rejectedEvent}
                  isCompany
                />
              ) : (
                <div className="bg-white p-6 rounded-lg h-max md:w-max">
                  <div className="flex-1 bg-white p-6 rounded-lg shadow h-max md:max-w-[400px]">
                    <div className="flex flex-col gap-4">
                      <Typography className="text-gray-600 md:text-lg">
                        Candidatul {candidate?.name} a propus următoarele intervale pentru interviu. Te rugăm să
                        selectezi una dintre aceste opțiuni pentru programarea interviului.
                      </Typography>

                      <div className="flex items-center gap-2">
                        <Typography className="text-gray-500 md:text-lg ">Tipul interviului:</Typography>
                        <Typography className="text-gray-800 md:text-lg font-semibold capitalize">
                          {formatInterviewType(
                            determineInterviewType(isFirstInterviewPhase, currentProcess?.[0], rejectedEvent!)
                          )}
                        </Typography>
                      </div>
                      <Typography className="text-gray-800 md:text-lg font-semibold">Programeaza interviu</Typography>
                      <Typography className="text-gray-500 md:text-base">
                        Mai jos sunt optiunile propuse de {candidate?.name}. Te rugam sa alegi una dintre optiuni sa
                        propui o alta zi si ora mai convenabila:
                      </Typography>
                      <div className="relative">
                        <Typography className="font-semibold">Alege una dintre optiuni:</Typography>

                        <div className="flex flex-col gap-2">
                          {eventProposedByCandidate?.proposed_times.map((slot, index) => (
                            <button
                              onClick={() => setSelectedTimeId(slot.id)}
                              key={index}
                              className={cn(
                                "border rounded-md px-3 py-2 w-full",
                                selectedTimeId === slot.id && "border-medi-blue bg-medi-blue/10"
                              )}
                            >
                              <div className="flex items-center gap-3 justify-between">
                                <span className="">{format(new Date(slot.start_time), "dd MMM. yyyy")}</span>
                                <span>{format(new Date(slot.start_time), "HH:mm")}</span>
                              </div>
                              <div className="flex items-center justify-between text-gray-400">
                                <span className="">{format(new Date(slot.end_time), "EEEE", { locale: ro })}</span>
                                <span>{format(new Date(slot.end_time), "HH:mm")}</span>
                              </div>
                            </button>
                          ))}
                        </div>

                        <div className="flex gap-2 mt-4">
                          <Button
                            variant="default"
                            className="flex-1"
                            loading={isAcceptingProposedTime}
                            disabled={!selectedTimeId || isAcceptingProposedTime}
                            onClick={handleAcceptProposedTime}
                          >
                            Programeaza interviu
                          </Button>
                          <Button
                            variant="outline"
                            className="flex-1"
                            onClick={handleRejectProposedTime}
                            loading={isRejectingProposedTime}
                          >
                            Seteaza alte date
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {status === ProcessStatus.InterviewRejectedByCompany && (
            <div className="bg-white p-6 rounded-lg h-max md:w-2/6">
              <Typography>
                Ai anulat reprogramarea interviului. Candidatul a primit o notificare automata in acest sens. Daca
                doresti sa reiei programarea unui interviu cu acest candidat, contacteaza reprezentantul Medijobs.
              </Typography>
            </div>
          )}

          {status === ProcessStatus.InterviewPassed ? (
            <div className="bg-white p-6 rounded-lg h-max md:w-2/6 space-y-4 md:max-w-96">
              <Typography className="text-gray-800 text-lg font-semibold">
                A fost prezent candidatul {candidate?.name} la interviu?
              </Typography>

              <div className="flex gap-2">
                <Button
                  variant="default"
                  className="flex-1"
                  onClick={async () => {
                    await handleSaveCandidateInterviewPresence(true);
                  }}
                  disabled={isSavingCandidateInterviewPresence}
                >
                  Da, a fost prezent
                </Button>
                <Button
                  variant="destructive"
                  className="flex-1"
                  onClick={async () => {
                    await handleSaveCandidateInterviewPresence(false);
                  }}
                  disabled={isSavingCandidateInterviewPresence}
                >
                  Nu a fost prezent
                </Button>
              </div>
            </div>
          ) : null}

          {showFeedbackForm ? (
            <div className="bg-white p-6 rounded-lg h-max md:w-2/6 space-y-4 md:max-w-96">
              <div className="space-y-2">
                <Typography className="text-gray-800 text-lg font-semibold">Mentiunea expertului MEDIjobs</Typography>
                <Typography className="text-gray-600">
                  Este o persoană dedicată, cu o atitudine proactivă și o capacitate remarcabilă de a colabora eficient
                  în echipă.
                </Typography>
              </div>
              <div className="space-y-2">
                <Typography className="text-gray-800 text-lg font-semibold">Cum a decurs interviul?</Typography>
                <Typography className="text-gray-700">
                  Ai discutat cu <b>{candidate?.name}</b>. Ce decizie ai luat? Raspunsul tau ne ajuta sa-ti propunem
                  candidati cat mai relevanti.
                </Typography>
              </div>

              {hasCandidatePositiveFeedback ? (
                <div>
                  <Typography className="text-gray-800 text-lg font-semibold">
                    Candidatul a dat feedback pozitiv. Te rugam sa alegi una dintre optiuni pentru a continua:{" "}
                    <FiThumbsUp className="size-5 text-green-500 inline" />
                  </Typography>
                </div>
              ) : null}

              <Accordion type="single" collapsible className="w-full space-y-2" defaultValue="continue">
                {bothArePresent || hasCandidatePositiveFeedback ? (
                  <AccordionItem value="continue" className="border rounded-md data-[state=open]:bg-medi-blue/10">
                    <AccordionTrigger className="w-full hover:no-underline justify-start gap-2" showChevron={false}>
                      <Label className="py-2 cursor-pointer flex gap-2">
                        <FiCheckCircle className="size-4 text-green-500" /> Vreau sa continui procesul de angajare
                      </Label>
                    </AccordionTrigger>

                    <AccordionContent className="px-4 pb-4">
                      <div className="space-y-2">
                        <Button
                          variant="default"
                          size="sm"
                          className="w-full"
                          onClick={async () => navigate({ search: { finalize: true } })}
                        >
                          Trimite oferta finala
                        </Button>
                        <Button
                          variant="outline"
                          size="sm"
                          className="w-full"
                          onClick={handleRequestFollowUpInterview}
                          loading={isRequestingFollowUpInterview}
                        >
                          Invita la un interviu nou
                        </Button>
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                ) : null}

                <AccordionItem value="discontinue" className="border rounded-md data-[state=open]:bg-medi-blue/10">
                  <AccordionTrigger className="w-full hover:no-underline justify-start gap-2" showChevron={false}>
                    <Label className="py-2 cursor-pointer flex gap-2">
                      <RiCloseCircleLine className="size-4 text-red-500" /> Nu voi angaja acest candidat
                    </Label>
                  </AccordionTrigger>

                  <AccordionContent className="px-4 pb-4">
                    <div className="space-y-2">
                      <Select onValueChange={value => setReasonForReject([value])} value={reasonForReject[0]}>
                        <SelectTrigger>
                          <SelectValue placeholder="Selecteaza un motiv" />
                        </SelectTrigger>
                        <SelectContent>
                          {rejectionReasonsTwo.map(reason => (
                            <SelectItem key={reason.id} value={reason.id}>
                              {reason.value}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>

                      {reasonForReject.find(id => id === "other") && (
                        <Textarea
                          placeholder="Motivul anularii"
                          value={otherReason}
                          onChange={e => setOtherReason(e.target.value)}
                        />
                      )}

                      <Button
                        onClick={handleCancelEvent}
                        disabled={!reasonForReject.length}
                        variant="destructive"
                        size="sm"
                        className="w-full"
                        loading={isCancellingEvent}
                      >
                        Refuza candidatul
                      </Button>
                    </div>
                  </AccordionContent>
                </AccordionItem>

                {oneIsNotPresent ? (
                  <AccordionItem value="reschedule" className="border rounded-md data-[state=open]:bg-medi-blue/10">
                    <AccordionTrigger className="w-full hover:no-underline justify-start gap-2" showChevron={false}>
                      <Label className="py-2 cursor-pointer flex gap-2">
                        <LuCircleGauge className="size-4 text-medi-blue" /> Vreau sa programez alt interviu
                      </Label>
                    </AccordionTrigger>

                    <AccordionContent className="px-4 pb-4">
                      <div className="space-y-2">
                        <Button
                          onClick={handleRejectProposedTime}
                          loading={isRejectingProposedTime}
                          variant="outline"
                          className="w-full bg-white border-medi-blue text-medi-blue h-10"
                        >
                          Reprogrameaza
                        </Button>
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                ) : null}
              </Accordion>
            </div>
          ) : null}

          {status === ProcessStatus.FollowUpInterviewRequested && (
            <div className="flex-1 bg-white p-6 rounded-lg shadow h-max md:max-w-[400px]">
              <div className="flex flex-col gap-4">
                <div>
                  <Typography className="text-gray-800 text-lg font-semibold">Mentiunea expertului MEDIjobs</Typography>
                  <Typography className="text-gray-600">
                    Este o persoană dedicată, cu o atitudine proactivă și o capacitate remarcabilă de a colabora
                    eficient în echipă.
                  </Typography>
                </div>

                <Typography className="text-gray-800 text-lg font-semibold">Programeaza interviu urmator</Typography>

                {!showFollowUpProposeForm && !proposedEvents?.length ? (
                  <div className="flex flex-col gap-4">
                    <Typography className="text-gray-600 text-lg font-light">
                      Te rugam sa selectezi locatia interviului si apoi apasa &quot;Accepta&quot; pentru a trimite
                      cererea de interviu catre candidat.
                    </Typography>

                    <RadioGroup className="w-full" value={interviewType} onValueChange={setInterviewType}>
                      <div className="flex flex-col gap-4 w-full">
                        <div className="w-full">
                          <div className="flex items-center gap-2">
                            <RadioGroupItem value="offline" id="office" />
                            <Label className="text-base" htmlFor="office">
                              Interviu la sediu
                            </Label>
                          </div>
                          {interviewType === "offline" && (
                            <div className="pl-5 space-y-2 mt-2">
                              <div className="space-y-2">
                                <Label className="text-gray-600 text-base">Adresa *</Label>
                                <Input
                                  value={address}
                                  onChange={e => setAddress(e.target.value)}
                                  placeholder="Str. Nume, nr. 3, Bucuresti"
                                />
                              </div>
                              <div className="space-y-2">
                                <Label className="text-gray-600 text-base">Persoana de contact *</Label>
                                <Input
                                  value={contactPerson}
                                  onChange={e => setContactPerson(e.target.value)}
                                  placeholder="Nume si prenume"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div>
                          <div className="flex items-center gap-2">
                            <RadioGroupItem value="phone" id="phone" />
                            <Label className="text-base" htmlFor="phone">
                              Interviu telefonic
                            </Label>
                          </div>
                          {interviewType === "phone" && (
                            <div className="pl-5 space-y-2 mt-2">
                              <div className="space-y-2">
                                <Label className="text-gray-600 text-base">Numar de telefon *</Label>
                                <MDPhoneInput value={phone} onChange={e => setPhone(e)} />
                              </div>
                              <div className="space-y-2">
                                <Label className="text-gray-600 text-base">Persoana de contact *</Label>
                                <Input
                                  value={contactPerson}
                                  onChange={e => setContactPerson(e.target.value)}
                                  placeholder="Nume si prenume"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </RadioGroup>

                    <Button
                      disabled={
                        !interviewType ||
                        (interviewType === "offline" && !address) ||
                        (interviewType === "offline" && !contactPerson) ||
                        (interviewType === "phone" && !phone)
                      }
                      variant="default"
                      className="w-full"
                      onClick={() => {
                        setNewLocationSet(true);

                        setShowFollowUpProposeForm(true);
                      }}
                    >
                      Continua
                    </Button>
                  </div>
                ) : (
                  <div className="relative">
                    <Typography className="font-semibold">Propune 3 optiuni pentru candidat</Typography>

                    {interviewDates.map((slot, index) => (
                      <div key={index} className="flex flex-col gap-2 mt-2">
                        <div className="border rounded-lg p-4">
                          <div className="flex gap-2">
                            <div className="flex-1">
                              <Typography className="text-gray-600 font-semibold">Data interviu</Typography>
                              <Popover>
                                <PopoverTrigger className="w-full shadow-md">
                                  <Button
                                    variant="outline"
                                    className={cn("w-full justify-start text-gray-400", slot.date && "text-gray-800")}
                                  >
                                    <LuCalendar className="size-4" />
                                    {slot.date ? format(slot.date, "dd/MM/yyyy") : "Selecteaza data"}
                                  </Button>
                                </PopoverTrigger>
                                <PopoverContent className="bg-white w-max">
                                  <Calendar
                                    mode="single"
                                    selected={slot.date}
                                    onSelect={date => {
                                      const newDates = [...interviewDates];

                                      newDates[index].date = date;
                                      setInterviewDates(newDates);
                                    }}
                                    className="rounded-md border"
                                    disabled={
                                      date =>
                                        date < new Date() || // disable past dates
                                        !isDateInAvailability(date, availability?.intervals ?? []) // disable dates not in availability
                                    }
                                  />
                                </PopoverContent>
                              </Popover>
                            </div>
                            <div className="flex-1">
                              <Typography className="text-gray-600 font-semibold">Ora interviu</Typography>
                              <Select
                                disabled={!slot.date}
                                value={slot.time}
                                onValueChange={value => {
                                  const newDates = [...interviewDates];

                                  newDates[index].time = value;
                                  setInterviewDates(newDates);
                                }}
                              >
                                <SelectTrigger>
                                  <SelectValue placeholder="Select time" />
                                </SelectTrigger>
                                <SelectContent>
                                  {generateTimeOptions(slot.date).map(timeSlot => (
                                    <SelectItem key={timeSlot.value} value={timeSlot.value}>
                                      {timeSlot.label}
                                    </SelectItem>
                                  ))}
                                </SelectContent>
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                    <div className="flex justify-center pt-4">
                      <Button
                        className="bg-medi-blue/10 size-8"
                        variant="ghost"
                        size="icon"
                        onClick={() => setShowFollowUpProposeForm(false)}
                      >
                        <LuArrowLeft className="h-5 w-5" />
                      </Button>
                    </div>

                    <div className="flex gap-2 mt-4">
                      <Button
                        variant="default"
                        className="flex-1"
                        disabled={!isAllDatesAndTimesSet || isProposing}
                        onClick={handleProposeTime}
                      >
                        {isProposing ? "Se trimite..." : "Trimite propunerile"}
                      </Button>
                      <Button
                        variant="outline"
                        className="flex-1"
                        onClick={async () => navigate({ search: { cancel: "true" } })}
                      >
                        Abandoneaza
                      </Button>
                    </div>

                    {eventProposedByCompany && !eventRejectedByCandidate && (
                      <div className="overlay absolute inset-0 bg-white/95 flex flex-col items-center justify-center">
                        <Typography className="text-gray-600 text-lg font-semibold text-center">
                          Cele 3 optiuni de interviu au fost trimise cu succes. Veti fi notificat prin email cand
                          candidatul selecteaza o optiune sau daca solicita o reprogramare.
                        </Typography>
                      </div>
                    )}

                    {rejectedEvent?.updated_at &&
                      latestEvent?.updated_at &&
                      rejectedEvent.updated_at > latestEvent.updated_at &&
                      eventRejectedByCandidate && (
                        <div className="overlay absolute inset-0 bg-white/95 flex flex-col items-center justify-center">
                          <Typography className="text-gray-600 text-lg font-semibold text-center">
                            candidatul a refuzat intervalele propuse. Veti fi notificat prin email cand candidatul va
                            propune noi intervale pentru interviu.
                          </Typography>
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>
          )}

          {status === ProcessStatus.NotPresented && (
            <div className="bg-white p-6 rounded-lg h-max md:w-2/6">
              <Typography>
                Candidatul a fost marcat ca neprezentat. Vei fi contactat In cel mai scurt timp de un reprezentant
                MEDIjobs pentru a remedia situatia.
              </Typography>
            </div>
          )}

          <Dialog open={!!cancel || !!finalize} onOpenChange={async () => navigate({ search: {} })}>
            <DialogContent className="bg-white max-w-xl">
              <DialogHeader>
                <DialogTitle>
                  <Typography className="text-xl font-semibold">
                    {cancel ? "Anulare interviu" : "Oferta finala"}
                  </Typography>
                </DialogTitle>
              </DialogHeader>

              {cancel && (
                <div className="flex flex-col gap-4">
                  <Typography className="text-gray-600 text-lg font-light">
                    Inainte de a anula acest interviu, te rugam sa ne oferi motivul anularii. Feedback-ul tau ne va
                    ajuta sa imbunatatim procesul.
                  </Typography>

                  <div className="flex flex-col gap-6">
                    {rejectionReasons.map(reason => (
                      <div className="flex items-center gap-2" key={reason.id}>
                        <Checkbox
                          checked={reasonForReject.includes(reason.id)}
                          onCheckedChange={value =>
                            setReasonForReject(
                              value ? [...reasonForReject, reason.id] : reasonForReject.filter(id => id !== reason.id)
                            )
                          }
                        />
                        <Label className="text-gray-600 text-lg font-light">{reason.value}</Label>
                      </div>
                    ))}

                    {reasonForReject.length > 0 && reasonForReject.find(id => id === "other") && (
                      <div className="flex flex-col gap-2">
                        <Textarea
                          placeholder="Motivul anularii"
                          value={otherReason}
                          onChange={e => setOtherReason(e.target.value)}
                        />
                      </div>
                    )}
                  </div>

                  <div className="flex gap-2 mt-4">
                    <Button variant="outline" className="flex-1" onClick={async () => navigate({ search: {} })}>
                      Renunta
                    </Button>
                    <Button
                      variant="destructive"
                      className="flex-1"
                      disabled={reasonForReject.length === 0}
                      loading={isCancellingEvent}
                      onClick={handleCancelEvent}
                    >
                      Confirma anulare
                    </Button>
                  </div>
                </div>
              )}

              {finalize && (
                <Form {...finalOfferForm}>
                  <form className="flex flex-col gap-4" onSubmit={finalOfferForm.handleSubmit(handleSendFinalOffer)}>
                    <div className="flex flex-col gap-4 w-[400px] mt-6">
                      <FormField
                        control={finalOfferForm.control}
                        name="salary"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Salariu</FormLabel>
                            <Input type="number" step="100" placeholder="Salariu" {...field} />
                          </FormItem>
                        )}
                      />

                      <div className="flex gap-2">
                        <FormField
                          control={finalOfferForm.control}
                          name="startDate"
                          render={({ field }) => (
                            <FormItem className="flex-1">
                              <FormLabel>Data de incepere</FormLabel>
                              <Popover>
                                <PopoverTrigger className="w-full shadow-md" asChild>
                                  <FormControl>
                                    <Button
                                      variant="outline"
                                      className={cn(
                                        "w-full justify-start text-gray-400",
                                        field.value && "text-gray-800"
                                      )}
                                    >
                                      <LuCalendar className="size-4" />
                                      {field.value ? format(new Date(field.value), "dd/MM/yyyy") : "Selecteaza data"}
                                    </Button>
                                  </FormControl>
                                </PopoverTrigger>
                                <PopoverContent className="bg-white w-max">
                                  <Calendar
                                    mode="single"
                                    selected={field.value ? new Date(field.value) : undefined}
                                    onSelect={date => {
                                      field.onChange(date);
                                    }}
                                    className="rounded-md border"
                                    disabled={date => date < new Date()}
                                  />
                                </PopoverContent>
                              </Popover>
                            </FormItem>
                          )}
                        />

                        <FormField
                          control={finalOfferForm.control}
                          name="contractPeriod"
                          render={({ field }) => (
                            <FormItem className="flex-1">
                              <FormLabel>Perioada contractuala</FormLabel>
                              <Select onValueChange={field.onChange} value={field.value}>
                                <FormControl>
                                  <SelectTrigger>
                                    <SelectValue placeholder="Perioada contractuala" />
                                  </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                  <SelectItem value="Determinata">Determinata</SelectItem>
                                  <SelectItem value="Nedeterminata">Nedeterminata</SelectItem>
                                </SelectContent>
                              </Select>
                            </FormItem>
                          )}
                        />
                      </div>

                      <FormField
                        control={finalOfferForm.control}
                        name="benefits"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Alte beneficii</FormLabel>
                            <Textarea placeholder="Alte beneficii" {...field} />
                          </FormItem>
                        )}
                      />
                    </div>

                    {cancel && (
                      <div className="flex gap-2 mt-4">
                        <Button variant="outline" className="flex-1" onClick={async () => navigate({ search: {} })}>
                          Renunta
                        </Button>
                        <Button
                          variant="destructive"
                          className="flex-1"
                          disabled={reasonForReject.length === 0}
                          loading={isCancellingEvent}
                          onClick={handleCancelEvent}
                        >
                          Confirma anulare
                        </Button>
                      </div>
                    )}

                    {finalize && (
                      <div className="flex gap-2 mt-4">
                        <Button variant="outline" className="flex-1" onClick={async () => navigate({ search: {} })}>
                          Renunta
                        </Button>
                        <Button
                          variant="default"
                          className="flex-1"
                          disabled={!finalOfferForm.formState.isValid}
                          loading={isSendingFinalOffer}
                          type="submit"
                        >
                          Trimite oferta finala
                        </Button>
                      </div>
                    )}
                  </form>
                </Form>
              )}
            </DialogContent>
          </Dialog>

          {status === ProcessStatus.FinalOffer && (
            <div className="bg-white p-6 rounded-lg h-max md:w-2/6 space-y-4 md:max-w-96">
              <Typography variant="h3">Oferta ta finala a fost trimisa catre candidat</Typography>

              {currentProcess?.[0]?.offer && (
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col">
                    <Typography className="text-gray-700 font-medium text-xl">Data de incepere</Typography>
                    <Typography className="text-gray-600 font-medium text-base">
                      {format(new Date(currentProcess[0].offer.start_date), "dd MMMM yyyy", { locale: ro })}
                    </Typography>
                  </div>
                  <div className="flex flex-col">
                    <Typography className="text-gray-700 font-medium text-xl">Perioada contractuala</Typography>
                    <Typography className="text-gray-600 font-medium text-base">
                      {currentProcess[0].offer.contract_period}
                    </Typography>
                  </div>
                  <div className="flex flex-col">
                    <Typography className="text-gray-700 font-medium text-xl">Salariu final</Typography>
                    <Typography className="text-gray-600 font-medium text-base">
                      {currentProcess[0].offer.salary} LEI
                    </Typography>
                  </div>

                  {currentProcess[0].offer.other_benefits && (
                    <div className="flex flex-col">
                      <Typography className="text-gray-700 font-medium text-xl">Alte beneficii</Typography>
                      <Typography className="text-gray-600 font-normal text-base">
                        {currentProcess[0].offer.other_benefits}
                      </Typography>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {status === ProcessStatus.Hire && (
            <div className="bg-white p-6 rounded-lg h-max md:w-2/6 space-y-4">
              <Typography variant="h3">Angajare!</Typography>

              {currentProcess?.[0]?.offer && (
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col">
                    <Typography className="text-gray-600 font-medium text-xl">Salariu final</Typography>
                    <Typography>{currentProcess[0].offer.salary} LEI</Typography>
                  </div>
                  <div className="flex flex-col">
                    <Typography className="text-gray-600 font-medium text-xl">Data de incepere</Typography>
                    <Typography>
                      {format(new Date(currentProcess[0].offer.start_date), "dd MMMM yyyy", { locale: ro })}
                    </Typography>
                  </div>
                  <div className="flex flex-col">
                    <Typography className="text-gray-600 font-medium text-xl">Perioada contractuala</Typography>
                    <Typography>{currentProcess[0].offer.contract_period}</Typography>
                  </div>

                  {currentProcess[0].offer.other_benefits && (
                    <div className="flex flex-col">
                      <Typography className="text-gray-600 font-medium text-xl">Alte beneficii</Typography>
                      <Typography>{currentProcess[0].offer.other_benefits}</Typography>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {status === ProcessStatus.NotPresented && (
            <div className="bg-white p-6 rounded-lg h-max md:w-2/6 space-y-4">
              <Typography className="text-gray-600 text-lg font-light">
                Candidatul a fost marcat ca neprezentat. Vei fi contactat In cel mai scurt timp de un reprezentant
                MEDIjobs pentru a remedia situatia.
              </Typography>
            </div>
          )}

          {status === ProcessStatus.OfferRejectedByCandidate && eventCancelledByCandidate && (
            <div className="bg-white p-6 rounded-lg h-max md:w-2/6 space-y-4 md:max-w-96">
              {isFirstInterviewPhase ? (
                <>
                  <Typography className="text-lg font-semibold">Candidatul a anulat interviul</Typography>
                  <Typography className="text-gray-600 text-lg font-light">
                    Candidatul a anulat interviul programat in data de{" "}
                    {cancelledEvent?.start_time &&
                      format(new Date(cancelledEvent.start_time), "dd MMM, yyyy", { locale: ro })}{" "}
                    la ora{" "}
                    {cancelledEvent?.start_time && format(new Date(cancelledEvent.start_time), "HH:mm", { locale: ro })}
                    .
                  </Typography>
                </>
              ) : (
                <>
                  {hasFeedback ? (
                    <Typography className="text-lg font-semibold">
                      Candidatul a refuzat continuarea procesului de recrutare in urma interviului care a avut loc in
                      data de{" "}
                      {cancelledEvent?.start_time &&
                        format(new Date(cancelledEvent.start_time), "dd MMM, yyyy", { locale: ro })}{" "}
                      la ora{" "}
                      {cancelledEvent?.start_time &&
                        format(new Date(cancelledEvent.start_time), "HH:mm", { locale: ro })}
                      .
                    </Typography>
                  ) : (
                    <div>
                      <Typography>Interviu anulat</Typography>
                      <Typography className="text-lg font-semibold">
                        Candidatul a solicitat anularea interviului programat in data{" "}
                        {cancelledEvent?.start_time &&
                          format(new Date(cancelledEvent.start_time), "dd MMM, yyyy", { locale: ro })}
                        . Nu iti face griji, te vom conecta cat de curand cu alti candidati.
                      </Typography>
                    </div>
                  )}
                </>
              )}
            </div>
          )}

          {status === ProcessStatus.OfferRejectedByCandidate && !eventCancelledByCandidate && (
            <div className="bg-white p-6 rounded-lg h-max md:w-2/6 space-y-4 md:max-w-96">
              <Typography className="text-lg font-semibold">
                Candidatul a respins oferta. Veti fi contactat In cel mai scurt timp de un reprezentant MEDIjobs pentru
                a remedia situatia.
              </Typography>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

export default CandidatePage;
