import { AxiosV2 } from "@/libs/utils/axios-interceptor";
import { useQuery } from "@tanstack/react-query";

interface FetchCandidateResponse {
  data: ApiInterview[];
  isLoading: boolean;
  error: string;
}
const useGetInterviews = () => {
  const { data, isLoading, error } = useQuery<FetchCandidateResponse, Error>({
    queryKey: ["interviews"],
    queryFn: async () => AxiosV2.get(`/recruitment/interview/?missed=0&rescheduled=0&cancelled=0&finished=0`),
  });

  return {
    data: data?.data ?? null,
    isLoading,
    error,
  };
};

export { useGetInterviews };
