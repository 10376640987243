import { Axios } from "@/libs/utils/axios-interceptor";
import { createMutation } from "react-query-kit";

interface AcceptOfferFastTrackProps {
  offerId: string;
}

export const useAcceptOfferFastTrack = createMutation({
  mutationFn: async ({ offerId }: AcceptOfferFastTrackProps) => Axios.post(`/offers/${offerId}/acceptfasttrack`),
});
