import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { cn } from "@/libs/utils/cn";
import { buttonVariants } from "@/components/button/Button";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

const Calendar = ({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) => (
  <DayPicker
    showOutsideDays={showOutsideDays}
    className={cn("p-3", className)}
    classNames={{
      months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
      month: "space-y-4",
      caption: "flex justify-center pt-1 relative items-center",
      caption_label: "text-sm font-medium",
      nav: "space-x-1 flex items-center",
      nav_button: cn(buttonVariants({ variant: "outline" }), "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"),
      nav_button_previous: "absolute left-1",
      nav_button_next: "absolute right-1",
      table: "w-full border-collapse space-y-1 text-center",
      head_row: "flex justify-between",
      head_cell: "text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]",
      row: "flex w-full mt-2 justify-between",
      cell: "h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
      day: cn(buttonVariants({ variant: "ghost" }), "h-9 w-9 p-0 font-normal aria-selected:opacity-100"),
      day_range_end: "day-range-end",
      day_selected:
        "bg-medi-blue text-white hover:bg-medi-blue/40 hover:text-white focus:bg-medi-blue focus:text-white",
      day_today: "border-medi-blue/80 text-medi-blue",
      day_outside: "day-outside text-gray-300 aria-selected:bg-accent/50 aria-selected:text-gray-300",
      day_disabled: "text-gray-500 opacity-50",
      day_range_middle: "aria-selected:bg-accent aria-selected:text-accent-foreground",
      day_hidden: "invisible",
      ...classNames,
    }}
    components={{
      IconLeft: () => <IoChevronBack className="h-4 w-4" />,
      IconRight: () => <IoChevronForward className="h-4 w-4" />,
    }}
    {...props}
  />
);

Calendar.displayName = "Calendar";

export { Calendar };
