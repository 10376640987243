export default {
  tasks: {
    desired_salary: "Salariu dorit",
    job_titles_and_obtained_specializations: "Titluri de studiu si specializari obtinute",
    cities_where_you_wish_to_work: "Orasele in care doresti sa profesezi",
    professional_experience: "Experienta profesionala",
    education: "Educatie",
    critical_profile_fields_require_your_attention: "Campuri critice din profil necesita atentia ta",
    critical_profile_field_requires_your_attention: "Campul critic din profil necesita atentia ta",
    important_profile_fields_require_your_attention: "Campuri importante din profil necesita atentia ta",
    important_profile_field_requires_your_attention: "Campul important din profil necesita atentia ta",
    new_offers: "Oferturi noi",
    new_offer: "Oferta noua",
  },
};
