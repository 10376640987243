import * as React from "react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { Item, Root } from "@radix-ui/react-radio-group";
import clsx from "clsx";
import { IoRadioButtonOn } from "react-icons/io5";

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => (
  <RadioGroupPrimitive.Root className={clsx("flex gap-2", className)} {...props} ref={ref} />
));

RadioGroup.displayName = "RadioGroup";
RadioGroup.propTypes = Root.propTypes;

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, ...props }, ref) => (
  <RadioGroupPrimitive.Item
    ref={ref}
    className={clsx(
      "aspect-square h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
      className
    )}
    {...props}
  >
    <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
      <IoRadioButtonOn className="h-2.5 w-2.5 fill-current text-medi-blue" />
    </RadioGroupPrimitive.Indicator>
  </RadioGroupPrimitive.Item>
));

RadioGroupItem.displayName = "RadioGroupItem";
RadioGroupItem.propTypes = Item.propTypes;

export { RadioGroup, RadioGroupItem };
