import { FormControl, FormField, FormItem, FormMessage } from "@/components/form";
import { Label } from "@/components/label";
import { IoCloseSharp as RemoveIcon } from "react-icons/io5";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/select/select";

import { Typography } from "@/components/typography";
import { useFormContext } from "react-hook-form";

import { RadioGroup, RadioGroupItem } from "@/components/radio";

import { FormattedMessage, useIntl } from "react-intl";
import {
  MultiSelector,
  MultiSelectorTrigger,
  MultiSelectorInput,
  MultiSelectorContent,
  MultiSelectorList,
  MultiSelectorItem,
} from "@/components/select/multiselect";
import { formSchemaType } from "@/components/Layouts/onboarding";
import { Badge } from "@/components/badge";
import { cn } from "@/libs/utils/cn";
import { useFetchAboardCountries } from "@/libs/hooks/useFetchAboardCountries";
import { useEffect, useMemo } from "react";
import { store } from "@/libs/store";
import { useNavigate } from "@tanstack/react-router";

const languageLevels = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

const StepSixForm = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const form = useFormContext<formSchemaType>();
  const { user } = store();

  const { data } = useFetchAboardCountries();

  const abroadCountries = useMemo(() => {
    if (!data || !Array.isArray(data)) {
      return [];
    }
    return [...data].sort((a, b) => a.name.localeCompare(b.name));
  }, [data]);

  const getLabel = (value: string) => abroadCountries.find(country => country.id === parseInt(value))?.name;

  useEffect(() => {
    if (user?.candidate_object.domain_experiences[0].category.abroad_work_countries_selectable === 0) {
      void navigate({ search: (prev: Record<string, unknown>) => ({ ...prev, step: 7 }) });
    }
  }, [user]);

  return (
    <div className="flex flex-col w-full">
      <Typography className="text-center">
        <FormattedMessage
          id="onboarding.candidate.steps.six.agree_to_work_abroad_label"
          defaultMessage="Are you interested in job offers abroad?"
        />
      </Typography>

      <div className="flex mt-4 flex-col items-center gap-8 justify-center">
        <FormField
          control={form.control}
          name="agrees_to_abroad_work"
          render={({ field }) => (
            <FormItem className="flex gap-4">
              <FormControl>
                <RadioGroup
                  onValueChange={value => {
                    field.onChange(value);
                  }}
                  className="flex"
                  defaultValue={field.value}
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="yes" id="yes" />
                    <Label htmlFor="yes">
                      <FormattedMessage id="common.yes" defaultMessage="Yes" />
                    </Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="no" id="no" />
                    <Label htmlFor="no">
                      <FormattedMessage id="common.no" defaultMessage="No" />
                    </Label>
                  </div>
                </RadioGroup>
              </FormControl>
            </FormItem>
          )}
        />
        {form?.getValues("agrees_to_abroad_work") === "yes" && (
          <div className="">
            <Typography className="text-center">
              <FormattedMessage
                id="onboarding.candidate.steps.six.preferred_countries_label"
                defaultMessage="Which countries are you willing to work in?"
              />
            </Typography>
            <div className="flex flex-col gap-2">
              <FormField
                control={form.control}
                name="abroad_work_countries"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormControl>
                      <MultiSelector values={field.value ?? []} onValuesChange={field.onChange}>
                        <MultiSelectorTrigger>
                          <MultiSelectorInput
                            placeholder={intl.formatMessage({
                              id: "onboarding.candidate.steps.six.preferred_countries_placeholder",
                              defaultMessage: "Search and select countries",
                            })}
                          />
                        </MultiSelectorTrigger>
                        <MultiSelectorContent>
                          <MultiSelectorList>
                            {abroadCountries &&
                              abroadCountries.length > 0 &&
                              abroadCountries.map(country => (
                                <MultiSelectorItem key={country.id} value={`${country.id}`}>
                                  {country.name}
                                </MultiSelectorItem>
                              ))}
                          </MultiSelectorList>
                        </MultiSelectorContent>
                      </MultiSelector>
                    </FormControl>
                    <FormMessage className="text-red-600" />
                    <div className="flex gap-2 items-center">
                      {field.value &&
                        field.value.map(item => (
                          <Badge
                            key={item}
                            className={cn("px-1 rounded-xl flex items-center gap-1 w-max")}
                            variant="default"
                          >
                            <span className="text-xs">{getLabel(item)}</span>
                            <button
                              aria-label={`Remove ${item} option`}
                              aria-roledescription="button to remove option"
                              type="button"
                              onClick={() => {
                                field.onChange(field.value!.filter(v => v !== item));
                              }}
                            >
                              <span className="sr-only">Remove {item} option</span>
                              <RemoveIcon className="h-4 w-4 hover:stroke-destructive" />
                            </button>
                          </Badge>
                        ))}
                    </div>
                  </FormItem>
                )}
              />
            </div>
          </div>
        )}
        {form.getValues("agrees_to_abroad_work") === "yes" && (
          <div className="flex flex-col gap-1">
            <Typography className="text-center leading-tight">
              <FormattedMessage
                id="onboarding.candidate.steps.six.language_level_label"
                defaultMessage="On a scale of 1 to 5, how confident are you in your English language skills?"
              />
            </Typography>

            <FormField
              control={form.control}
              name="language_ability"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <SelectTrigger>
                        <SelectValue
                          placeholder={intl.formatMessage({
                            id: "onboarding.candidate.steps.six.language_level_placeholder",
                            defaultMessage: "Select a language level",
                          })}
                        />
                      </SelectTrigger>
                      <SelectContent>
                        {languageLevels.map(level => (
                          <SelectItem key={level} value={level}>
                            {level}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default StepSixForm;
