import { getAngularCredentials } from "@/core/auth/utils";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/_dashboard-layout/needs/")({
  component: () => <div />,
  beforeLoad: () => {
    const AngularCredentials = getAngularCredentials();
    const ngApp = import.meta.env.VITE_RECRUITER_APP_URL;

    if (AngularCredentials?.profileType !== 1) {
      throw redirect({
        to: "/dashboard/candidate",
      });
    } else {
      window.location.href = `${ngApp}/needs`;
    }
  },
});
