import { Axios } from "@/libs/utils/axios-interceptor";
import { createMutation } from "react-query-kit";

interface RejectOfferProps {
  offerId: string;
  note: string;
}

export const useRejectOffer = createMutation({
  mutationFn: async ({ offerId, note }: RejectOfferProps) => Axios.post(`/offers/${offerId}/rejectoffer`, { note }),
});
