export default {
  tasks: {
    desired_salary: "Desired salary",
    job_titles_and_obtained_specializations: "Job titles and obtained specializations",
    cities_where_you_wish_to_work: "Cities where you wish to work",
    professional_experience: "Professional experience",
    education: "Education",
    critical_profile_fields_require_your_attention: "Critical profile fields require your attention",
    critical_profile_field_requires_your_attention: "Critical profile field requires your attention",
    important_profile_fields_require_your_attention: "Important profile fields require your attention",
    important_profile_field_requires_your_attention: "Important profile field requires your attention",
    new_offer: "New offer",
    new_offers: "New offers",
  },
};
