import { Typography } from "@/components/typography";
import { PiPaperclip } from "react-icons/pi";

import { FormControl, FormField, FormItem } from "@/components/form";
import { FileInput, FileUploader, FileUploaderContent, FileUploaderItem } from "@/components/file-uploader";
import { FormattedMessage } from "react-intl";
import { useFormContext } from "react-hook-form";
import { formSchemaType } from "@/components/Layouts/onboarding";

const StepThirteenForm = () => {
  const form = useFormContext<formSchemaType>();

  const dropZoneConfig = {
    maxFiles: 1,
    maxSize: 1024 * 1024 * 4,
    multiple: false,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
      "text/plain": [".txt"],
      "text/csv": [".csv"],
    },
  };

  return (
    <div className="flex flex-col w-full">
      <Typography className="text-center">
        <FormattedMessage
          id="onboarding.candidate.steps.thirteen.resume_label"
          defaultMessage="Congratulations! You've reached the final step! Upload your resume here."
        />
      </Typography>

      <div className="flex mt-10 items-center gap-4 justify-center">
        <FormField
          control={form.control}
          name="resume"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormControl>
                <FileUploader
                  value={field.value ? [field.value] : []}
                  onValueChange={field.onChange}
                  dropzoneOptions={dropZoneConfig}
                  className="relative max-w-xs space-y-1 mx-auto"
                >
                  <FileInput className="border border-dashed border-gray-500">
                    <div className="flex items-center justify-center flex-col pt-3 pb-4 w-full ">
                      <FileSvgDraw />
                    </div>
                  </FileInput>
                  <FileUploaderContent className="min-h-10">
                    {field.value && Array.isArray(field.value) && field.value.length > 0 && (
                      <FileUploaderItem index={0} inViewIndex={0} setInviewIndex={() => {}}>
                        <PiPaperclip className="h-4 w-4 stroke-current" />
                        <span>{(field.value[0] as File)?.name || "Unnamed file"}</span>
                      </FileUploaderItem>
                    )}
                  </FileUploaderContent>
                </FileUploader>
              </FormControl>
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};

export default StepThirteenForm;

const FileSvgDraw = () => (
  <>
    <svg
      className="w-8 h-8 mb-3 text-gray-500 dark:text-gray-400"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 16"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
      />
    </svg>
    <p className="mb-1 text-sm text-gray-500 dark:text-gray-400">
      <span className="font-semibold">
        <FormattedMessage id="onboarding.candidate.steps.thirteen.resume_placeholder" defaultMessage="Drag & Drop" />
      </span>
    </p>
    <p className="text-xs text-gray-500 dark:text-gray-400">PDF, DOC, or TXT</p>
  </>
);
