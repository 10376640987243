import { AxiosV2 } from "@/libs/utils/axios-interceptor";
import { useQuery } from "@tanstack/react-query";

interface UseGetEventsProps {
  status?: "accepted" | "proposed" | "rejected" | "canceled";
  participants_id?: number;
  content_type?: number;
  process_id?: number;
  shouldFetch?: boolean;
}

export const useGetEvents = ({
  participants_id,
  status,
  content_type,
  process_id,
  shouldFetch = true,
}: UseGetEventsProps) => {
  const query = new URLSearchParams();

  if (status) {
    query.set("status", status);
  }

  if (content_type) {
    query.set("content_type", content_type.toString());
  }

  if (process_id) {
    query.set("object_id", process_id.toString());
  }

  if (participants_id) {
    query.set("participants__id", participants_id.toString());
  }

  query.set("event_type__event_type", "recruitment_process");

  const { data, isLoading } = useQuery({
    queryKey: ["events", process_id, status],
    queryFn: async () => {
      const { data } = await AxiosV2.get<ApiEvent[]>(`/event-management/events/?${query.toString()}`);

      return data;
    },
    enabled: shouldFetch,
  });

  return { data, isLoading };
};
