import { IntlProvider } from "react-intl";

import messages from "./messages";

const defaultLocale = import.meta.env.VITE_APP_LOCALE as string;

interface ProviderProps {
  locale?: string;
  children: React.ReactNode;
}

const Provider = ({ children }: ProviderProps) => {
  const locale = defaultLocale;

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {children}
    </IntlProvider>
  );
};

export default Provider;
