import RegisterCompanyPage from "@/pages/auth/register-company";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth-layout/register/company/")({
  component: () => <RegisterCompanyPage />,
  beforeLoad: () => {
    const ngApp = import.meta.env.VITE_RECRUITER_APP_URL;

    window.location.href = `${ngApp}/register/company`;
  },
});
