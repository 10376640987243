import DashboardLayout from "@/components/Layouts/dashboard-layout";
import { getAngularCredentials } from "@/core/auth/utils";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/_dashboard-layout/dashboard/")({
  component: () => <DashboardLayout />,
  beforeLoad: () => {
    const AngularCredentials = getAngularCredentials();

    if (AngularCredentials?.profileType === 2) {
      throw redirect({
        to: "/dashboard/candidate",
      });
    } else {
      throw redirect({
        to: "/dashboard/company",
      });
    }
  },
});
