import { getCompanies } from "@/services/api/auth";
import { useQuery } from "@tanstack/react-query";

const useFetchCompanies = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["companies"],
    queryFn: getCompanies,
  });

  return {
    data: data?.data ?? [],
    isLoading,
    isError,
  };
};

export { useFetchCompanies };
