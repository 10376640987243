import { store } from "@/libs/store";
import { uploadCv } from "@/services/api/auth";
import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";

export interface UploadCvPayload {
  cv: File;
  access_token: string;
}
const useUploadCv = () => {
  const { user, setUser } = store();
  const { mutate, data } = useMutation<unknown, Error, UploadCvPayload>({
    mutationFn: async (payload: UploadCvPayload) => uploadCv(payload),
    onSuccess: () => {
      const URL = import.meta.env.VITE_RECRUITER_APP_URL;

      window.location.href = `${URL}/auth/callback#access_token=${user?.access_token}&token_type=Bearer&expires_in=31622400`;
      setUser(null);
    },
    onError: error => {
      toast.error(error.message);
    },
  });

  return { mutate, data };
};

export { useUploadCv };
