import axios, { AxiosInstance, AxiosResponse, AxiosError, AxiosHeaders } from "axios";
import { getAngularToken } from "@/core/auth/utils";
import { LOCALES } from "@/i18n/constants";

interface APIError {
  status?: boolean;
  message?: string;
  errors?: Record<string, string[]>;
  error?: Record<string, string[]>;
  non_field_errors?: string[];
  [key: string]: unknown; // allow additional properties with unknown type
}

const BASE_URL = import.meta.env.VITE_API_ENDPOINT;
const BASE_URL_V2 = import.meta.env.VITE_API_ENDPOINT_V2;

// get the current locale from environment variable, defaulting to English
const defaultLocale = (import.meta.env.VITE_APP_LOCALE as string) || LOCALES.RO;
const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// v1 error handler
export const handleAxiosError = (error: AxiosError<APIError>): string => {
  if (error.response) {
    const { data } = error.response;

    if (data.errors) {
      const firstErrorKey = Object.keys(data.errors)[0];

      return data.errors[firstErrorKey][0];
    }

    if (data.error) {
      const firstErrorKey = Object.keys(data.error)[0];

      return data.error[firstErrorKey][0];
    }

    return data.message || "An unknown error occurred";
  }

  if (error.request) {
    return `Bad Request: ${error.request}`;
  }

  return `Error: ${error.message}`;
};

// v2 error handler
export const handleAxiosV2Error = (error: AxiosError<APIError>): string => {
  if (error.response) {
    const { data } = error.response;

    const errorData = typeof data === "string" ? (JSON.parse(data) as Record<string, unknown>) : data;
    const firstValue = Object.values(errorData)[0];

    if (Array.isArray(firstValue)) {
      return firstValue[0] as string;
    }

    if (typeof firstValue === "string") {
      return firstValue;
    }

    if (typeof firstValue === "object" && firstValue !== null) {
      const nestedValue = Object.values(firstValue as Record<string, unknown>)[0];

      return typeof nestedValue === "string"
        ? nestedValue
        : Array.isArray(nestedValue)
          ? String(nestedValue[0])
          : "An unknown error occurred";
    }

    return "An unknown error occurred";
  }

  if (error.request) {
    return `Bad Request: ${error.request}`;
  }

  return `Error: ${error.message}`;
};

// axios instance for V1
export const Axios: AxiosInstance = axios.create({
  baseURL: BASE_URL ?? "",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

Axios.interceptors.request.use(
  config => {
    const token = getAngularToken();

    if (token) {
      config.headers = new AxiosHeaders(config.headers);
      config.headers.set("Authorization", `Bearer ${token}`);
    }

    // add Accept-Language header
    config.headers.set("Accept-Language", defaultLocale);
    // config.headers.set("Time-zone", currentTimezone);

    return config;
  },
  (error: AxiosError<APIError>) => {
    throw handleAxiosError(error);
  }
);

Axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError<APIError>) => {
    const errorMessage = handleAxiosError(error);

    throw new Error(errorMessage);
  }
);

// axios instance for V2
export const AxiosV2: AxiosInstance = axios.create({
  baseURL: BASE_URL_V2 ?? "",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

AxiosV2.interceptors.request.use(
  config => {
    const token = getAngularToken();

    if (token) {
      config.headers = new AxiosHeaders(config.headers);
      config.headers.set("Authorization", `Bearer ${token}`);
    }

    // add Accept-Language header
    config.headers.set("Accept-Language", defaultLocale);
    config.headers.set("Time-zone", currentTimezone);

    return config;
  },
  (error: AxiosError<APIError>) => {
    throw handleAxiosV2Error(error);
  }
);

AxiosV2.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError<APIError>) => {
    const errorMessage = handleAxiosV2Error(error);

    throw new Error(errorMessage);
  }
);
