import { updateCandidate } from "@/services/api/auth";
import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";

export interface UpdateCandidatePayload {
  schedule_ids: string[];
  place_id: string;
  max_distance_in_km: number;
  othercities: number;
  othercities_ids: string[];
  education_id: string;
  category_id: string;
  desired_salary: number;
  desired_salary_part: number;
  othercategories_ids: string[];
  agrees_to_abroad_work: number;
  abroad_work_countries: string[];
  experience_id: string;
  notification_preferences: string;
  identification_id: string;
  phone: string;
  id: string;
  access_token: string;
}

interface UpdateCandidateResponse {
  data: {
    candidate_id: string;
    access_token: string;
  };
}

interface UpdateCandidateProps {
  current_step: number;
  setCurrentStep: (step: number) => void;
}

const useUpdateCandidate = ({ current_step, setCurrentStep }: UpdateCandidateProps) => {
  const { data, mutateAsync, isPending, isSuccess, error, isError } = useMutation<
    UpdateCandidateResponse,
    ApiError,
    UpdateCandidatePayload
  >({
    mutationFn: async (payload: UpdateCandidatePayload) => updateCandidate(payload),
    onSuccess: () => {
      toast.success("Account updated successfully");
      setCurrentStep(current_step + 1);
    },
    onError: error => {
      toast.error(error.message);
    },
  });

  return { data, mutateAsync, isPending, isSuccess, error, isError };
};

export { useUpdateCandidate };
