import { Axios } from "@/libs/utils/axios-interceptor";
import { createMutation } from "react-query-kit";

interface SeeOfferProps {
  offerId: string;
}

export const useSeeOffer = createMutation({
  mutationFn: async ({ offerId }: SeeOfferProps) => Axios.post(`/offers/${offerId}/see`),
});
