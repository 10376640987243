import { getCities } from "@/services/api/auth";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

const useCities = () => {
  const [filteredCities, setFilteredCities] = useState<OptionKeys[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { data } = useQuery({
    queryKey: ["cities"],
    queryFn: getCities,
  });

  const cityOptions: OptionKeys[] =
    (data && data.data.map(city => ({ label: `${city.value} (${city.county})`, value: `${city.id}` }))) ?? [];

  useEffect(() => {
    if (!data) {
      return;
    }

    if (!searchTerm) {
      setFilteredCities(cityOptions);
    }

    const result = cityOptions.filter(city => city.value.toLowerCase().includes(searchTerm.toLowerCase()));

    setFilteredCities(result);
  }, [data, searchTerm]);

  return { cityOptions, filteredCities, setSearchTerm };
};

export { useCities };
