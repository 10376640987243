import { useQuery } from "@tanstack/react-query";

import { Axios } from "../utils/axios-interceptor";

export interface DashboardOffer {
  id: number;
  position: string;
  city: string;
  county: string;
  created_at: Date;
  interviewCount: number;
  need: ApiNeed;
}
interface FetchOffersResponse {
  data: ApiOffers[];
}
const useFetchOffers = (shouldFetch: boolean = true) => {
  const { data, isLoading, error } = useQuery<FetchOffersResponse, Error>({
    queryKey: ["offers"],
    queryFn: async () => Axios.get("/offers"),
    enabled: shouldFetch,
  });

  const offers =
    data && data.data.length > 0
      ? (data?.data
          .map(offer => ({
            id: offer.id,
            position: offer.need.category.value + (offer.need.subcategory ? ` ${offer.need.subcategory.value}` : ""),
            city: offer.need.city.name,
            county: offer.need.city.county,
            created_at: offer.created_at,
            interviewCount: offer.interviews.length,
            need: offer.need,
          }))
          .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()) as DashboardOffer[])
      : [];

  return {
    data: offers,
    isLoading,
    error,
  };
};

export { useFetchOffers };
