import { Axios } from "@/libs/utils/axios-interceptor";
import { createMutation } from "react-query-kit";

interface SendFinalOfferProps {
  offer_id: string;
  reason: string;
}

export interface Value {
  salary: string;
  startDate: Date;
  contractPeriod: string;
  benefits?: string;
}

export const useRejectFinalOffer = createMutation({
  mutationFn: async ({ offer_id, reason }: SendFinalOfferProps) =>
    Axios.post(`/offers/${offer_id}/rejectfinaloffer`, {
      note: reason,
    }),
});
