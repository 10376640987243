import { Link, Outlet } from "@tanstack/react-router";
import { FaUserLarge } from "react-icons/fa6";
import { MdOutlineKeyboardArrowRight, MdOutlineKeyboardArrowLeft, MdOutlineLocalOffer } from "react-icons/md";

import { LuStethoscope, LuCalendarCheck2, LuUserCog } from "react-icons/lu";

import { BsQuestionCircleFill } from "react-icons/bs";
import { TbLogout, TbUsersPlus } from "react-icons/tb";
import { FiHome, FiUsers } from "react-icons/fi";
import { RiCalendarScheduleLine } from "react-icons/ri";

import { Button } from "../button/Button";

import LOGO_LG from "@/assets/new-logo-icon.png";
import LOGO_SM from "@/assets/new-logo-text.png";

// import { Avatar, AvatarFallback, AvatarImage } from "../avatar";
import { cn } from "@/libs/utils/cn";
import { useState } from "react";
import { IoPersonOutline } from "react-icons/io5";
// import { PiBriefcase } from "react-icons/pi";
import { useAuth } from "@/core/auth";
import { getAngularCredentials } from "@/core/auth/utils";
import { RxHamburgerMenu } from "react-icons/rx";

const SIDE_BAR_CANDIDATE_ITEMS = [
  {
    icon: <FiHome />,
    label: "Acasa",
    href: "/dashboard/candidate",
  },
  {
    icon: <MdOutlineLocalOffer />,
    label: "Profil",
    href: "/profile",
  },
  {
    icon: <LuCalendarCheck2 />,
    label: "Interviuri",
    href: "/interviews",
  },
  {
    icon: <LuStethoscope />,
    label: "Oferte",
    href: "/offers",
  },
  /*
   * {
   *   icon: <PiChatsFill />,
   *   label: "Interview schedule",
   * },
   * {
   *   icon: <IoPeopleOutline />,
   *   label: "Candidates",
   * },
   */
];

const SIDE_BAR_COMPANY_ITEMS = [
  {
    icon: <FiHome />,
    label: "Acasa",
    href: "/dashboard/company",
  },
  {
    icon: <LuUserCog />,
    label: "Procese",
    href: "/needs",
  },
  {
    icon: <LuCalendarCheck2 />,
    label: "Interviuri",
    href: "/interviews",
  },
  {
    icon: <FiUsers />,
    label: "Candidati",
    href: "/candidates",
  },
  {
    icon: <RiCalendarScheduleLine />,
    label: "Programare",
    href: "/company",
  },
];

const SIDE_BAR_FOOTER_ITEMS = [
  {
    icon: <IoPersonOutline />,
    label: "Profil",
    href: "/profile",
  },
  /*
   * {
   *   icon: <IoNotificationsOutline />,
   *   label: "Notifications",
   *   href: "/notifications",
   * },
   */
  /*
   * {
   *   icon: <CiSettings />,
   *   label: "Settings",
   *   href: "/settings",
   * },
   */
];

const DashboardLayout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const signOut = useAuth.use.signOut();

  const angularCredentials = getAngularCredentials();

  const handleSignOut = () => {
    // first clear all auth state
    signOut();
  };

  return (
    <div className="bg-medi-bg max-h-screen flex overflow-hidden">
      {/* Desktop sidebar */}
      <div
        className={cn(
          "hidden h-screen bg-dark-blue text-white py-4 relative md:flex flex-col gap-5 transition-all duration-300 ease-in-out",
          isOpen ? "w-max" : "w-20"
        )}
      >
        <div className={cn("px-3 pt-8 flex items-center justify-center gap-3", !isOpen && "flex-col gap-4")}>
          <img src={LOGO_LG} alt="logo" className={cn("transition-opacity duration-300 ease-in-out w-6")} />
          <img src={LOGO_SM} alt="logo" className="w-32 transition-opacity duration-300 ease-in-out" />
        </div>

        <ul className={cn("text-xl px-3 flex flex-col gap-4 mt-10", !isOpen && "px-0")}>
          {angularCredentials?.profileType === 2
            ? SIDE_BAR_CANDIDATE_ITEMS.map((item, index) => (
                <Link key={index} to={item.href}>
                  {({ isActive }) => (
                    <li
                      title={item.label}
                      className={cn(
                        "flex items-center gap-4 py-3 px-3 rounded-full hover:bg-dark-blue-500 transition-all duration-200 ease-in-out",
                        isActive && isOpen && "bg-medi-blue",
                        !isOpen && "justify-center transition-opacity duration-300 ease-in-out",
                        isActive && !isOpen && "border-l-4 border-medi-blue rounded-none"
                      )}
                    >
                      <span className="text-2xl font-light">{item.icon}</span>
                      {isOpen && <span className="transition-opacity duration-300 ease-in-out">{item.label}</span>}
                    </li>
                  )}
                </Link>
              ))
            : SIDE_BAR_COMPANY_ITEMS.map((item, index) => (
                <Link key={index} to={item.href}>
                  {({ isActive }) => (
                    <li
                      title={item.label}
                      className={cn(
                        "flex items-center gap-4 py-3 px-3 rounded-full hover:bg-dark-blue-500 transition-all duration-200 ease-in-out",
                        isActive && isOpen && "bg-medi-blue",
                        !isOpen && "justify-center transition-opacity duration-300 ease-in-out",
                        isActive && !isOpen && "border-l-4 border-medi-blue rounded-none"
                      )}
                    >
                      <span className="text-2xl">{item.icon}</span>
                      {isOpen && item.label}
                    </li>
                  )}
                </Link>
              ))}
        </ul>

        {/* sidebar footer */}
        <div className={cn("text-xl px-4 flex flex-col gap-4 mt-auto", !isOpen && "px-0")}>
          {SIDE_BAR_FOOTER_ITEMS.map((item, index) => (
            <Link key={index} to={item.href}>
              {({ isActive }) => (
                <li
                  key={index}
                  className={cn(
                    "flex items-center gap-6 py-3 px-3 rounded-full hover:bg-dark-blue-500",
                    isActive && isOpen && "bg-medi-blue",
                    !isOpen && "justify-center",
                    isActive && !isOpen && "border-l-4 border-medi-blue rounded-none"
                  )}
                >
                  <span className="text-2xl">{item.icon}</span>
                  {isOpen && item.label}
                </li>
              )}
            </Link>
          ))}

          <li
            className={cn(
              "flex items-center gap-6 py-3 px-3 rounded-full hover:bg-dark-blue-500 justify-between",
              !isOpen && "justify-center"
            )}
          >
            <button onClick={handleSignOut} className="flex items-center gap-6">
              <span className="text-2xl">
                <TbLogout className="text-red-600" />
              </span>
              {isOpen && "Logout"}
            </button>
            {isOpen && (
              <button onClick={() => setIsOpen(!isOpen)}>
                <MdOutlineKeyboardArrowLeft className="text-2xl mr-4" />
              </button>
            )}
          </li>

          {!isOpen && (
            <button onClick={() => setIsOpen(!isOpen)} className="py-3 px-3 w-max mx-auto rounded-lg">
              <MdOutlineKeyboardArrowRight className="text-3xl" />
            </button>
          )}
        </div>
      </div>
      <div className="flex-1 h-screen">
        {/* Modified top-nav */}
        <nav className="relative py-5 px-10 flex justify-between items-center gap-10 bg-white shadow-sm">
          <button className="text-2xl md:hidden" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
            <RxHamburgerMenu />
          </button>

          {/* Mobile dropdown menu */}
          {isMobileMenuOpen && (
            <div className="absolute top-full left-0 right-0 bg-white shadow-lg md:hidden z-50">
              <ul className="py-2">
                {angularCredentials?.profileType === 2
                  ? SIDE_BAR_CANDIDATE_ITEMS.map((item, index) => (
                      <Link key={index} to={item.href} onClick={() => setIsMobileMenuOpen(false)}>
                        <li className="flex items-center gap-3 px-10 py-3 hover:bg-gray-50">
                          <span className="text-xl">{item.icon}</span>
                          <span>{item.label}</span>
                        </li>
                      </Link>
                    ))
                  : SIDE_BAR_COMPANY_ITEMS.map((item, index) => (
                      <Link key={index} to={item.href} onClick={() => setIsMobileMenuOpen(false)}>
                        <li className="flex items-center gap-3 px-10 py-3 hover:bg-gray-50">
                          <span className="text-xl">{item.icon}</span>
                          <span>{item.label}</span>
                        </li>
                      </Link>
                    ))}
                {SIDE_BAR_FOOTER_ITEMS.map((item, index) => (
                  <Link key={index} to={item.href} onClick={() => setIsMobileMenuOpen(false)}>
                    <li className="flex items-center gap-3 px-10 py-3 hover:bg-gray-50">
                      <span className="text-xl">{item.icon}</span>
                      <span>{item.label}</span>
                    </li>
                  </Link>
                ))}
                <button
                  className="flex items-center gap-3 px-10 py-3 hover:bg-gray-50 text-red-600"
                  onClick={handleSignOut}
                >
                  <span className="text-xl">
                    <TbLogout />
                  </span>
                  <span>Logout</span>
                </button>
              </ul>
            </div>
          )}

          {/* Existing top-nav content */}
          {angularCredentials?.profileType === 1 && (
            <div className="w-max ml-auto">
              <Link to="/needs/add">
                <Button className="w-max px-6 h-10 gap-3" variant="default">
                  <TbUsersPlus className="fill-white" />
                  Recrutare nouă
                </Button>
              </Link>
            </div>
          )}
          {angularCredentials?.profileType === 2 && (
            <div className="w-max flex items-center gap-4 text-xl ml-auto">
              <BsQuestionCircleFill />
              <FaUserLarge />
            </div>
          )}
        </nav>
        <div
          className="flex flex-1 "
          style={{
            height: "calc(100dvh - 3.9rem)",
          }}
        >
          <main className="flex-1 overflow-y-scroll p-5 md:mb-5">
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
