import { AxiosV2 } from "@/libs/utils/axios-interceptor";
import { createMutation } from "react-query-kit";

interface SaveCandidateInterviewPresenceProps {
  interviewId: string;
  isCandidatePresent: boolean;
}

export const useSaveCandidateInterviewPresence = createMutation({
  mutationFn: async ({ interviewId, isCandidatePresent }: SaveCandidateInterviewPresenceProps) =>
    AxiosV2.post(`/recruitment/interview/${interviewId}/save-candidate-presence/`, { present: isCandidatePresent }),
});
