import { Axios } from "@/libs/utils/axios-interceptor";
import { useQuery } from "@tanstack/react-query";

interface FetchCandidateResponse {
  data: ApiCandidate;
  isLoading: boolean;
  error: string;
}
const useGetCandidateById = (id: number) => {
  const { data, isLoading, error } = useQuery<FetchCandidateResponse, Error>({
    queryKey: ["candidateById", id],
    queryFn: async () => Axios.get(`/candidates?candidate_id=${id}`),
    enabled: !!id,
  });

  return {
    data: data?.data ?? null,
    isLoading,
    error,
  };
};

export { useGetCandidateById };
