// candidate onboarding steps and form messages
export default {
  candidate: {
    steps: {
      one: {
        title: "Bun venit!",
        description: "Introduceți adresa de email și parola pentru a începe.",
        email: "Email *",
        email_placeholder: "Adresă de email",
        password: "Parolă *",
        phone: "Telefon *",
        password_placeholder: "Parolă",
        confirm_password: "Confirmă parola *",
        confirm_password_placeholder: "Confirmă parola",
        name: "Nume complet *",
        name_placeholder: "Introduceți numele complet",
        category: "Titulatura *",
        category_hint: "Ce titulatură ai la actualul loc de muncă?",
        category_placeholder: "Selectează titulatura",
        sub_category: "Specialitate medicală",
        sub_category_hint: "În ce specialitate medicală activezi sau ai activat?",
        sub_category_placeholder: "Selectează Specialitățile",
        tags_label: "Competențe",
        tags_placeholder: "Selectează Competențe",
        tags_hint: "Selectează competențele relevante pentru tine",
        terms:
          "Pentru a continua, vă rugăm să confirmați că sunteți de acord cu <link>Termenii și Condițiile MEDIjobs</link> *",
        receive_notifications: "Doresc sa primesc notificari cu noutati privind MEDIjobs si partenerii sai",
      },
      two: {
        title: "Ce tip de program de lucru îți dorești?",
        work_type_label: "Doriți să lucrați cu normă întreagă sau parțială?",
      },
      three: {
        title: "Preferință de locație",
        city_label: "Care este orasul dvs de domiciliu?",
        city_placeholder: "Caută și selectează orașul",
        work_locations_label: "În ce orașe dorești să primești oferte de muncă?",
        work_locations_placeholder: "Caută și selectează locațiile",
        max_distance_in_km_label: "Cât esti dispus(ă) să faci naveta pana la locul de muncă?",
        max_distance_in_km_placeholder: "Selectează distanța ",
        open_to_relocation_label: "Sunteți dispus să vă relocati pentru muncă?",
        preferred_cities_label: "În ce orașe din Romania te-ai reloca pentru oportunitatea ideală de muncă?",
        preferred_cities_placeholder: "Caută și selectează orașele",
        preferred_cities_hint: "Indicații: Puteți selecta mai multe opțiuni...",
      },
      four: {
        title: "Context educațional",
        certification_label: "Care este ultima instituție de învățământ pe care ați absolvit-o?",
        certification_placeholder: "Selectează certificarea",
      },

      five: {
        title: "Așteptări salariale",
        salary_expectations_label: "Care este salariul dorit pentru un program full time?",
        min_salary_label: "Minim",
        min_salary_placeholder: "Introduceți suma minimă",
        max_salary_label: "Maxim",
        max_salary_placeholder: "Introduceți suma maximă",
      },
      six: {
        title: "Muncă în străinătate",
        agree_to_work_abroad_label: "Sunteți interesat de oferte de muncă în străinătate?",
        preferred_countries_label: "În ce țări sunteți dispus să vă relocati?",
        preferred_countries_placeholder: "Caută și selectează țările",
        language_level_label: "Pe o scală de la 1 la 10, cum considerați nivelul dvs. de cunoștințe în limba engleză?",
        language_level_placeholder: "Selectează opțiunea",
      },
      seven: {
        title: "Te rugăm să adăugi orice alte titulaturi pentru care dorești să primești oferte de muncă",
        secondary_job_title_label: "Selectează alte titulaturi pentru care vrei sa primesti oferte de muncă",
        secondary_job_title_placeholder: "Alte posturi de interes",
        secondary_job_sub_category_label: "În ce specialitate medicală activezi sau ai activat?",
      },
      eight: {
        title: "Ani de experiență",
        experience_level_placeholder: "Selectează nivelul de experiență",
        years_of_experience_label: "De cât timp lucrați ca {category}? *",
        years_of_experience_placeholder: "Selectează anii de experiență",
      },
      nine: {
        title: "Care este numărul dvs. de telefon?",
        phone_label:
          "Furnizarea acestei informații este necesară pentru a primi mesaje text cu oferte de muncă și pentru a efectua interviuri telefonice cu angajatorii interesați.",
      },
      ten: {
        title: "Canal preferat de comunicare",
        notification_preferences_label: "Cum preferați să vă contactăm?",
        notification_preferences_placeholder: "Caută și selectează preferințele",
      },
      eleven: {
        title: "Starea căutării de muncă",
        job_search_status_label:
          "Anunțați-ne cât de activ căutați un nou loc de muncă, astfel încât să vă putem conecta cu oportunități mai rapid.",
        job_search_status_placeholder: "Selectează starea căutării de muncă",
      },
      twelve: {
        title: "Selectați companiile pe care doriți să le evitați",
        black_listed_label: "Blocați angajatorii cu care nu doriți să vă conectați.",
        black_listed_placeholder: "Caută și selectează companiile",
      },
      thirteen: {
        title: "Încărcați CV-ul dvs.",
        resume_label: "Felicitări! Ați ajuns la ultimul pas! Încărcați aici CV-ul dvs.",
      },
    },
    years_of_experience_options: {
      "0_1": "0 - 1",
      "1_3": "1 - 3",
      "3_5": "3 - 5",
      "5_10": "5 - 10",
      "10_50": "Mai mult de 10+",
    },
  },
};
