import DashboardLayout from "@/components/Layouts/dashboard-layout";
import { getAngularToken } from "@/core/auth/utils";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/_dashboard-layout")({
  component: () => <DashboardLayout />,
  beforeLoad: ({ location }) => {
    const token = getAngularToken();

    if (token === null) {
      throw redirect({
        to: "/login",
        replace: true,
        search: {
          /*
           * use the current location to power a redirect after login
           * (Do not use `router.state.resolvedLocation` as it can
           * potentially lag behind the actual current location)
           */
          redirect: location.href,
        },
      });
    } else {
      return {
        token,
      };
    }
  },
});
