/* eslint-disable no-loops/no-loops */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

// eslint-disable-next-line import/no-duplicates
import ro from "date-fns/locale/ro";
import { Calendar, dateFnsLocalizer, Views, DateFormat, Formats, SlotInfo } from "react-big-calendar";
// eslint-disable-next-line import/no-duplicates
import { parse, startOfWeek, getDay, format } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import { useGetAvailability } from "@/core/hooks/useGetAvailability";
import { Dialog, DialogTitle, DialogContent, DialogHeader } from "@/components/dialog";
import { Button } from "@/components/button/Button";
import { Label } from "@/components/label";
import Input from "@/components/input/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/select/select";
import { createEventSchema } from "@/core/schemas/create-event";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/form";
import { useCreateAvailability } from "@/core/hooks/company/mutations/useCreateAvailability";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";
import { useUpdateAvailability } from "@/core/hooks/company/mutations/useUpdateAvailability";
import { useDeleteAvailability } from "@/core/hooks/company/mutations/useDeleteAvailability";
import { useGetAccount } from "@/core/hooks/useGetAccount";
import { getAngularToken } from "@/core/auth/utils";
import Loading from "@/components/loading";

const locales = {
  "ro-RO": ro,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const generateTimeOptions = () => {
  const times = [];

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const hourStr = hour.toString().padStart(2, "0");
      const minStr = minute.toString().padStart(2, "0");
      const timeStr = `${hourStr}:${minStr}`;
      const label = format(parse(timeStr, "HH:mm", new Date()), "hh:mm a");

      times.push({ value: timeStr, label });
    }
  }
  return times;
};

type FormType = z.infer<typeof createEventSchema>;
export const Schedule = () => {
  const AngulaToken = getAngularToken();
  const { data: user } = useGetAccount(AngulaToken ?? "");
  const queryClient = useQueryClient();

  const { data, isLoading } = useGetAvailability({ processId: user?.user?.id.toString() ?? "" });
  const [open, setOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState<string | null>(null);

  const defaultValues = useMemo(
    () => ({
      start_time: "",
      end_time: "",
      selectedDate: "",
    }),
    []
  );

  const form = useForm<FormType>({
    resolver: zodResolver(createEventSchema),
    defaultValues,
  });

  const { mutateAsync: createAvailability, isPending: isCreating } = useCreateAvailability();
  const { mutateAsync: updateAvailability, isPending: isUpdating } = useUpdateAvailability();
  const { mutateAsync: deleteAvailability, isPending: isDeleting } = useDeleteAvailability();

  const handleEventClick = useCallback((event: { id: number; start: Date; end: Date; title?: string }) => {
    setSelectedEventId(event.id.toString());
    const formattedStart = format(event.start, "HH:mm");
    const formattedEnd = format(event.end, "HH:mm");

    form.setValue("start_time", formattedStart);
    form.setValue("end_time", formattedEnd);
    form.setValue("selectedDate", event.start.toISOString());
    form.setValue("title", event.title || "");
    setOpen(true);
  }, []);

  const handleDelete = useCallback(async () => {
    if (!selectedEventId) {
      return;
    }

    await deleteAvailability(
      { id: selectedEventId, user: user?.user?.id.toString() ?? "" },
      {
        onSuccess: async () => {
          toast.success("Disponibilitatea a fost ștearsă cu succes");
          setOpen(false);
          form.reset(defaultValues);
          setSelectedEventId(null);
          await queryClient.invalidateQueries({ queryKey: ["availability"] });
        },
        onError: () => {
          toast.error("Eroare la stergerea disponibilității");
        },
      }
    );
  }, [selectedEventId]);

  const handleSubmit = useCallback(
    async (data: FormType) => {
      const selectedDate = new Date(data.selectedDate);
      const jsWeekDay = selectedDate.getDay();
      const weekDay = jsWeekDay === 0 ? 6 : jsWeekDay - 1;

      const formattedData = {
        title: data.title || "",
        start_time: `${data.start_time}:00`,
        end_time: `${data.end_time}:00`,
        user: user?.user?.id.toString() ?? "",
        weekday: weekDay.toString(),
      };

      if (selectedEventId) {
        // update existing event
        await updateAvailability(
          { id: selectedEventId, ...formattedData },
          {
            onSuccess: async () => {
              toast.success("Disponibilitatea a fost actualizată cu succes");
              setOpen(false);
              form.reset(defaultValues);
              setSelectedEventId(null);
              await queryClient.invalidateQueries({ queryKey: ["availability"] });
            },
          }
        );
      } else {
        // create new event
        await createAvailability(formattedData, {
          onSuccess: async () => {
            toast.success("Disponibilitatea a fost creată cu succes");
            setOpen(false);
            form.reset(defaultValues);
            await queryClient.invalidateQueries({ queryKey: ["availability"] });
          },
        });
      }
    },
    [selectedEventId, user]
  );

  const { defaultDate, formats, views, events } = useMemo(() => {
    // convert availability data to calendar events
    const convertedEvents =
      data?.intervals?.map(slot => {
        const baseDate = new Date();
        // convert from backend (0-6, Mon-Sun) to JS (0-6, Sun-Sat)
        const backendDay = slot.weekday;
        const jsDay = backendDay === 6 ? 0 : backendDay + 1;

        // calculate difference using JS day numbers
        const diff = jsDay - baseDate.getDay();

        // rest of the event conversion remains the same
        const eventDate = new Date(baseDate);

        eventDate.setDate(baseDate.getDate() + diff);

        const [startHours, startMinutes] = slot.start_time.split(":");
        const [endHours, endMinutes] = slot.end_time.split(":");

        const start = new Date(eventDate);

        start.setHours(parseInt(startHours), parseInt(startMinutes), 0);

        const end = new Date(eventDate);

        end.setHours(parseInt(endHours), parseInt(endMinutes), 0);

        return {
          id: slot.id,
          title: "Available interview slots",
          start,
          end,
        };
      }) ?? [];

    return {
      defaultDate: new Date(),
      formats: {
        dayHeaderFormat: (date: Date, culture: Locale): DateFormat =>
          format(date, "dddd MMMM Do", { locale: culture }) ?? "",
        dayFormat: (date: Date, culture: Locale): DateFormat => format(date, "EEEE", { locale: culture }) ?? "",
      },
      views: [Views.WEEK, Views.DAY],
      events: convertedEvents,
    };
  }, [data]);

  const handleSelectSlot = useCallback(({ start, end }: SlotInfo) => {
    // format the dates to match the time format used in the selects
    const formattedStart = format(start, "HH:mm");
    const formattedEnd = format(end, "HH:mm");

    form.setValue("start_time", formattedStart);
    form.setValue("end_time", formattedEnd);
    form.setValue("selectedDate", start.toISOString()); // store the selected date

    setOpen(true);
  }, []);

  const getFilteredEndTimeOptions = useCallback((startTime: string | undefined) => {
    if (!startTime) {
      return generateTimeOptions();
    }
    const options = generateTimeOptions();
    const startIndex = options.findIndex(option => option.value === startTime);

    return options.slice(startIndex + 1);
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Calendar
        defaultDate={defaultDate}
        localizer={localizer}
        defaultView={Views.WEEK}
        formats={formats as unknown as Formats}
        views={views}
        events={events}
        onSelectSlot={handleSelectSlot}
        onSelectEvent={evt => {
          handleEventClick(evt);
        }}
        selectable
        startAccessor="start"
        endAccessor="end"
        style={{ height: "calc(100vh - 200px)" }}
        toolbar={false}
        min={new Date(new Date().setHours(7, 0, 0))}
        max={new Date(new Date().setHours(21, 0, 0))}
      />

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="w-full max-w-2xl bg-white flex flex-col">
          <DialogHeader className="flex !flex-row justify-between items-center">
            <DialogTitle className="">{selectedEventId ? "Edit interview slot" : "Create interview slot"}</DialogTitle>
            <button
              onClick={() => {
                setSelectedEventId(null);
                form.reset(defaultValues);
                setOpen(false);
              }}
              className="w-max px-2.5 flex items-center justify-center text-xl rounded-full hover:bg-medi-blue/20"
            >
              x
            </button>
          </DialogHeader>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col gap-4 md:min-w-96">
              <FormField
                name="title"
                render={({ field }) => (
                  <div className="flex flex-col gap-2 flex-1">
                    <Label>Title (optional)</Label>
                    <FormItem>
                      <FormControl>
                        <Input placeholder="Enter title" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  </div>
                )}
              />
              <div className="flex gap-2">
                <FormField
                  name="start_time"
                  render={({ field }) => (
                    <div className="flex flex-col gap-2 flex-1">
                      <Label>Start time *</Label>
                      <FormItem>
                        <FormControl>
                          <Select value={field.value} onValueChange={field.onChange}>
                            <SelectTrigger>
                              <SelectValue placeholder="Select time" />
                            </SelectTrigger>
                            <SelectContent>
                              {generateTimeOptions().map(time => (
                                <SelectItem key={time.value} value={time.value}>
                                  {time.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    </div>
                  )}
                />

                <FormField
                  name="end_time"
                  render={({ field }) => (
                    <div className="flex flex-col gap-2 flex-1">
                      <Label>End time *</Label>
                      <FormItem>
                        <FormControl>
                          <Select
                            value={field.value}
                            onValueChange={field.onChange}
                            disabled={!form.getValues("start_time")}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Select time" />
                            </SelectTrigger>
                            <SelectContent>
                              {getFilteredEndTimeOptions(form.getValues("start_time")).map(time => (
                                <SelectItem key={time.value} value={time.value}>
                                  {time.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    </div>
                  )}
                />
              </div>
              <div className="flex gap-2">
                {selectedEventId ? (
                  <>
                    <Button type="submit" className="flex-1" loading={isUpdating}>
                      Save changes
                    </Button>
                    <Button
                      type="button"
                      variant="destructive"
                      className="flex-1"
                      onClick={handleDelete}
                      loading={isDeleting}
                    >
                      Delete
                    </Button>
                  </>
                ) : (
                  <Button type="submit" className="w-full" loading={isCreating}>
                    Create
                  </Button>
                )}
              </div>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
};
