import { getCategories } from "@/services/api/auth";
import { useQuery } from "@tanstack/react-query";

const useFetchCategories = () => {
  const { data, isError, isLoading } = useQuery({
    queryKey: ["categories"],
    queryFn: getCategories,
  });

  return {
    data: data?.data ?? [],
    isError,
    isLoading,
  };
};

export { useFetchCategories };
