import { createIntl } from "react-intl";
import { z } from "zod";

const intl = createIntl({
  locale: "ro-RO",
  messages: {
    "errors.required": "Acest câmp este obligatoriu",
    "errors.invalid_email": "Adresa de email este invalidă",
    "errors.password_too_short": "Parola trebuie să aibă minim 8 caractere",
    "errors.password_mismatch": "Parolele nu se potrivesc",
    "errors.min_salary": "Salariul minim trebuie să fie mai mare decât 0",
    "errors.min_salary_number": "Salariul minim trebuie să fie un număr",
    "errors.phone_number_too_short": "Numărul de telefon trebuie să aibă minim 10 caractere",
    "errors.invalid_phone_number": "Numărul de telefon este invalid",
  },
});

export const loginSchema = z.object({
  email: z.string({ required_error: intl.formatMessage({ id: "errors.required" }) }).email({
    message: intl.formatMessage({ id: "errors.invalid_email" }),
  }),
  password: z.string({ required_error: intl.formatMessage({ id: "errors.required" }) }).min(3, {
    message: intl.formatMessage({ id: "errors.required" }),
  }),
});
