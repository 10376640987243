// candidate onboarding steps and form messages
export default {
  candidate: {
    steps: {
      one: {
        title: "Welcome!",
        description: "Enter your email and password to get started.",
        email: "Email *",
        email_placeholder: "Email address",
        password: "password *",
        password_placeholder: "password",
        confirm_password: "Confirm Password *",
        confirm_password_placeholder: "Confirm Password",
        name: "Full Name *",
        name_placeholder: "Enter Fullname",
        phone: "Phone *",
        category: "Job title *",
        category_hint: "What is your current job title?",
        category_placeholder: "Select Job Title",
        sub_category: "Medical specialty",
        sub_category_hint: "In which medical specialty do you work or have you worked?",
        sub_category_placeholder: "Select Medical Specialty",
        tags_label: "Competencies",
        tags_hint: "Select relevant Competencies for your profile",
        tags_placeholder: "Select Competencies",
        terms: "To proceed, please confirm that you agree to the <link>MEDIjobs Terms and Conditions</link> *",
        receive_notifications: "I want to receive notifications about MEDIjobs and its partners",
      },
      two: {
        title: "What type of work schedule do you prefer?",
        work_type_label: "Would you like full-time or part-time work? *",
      },
      three: {
        title: "Location preferences",
        city_label: "What is your city of residence?",
        city_placeholder: "Search and select city",
        work_locations_label: "In which cities do you want to receive job offers?",
        work_locations_placeholder: "Search and select locations",
        max_distance_in_km_label: "How far are you willing to commute to work?",
        max_distance_in_km_placeholder: "Select distance in km",
        open_to_relocation_label: "Are you willing to relocate for work purposes?",
        preferred_cities_label: "In which cities in Romania would you relocate for the ideal job opportunity?",
        preferred_cities_placeholder: "Search and select cities",
        preferred_cities_hint: "Hints: You can select multiple options...",
      },
      four: {
        title: "Educational Background",
        certification_label: "What is the most recent educational institution you graduated from?",
        certification_placeholder: "Select Certification",
      },

      five: {
        title: "Salary Expectations",
        salary_expectations_label: "What is your desired salary for a full-time job?",
        min_salary_label: "Min",
        min_salary_placeholder: "Enter minimum amount",
        max_salary_label: "Max",
        max_salary_placeholder: "Enter maximum amount",
      },
      six: {
        title: "Work abroad",
        agree_to_work_abroad_label: "Are you interested in job offers abroad?",
        preferred_countries_label: "Which countries are you willing to relocate to?",
        preferred_countries_placeholder: "Search and select countries",
        language_level_label: "On a scale from 1 to 10, what do you consider your level of English knowledge to be?",
        language_level_placeholder: "Select option",
      },
      seven: {
        title: "Please add any other job titles for which you would like to receive job offers.",
        secondary_job_title_label: "Please add any other job titles for which you would like to receive job offers.",
        secondary_job_title_placeholder: "Select Job Title",
      },
      eight: {
        title: "Years of experience",
        experience_level_placeholder: "Select Experience Level",
        years_of_experience_label: "How long have you been working as a {category}? *",
        years_of_experience_placeholder: "Select Years of Experience",
      },
      nine: {
        title: "What is your phone number?",
        phone_label:
          "Providing this information is necessary to receive text messages with job offers and to conduct phone interviews with interested employers.",
      },
      ten: {
        title: "preferred communication channel",
        notification_preferences_label: "How do you prefer we contact you?",
        notification_preferences_placeholder: "Search and select preferences",
      },
      eleven: {
        title: "Job search status",
        job_search_status_label:
          "Let us know how actively you are looking for a new job so we can connect you with opportunities more quickly.",
        job_search_status_placeholder: "Select Job Search Status",
      },
      twelve: {
        title: "Select blacklisted companies",
        black_listed_label: "Block employers you do not wish to connect with.",
        black_listed_placeholder: "Search and select companies",
      },
      thirteen: {
        title: "Upload your CV",
        resume_label: "Congratulations! You've reached the final step! Upload your resume here",
        resume_placeholder: "Click to upload, or drag and drop",
      },
    },
    years_of_experience_options: {
      "0_1": "0 - 1",
      "1_3": "1 - 3",
      "3_5": "3 - 5",
      "5_10": "5 - 10",
      "10_50": "More than 10+",
    },
  },
};
