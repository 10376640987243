import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_dashboard-layout/profile")({
  component: () => <div />,
  beforeLoad: () => {
    const ngApp = import.meta.env.VITE_RECRUITER_APP_URL;

    window.location.href = `${ngApp}/profile`;
  },
});
