const BASE_URL = import.meta.env.VITE_API_ENDPOINT;

async function request(url: string, method = "GET", data: unknown = null): Promise<unknown> {
  const options: RequestInit = { method };

  options.headers = {};

  if (data) {
    if (data instanceof FormData) {
      options.body = data;
    } else {
      options.headers["Content-Type"] = "application/json; charset=UTF-8";
      options.headers.Accept = "application/json";
      options.body = JSON.stringify(data);
    }
  }

  return fetch(BASE_URL + url, options)
    .then(async response => {
      const contentType = response.headers.get("content-type");

      if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json();
      }

      return {};
    })
    .catch(error => {
      throw error;
    });
}

export const http = {
  get: async (url: string) => request(url),
  delete: async (url: string) => request(url, "DELETE"),
  post: async (url: string, data: unknown) => request(url, "POST", data),
  patch: async (url: string, data: unknown) => request(url, "PATCH", data),
  put: async (url: string, data: unknown) => request(url, "PUT", data),
};
