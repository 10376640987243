import { Accordion, AccordionTrigger, AccordionContent, AccordionItem } from "@/components/accordion";
import Loading from "@/components/loading";
import useGetTasks from "@/core/hooks/useGetTasks";
import { useFetchCandidate } from "@/libs/hooks/useFetchCandidate";
import { BsListTask } from "react-icons/bs";
import { IoMdPeople } from "react-icons/io";
import { MdLocalOffer } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { Link } from "@tanstack/react-router";

import JobCardItem from "@/components/jobCarditem";
import { useFetchOffers } from "@/libs/hooks/useFetchOffers";
import { cn } from "@/libs/utils/cn";
import { Button } from "@/components/button/Button";
import { useGetInterviews } from "@/core/hooks/useGetInterviews";
import InterviewCard from "@/components/interview-card";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";

const CandidateDashboard: React.FC = () => {
  const { data, isLoading } = useFetchCandidate();
  const { data: offers, isLoading: isOffersLoading } = useFetchOffers();
  const { urgent, important, newOffers } = useGetTasks({ entity: "candidate" });
  const { data: interviews, isLoading: isInterviewsLoading } = useGetInterviews();

  useDocumentTitle("Dashboard");

  if (isLoading || isOffersLoading || isInterviewsLoading) {
    return <Loading />;
  }

  const taskLength = urgent.length + important.length + newOffers.length;
  const offersLength = offers.length;

  return (
    <div className="flex flex-col gap-5 h-full">
      <div className="flex flex-col md:flex-row gap-5 flex-1">
        <div className="md:flex-1 bg-dark-blue text-white p-6 rounded-lg overflow-y-auto  max-sm:h-max">
          <h1 className="text-2xl font-bold mb-4">Bine ai venit, {data?.name}</h1>
          <Link to="/profile">
            <Button>Editeaza profil</Button>
          </Link>
        </div>

        <div className="md:flex-1 bg-white p-6 rounded-lg shadow">
          <div className="flex flex-col h-full">
            <h2 className="text-xl font-semibold mb-4 flex items-center justify-between border-b border-gray-100 pb-4">
              <div className="flex items-center">
                <BsListTask className="mr-2" />
                <span className="mr-2">Tasks</span>
              </div>
              <span
                className={cn(
                  "rounded-full px-3 py-1 text-sm",
                  taskLength > 0 ? "bg-medi-blue text-white" : "bg-gray-200 text-gray-700"
                )}
              >
                {taskLength}
              </span>
            </h2>
            <div className="md:flex-1 overflow-y-auto">
              {taskLength > 0 && (
                <Accordion type="single" collapsible>
                  {newOffers.length > 0 && (
                    <AccordionItem value="newOffers">
                      <AccordionTrigger>
                        {newOffers.length}{" "}
                        <FormattedMessage
                          id={newOffers.length > 1 ? "dashboard.tasks.new_offers" : "dashboard.tasks.new_offer"}
                          defaultMessage="Ofertă nouă"
                        />
                      </AccordionTrigger>
                      {newOffers.map(offer => (
                        <AccordionContent key={offer.offer_id}>
                          <Link className="text-medi-blue" to="/offers/$id" params={{ id: String(offer.offer_id) }}>
                            {offer.offer_title}
                          </Link>
                        </AccordionContent>
                      ))}
                    </AccordionItem>
                  )}
                  {urgent.length > 0 && (
                    <AccordionItem value="urgent">
                      <AccordionTrigger>
                        {urgent.length}{" "}
                        <FormattedMessage
                          id="dashboard.tasks.critical_profile_field_requires_your_attention"
                          defaultMessage="Campul critic din profil necesita atentia ta"
                        />
                      </AccordionTrigger>
                      {urgent.map(task => (
                        <AccordionContent key={task}>
                          <Link className="text-medi-blue" to="/profile">
                            {task}
                          </Link>
                        </AccordionContent>
                      ))}
                    </AccordionItem>
                  )}

                  {important.length > 0 && (
                    <AccordionItem value="important">
                      <AccordionTrigger>
                        {important.length}{" "}
                        <FormattedMessage
                          id="dashboard.tasks.important_profile_field_requires_your_attention"
                          defaultMessage="Campul important din profil necesita atentia ta"
                        />
                      </AccordionTrigger>
                      {important.map(task => (
                        <AccordionContent key={task}>
                          <Link className="text-medi-blue" to="/profile">
                            {task}
                          </Link>
                        </AccordionContent>
                      ))}
                    </AccordionItem>
                  )}
                </Accordion>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-5 md:flex-1 md:min-h-0">
        <div className="md:flex-1 bg-white p-6 rounded-lg shadow md:overflow-hidden">
          <div className="flex flex-col h-full max-sm:max-h-[30rem]">
            <h2 className="text-xl font-semibold mb-4 flex items-center justify-between border-b border-gray-100 pb-4">
              <div className="flex items-center">
                <IoMdPeople className="mr-2" />
                <span className="mr-2">Interviuri</span>
              </div>
              <span
                className={cn(
                  "rounded-full px-3 py-1 text-sm",
                  (interviews?.length ?? 0) > 0 ? "bg-medi-blue text-white" : "bg-gray-200 text-gray-700"
                )}
              >
                {interviews?.length ?? 0}
              </span>
            </h2>
            <div className="md:flex-1 overflow-y-auto  flex flex-col gap-4">
              {interviews?.length === 0 ? (
                <div className="text-gray-500 text-center py-4">Deocamdata nu ai interviuri programate</div>
              ) : (
                interviews?.map(interview => (
                  <InterviewCard
                    key={interview.id}
                    title={`interview with ${interview.process.need.company as unknown as string}`}
                    location={interview.address ?? "-"}
                    // eslint-disable-next-line jsx-a11y/aria-role, @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
                    role={`${interview.process.need.category ?? ""} - ${interview.process.need.subcategory ?? ""}`}
                    contactPerson={interview.contact_person}
                    phone={interview.phone}
                    interviewType={interview.interview_type as "offline" | "virtual" | "phone"}
                    startTime={interview.scheduled_date_time}
                    endTime={interview.scheduled_date_time}
                    to={`/offers/${interview.process.id}`}
                  />
                ))
              )}
            </div>
            <Link to="/interviews" className="text-medi-blue font-semibold uppercase mt-4 self-end">
              VEZI TOATE INTERVIURILE
            </Link>
          </div>
        </div>

        <div className="md:flex-1 bg-white p-6 rounded-lg shadow overflow-hidden">
          <div className="flex flex-col h-full max-sm:max-h-[30rem]">
            <h2 className="text-xl font-semibold mb-4 flex items-center justify-between border-b border-gray-100 pb-4">
              <div className="flex items-center">
                <MdLocalOffer className="mr-2" />
                <span className="mr-2">Oferte</span>
              </div>
              <span
                className={cn(
                  "rounded-full px-3 py-1 text-sm",
                  offersLength > 0 ? "bg-medi-blue text-white" : "bg-gray-200 text-gray-700"
                )}
              >
                {offersLength}
              </span>
            </h2>
            <div className="flex-1 overflow-y-auto min-h-0 flex flex-col gap-4">
              {offers.map(offer => (
                <JobCardItem
                  key={offer.id}
                  title={offer.position}
                  location={`${offer.city}, ${offer.county}`}
                  subtitle={`${offer.interviewCount} interviuri programate`}
                  to={`/offers/${offer.id}`}
                />
              ))}
            </div>
            <Link to="/offers" className="text-medi-blue font-semibold uppercase mt-4 self-end">
              VEZI TOATE OFERTELE
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateDashboard;
