import { ChangeEvent, FormEvent, useEffect, useState } from "react";

import { useQuery } from "@tanstack/react-query";

import { messages } from "./auth-data";
import { Button } from "@/components/button/Button";
import MedijobsLogo from "@/assets/images/medijobs_logo_subtext.svg";
import { getCategories, registerCandidate } from "@/services/api/auth";

import MDPhoneInput from "@/components/phoneInput";
import Input from "@/components/input/input";
import { Link } from "@tanstack/react-router";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/select/select";

const RegisterCandidatePage = () => {
  const { data: categories } = useQuery({
    queryKey: ["categories"],
    queryFn: getCategories,
  });

  const sorted_cats = categories?.data.sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0)) ?? [];

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [utm_campaign, setUtmCampaign] = useState("");
  const [utm_source, setUtmSource] = useState("");
  const [utm_medium, setUtmMedium] = useState("");
  const [utm_content, setUtmContent] = useState("");
  const [http_referer, setHttpReferer] = useState("");
  const [adsValue, setAdsValue] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [subCategories, setSubCategories] = useState<ApiSubcategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<ApiCategory>();
  const [selectedSubCategory, setSelectedSubcategory] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [serverErrors, setServerErrors] = useState([] as string[]);
  const handleEmailChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setEmail(ev.target.value);
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  };

  const handlePasswordChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setPassword(ev.target.value);
  };

  const handleRegisterSubmit = async (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    let formHasErrors = false;

    if (email === "" || !isValidEmail(email)) {
      setEmailError(true);
      formHasErrors = true;
    }

    if (password === "") {
      setPasswordError(true);
      formHasErrors = true;
    }
    if (name === "") {
      setNameError(true);
      formHasErrors = true;
    }

    if (!phone) {
      setPhoneError(true);
      formHasErrors = true;
    }
    if (!selectedCategory) {
      setCategoryError(true);
      formHasErrors = true;
    }

    if (!termsAccepted) {
      setTermsError(true);
      formHasErrors = true;
    }
    if (formHasErrors) {
      return;
    }
    const payload = {
      email,
      password,
      name,
      phone,
      terms: termsAccepted ? 1 : 0,
      ads: adsValue ? 1 : 0,
      utm_campaign,
      utm_source,
      utm_medium,
      utm_content,
      category_id: selectedCategory?.id,
      referer: http_referer,
      subcategory_ids: [selectedSubCategory],
    };
    const response = await registerCandidate(payload);

    if (response.errors) {
      setServerErrors(Object.values(response.errors));
    } else {
      const token = response.access_token;
      /*
       * const URL = import.meta.env.VITE_RECRUITER_APP_URL
       * entering funnel here
       */

      const URL = import.meta.env.VITE_RECRUITER_APP_URL;

      window.location.href = `${URL}/auth/callback#access_token=${token}&token_type=Bearer&expires_in=31622400`;
    }
  };
  const handleCategorySelect = (value: string) => {
    const category = sorted_cats?.find(category => category.id === parseInt(value));

    setSelectedCategory(category);
    const subCategories = category?.subcategories ?? [];

    setSubCategories(subCategories);
  };
  const handleSubcategorySelect = (value: string) => {
    setSelectedSubcategory(value);
  };

  const handleTermsChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(ev.target.checked);
  };
  const setUTMValues = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const utm_campaign = urlParams.get("utm_campaign") ?? "";
    const utm_source = urlParams.get("utm_source") ?? "";
    const utm_medium = urlParams.get("utm_medium") ?? "";
    const utm_content = urlParams.get("utm_content") ?? "";
    const http_referer = urlParams.get("http_referer") ?? "";

    setUtmCampaign(utm_campaign);
    setUtmSource(utm_source);
    setHttpReferer(http_referer);
    setUtmMedium(utm_medium);
    setUtmContent(utm_content);
  };

  useEffect(() => {
    setUTMValues();
  }, []);
  return (
    <div id="login-page">
      <div className="container">
        <div className="layout justify-space-between align-center align-content-center m-0 p-0">
          <div>
            <img alt="Medijobs logo" src={MedijobsLogo} width={150} />
          </div>

          <Link to="/register/company">Creează profil companie</Link>
        </div>
      </div>

      <div className="mt-10">
        {serverErrors.length > 0 && (
          <div className="container">
            <div className="layout column">
              {serverErrors.map((error, idx) => (
                <div key={`error-${idx}`} className="error-alert">
                  {" "}
                  {error}
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="">
          <h2>{messages.ro.funnel.candidate.create}</h2>

          <p className="larger-text">{messages.ro.funnel.candidate.createSubtext}</p>
        </div>

        <form onSubmit={handleRegisterSubmit} className="flex flex-col gap-4 mt-4">
          <div className="flex flex-col gap-1.5">
            <label htmlFor="name">{messages.ro.funnel.candidate.name} *</label>

            <Input
              name="name"
              placeholder={messages.ro.funnel.candidate.name}
              type="text"
              value={name}
              onChange={ev => setName(ev.target.value)}
            />

            {nameError && <span className="input-container__error">{messages.ro.funnel.common.requiredError}</span>}
          </div>

          <div className="flex flex-col gap-1.5">
            <label htmlFor="job">{messages.ro.funnel.candidate.category} *</label>

            <Select onValueChange={value => handleCategorySelect(value)}>
              <SelectTrigger>
                <SelectValue placeholder={messages.ro.funnel.candidate.categoryPlacehold} />
              </SelectTrigger>
              <SelectContent>
                {sorted_cats.length > 0 &&
                  sorted_cats.map((option, i) => (
                    <SelectItem value={`${option.id}`} key={i}>
                      {option.value}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>

            {categoryError && <span className="input-container__error">{messages.ro.funnel.common.requiredError}</span>}
          </div>

          {subCategories.length > 0 && (
            <div className="flex flex-col gap-1.5">
              <label htmlFor="job">{messages.ro.funnel.candidate.subcategory} *</label>

              <Select onValueChange={handleSubcategorySelect}>
                <SelectTrigger>
                  <SelectValue placeholder={messages.ro.funnel.candidate.subcategoryPlacehold} />
                </SelectTrigger>
                <SelectContent>
                  {subCategories.map((option, i) => (
                    <SelectItem value={`${option.id}`} key={i}>
                      {option.value}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>

              {nameError && <span className="input-container__error">{messages.ro.funnel.common.requiredError}</span>}
            </div>
          )}

          <div className="flex flex-col gap-1.5">
            <label htmlFor="email">{messages.ro.funnel.candidate.email} *</label>

            <Input
              name="email"
              placeholder={messages.ro.funnel.candidate.emailPlacehold}
              type="text"
              value={email}
              onChange={handleEmailChange}
            />

            {emailError && (
              <span className="input-container__error">
                {email === "" ? messages.ro.funnel.common.requiredError : messages.ro.funnel.common.emailError}
              </span>
            )}
          </div>

          <div className="flex flex-col gap-1.5">
            <label htmlFor="job">{messages.ro.funnel.candidate.phone} *</label>
            <MDPhoneInput
              onChange={(phone: string) => {
                setPhone(phone);
              }}
              defaultCountry="ro"
              placeholder={messages.ro.funnel.candidate.phonePlacehold}
            />

            {phoneError && <span className="input-container__error">{messages.ro.funnel.common.requiredError}</span>}
          </div>

          <div className="flex flex-col gap-1.5">
            <label htmlFor="password">{messages.ro.funnel.login.password} *</label>

            <Input
              name="password"
              placeholder={messages.ro.funnel.login.passwordPlacehold}
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />

            {passwordError && <span className="input-container__error">{messages.ro.funnel.common.requiredError}</span>}
          </div>

          <div
            className={
              termsError ? "input-container-checkbox input-container-checkbox--error" : "input-container-checkbox"
            }
          >
            <label htmlFor="terms">
              <input id="terms" name="terms" type="checkbox" onChange={handleTermsChange} />
              {messages.ro.funnel.common.agree}
              <a href="/tos" target="_blank">
                {messages.ro.funnel.common.terms}
              </a>
              {messages.ro.funnel.common.platform} *
            </label>
          </div>

          <label htmlFor="ads">
            <input id="ads" name="ads" type="checkbox" onChange={ev => setAdsValue(ev.target.checked)} />

            {messages.ro.funnel.common.marketing}
          </label>

          <Button type="submit" variant="default">
            {messages.ro.funnel.common.createAccount}
          </Button>
        </form>

        <div className="flex mt-4">
          {messages.ro.funnel.common.alreadyHaveAccount}

          {"? "}

          <Link to="/login">{messages.ro.funnel.common.auth}</Link>
        </div>
      </div>
    </div>
  );
};

export default RegisterCandidatePage;
