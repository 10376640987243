import { AuthLayout } from "@/components/Layouts/auth";
import { getAngularCredentials, getAngularToken } from "@/core/auth/utils";
import { ProfileType } from "@/pages/auth/login";
import ErrorPage from "@/pages/error-page";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth-layout")({
  component: () => <AuthLayout />,
  errorComponent: () => <ErrorPage />,
  beforeLoad: () => {
    const credentials = getAngularCredentials();
    const meditoken = getAngularToken();

    if (meditoken) {
      if (credentials?.profileType === ProfileType.Candidate) {
        throw redirect({ to: "/dashboard/candidate" });
      } else {
        try {
          const ngApp = import.meta.env.VITE_RECRUITER_APP_URL;

          window.location.href = `${ngApp}`;
        } catch (error) {
          console.error(error);
        }
      }
    }
  },
});
