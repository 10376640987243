import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/select/select";
import { IoCloseSharp as RemoveIcon } from "react-icons/io5";

import { Typography } from "@/components/typography";
import { FormControl, FormField, FormItem, FormMessage } from "@/components/form";
import {
  MultiSelector,
  MultiSelectorContent,
  MultiSelectorInput,
  MultiSelectorItem,
  MultiSelectorList,
  MultiSelectorTrigger,
} from "@/components/select/multiselect";
import { useCities } from "@/libs/hooks/useCities";
import { cn } from "@/libs/utils/cn";
import { Badge } from "@/components/badge";

import { FormattedMessage, useIntl } from "react-intl";
import { useFormContext } from "react-hook-form";
import { formSchemaType } from "@/components/Layouts/onboarding";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/popover";

import { HiChevronUpDown } from "react-icons/hi2";
import { Command, CommandInput, CommandItem, CommandList } from "@/components/cmd";

import { useState } from "react";

const StepThreeForm = () => {
  const intl = useIntl();
  const form = useFormContext<formSchemaType>();
  const [isOpen, setIsOpen] = useState(false);

  const { filteredCities, setSearchTerm } = useCities();

  const getLabel = (value: string) => filteredCities.find(city => city.value === value)?.label;

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col gap-10 ">
        <div className="">
          <Typography className="text-center">
            <FormattedMessage
              id="onboarding.candidate.steps.three.city_label"
              defaultMessage="What is your city of residence?"
            />
          </Typography>
          <div className="flex flex-col mt-4 gap-2  md:px-10">
            <FormField
              control={form.control}
              name="city"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormControl>
                    <Popover open={isOpen} onOpenChange={setIsOpen}>
                      <PopoverTrigger className="flex bg-white text-black/40 font-semibold justify-between items-center border px-2.5 h-[2.5rem] shadow-md rounded-md">
                        {field.value ? (
                          <Typography className="text-black/80">{getLabel(field.value)}</Typography>
                        ) : (
                          intl.formatMessage({
                            id: "onboarding.candidate.steps.three.city_placeholder",
                            defaultMessage: "Search and select city",
                          })
                        )}
                        <HiChevronUpDown className="ml-2 h-4 w-4 shrink-0" />
                      </PopoverTrigger>
                      <PopoverContent className="popover-content p-0 bg-white">
                        <Command>
                          <CommandInput placeholder="Search and select city" />
                          <CommandList className="overflow-y-scroll max-h-96">
                            {filteredCities.map(item => (
                              <CommandItem
                                onSelect={() => {
                                  field.onChange(item.value);
                                  setIsOpen(false);
                                }}
                                key={item.value}
                                value={item.label}
                                className={cn("cursor-pointer", field.value === item.value ? "bg-medi-blue" : "")}
                              >
                                {item.label}
                              </CommandItem>
                            ))}
                          </CommandList>
                        </Command>
                      </PopoverContent>
                    </Popover>
                  </FormControl>
                  <FormMessage className="text-red-600" />
                </FormItem>
              )}
            />
          </div>
        </div>
        <div className="">
          <Typography className="text-center">
            <FormattedMessage
              id="onboarding.candidate.steps.three.work_locations_label"
              defaultMessage="For which city do you want to receive opportunities?"
            />
          </Typography>

          <div className="flex flex-col mt-4 gap-2  md:px-10">
            <FormField
              control={form.control}
              name="work_locations"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <MultiSelector values={field.value ?? []} onValuesChange={field.onChange}>
                      <MultiSelectorTrigger>
                        <MultiSelectorInput
                          onValueChange={value => setSearchTerm(value)}
                          placeholder={intl.formatMessage({
                            id: "onboarding.candidate.steps.three.work_locations_placeholder",
                            defaultMessage: "Search and select cities",
                          })}
                        />
                      </MultiSelectorTrigger>
                      <MultiSelectorContent>
                        <MultiSelectorList>
                          {filteredCities.map((city, i) => (
                            <MultiSelectorItem key={i} value={city.value}>
                              {city.label}
                            </MultiSelectorItem>
                          ))}
                        </MultiSelectorList>
                      </MultiSelectorContent>
                    </MultiSelector>
                  </FormControl>
                  <FormMessage className="text-red-600" />
                  <div className="flex gap-2 items-center flex-wrap mt-2">
                    {field.value &&
                      field.value.map(item => (
                        <Badge
                          key={item}
                          className={cn("px-1 rounded-xl flex items-center gap-1 w-max")}
                          variant="default"
                        >
                          <span className="text-xs">{getLabel(item)}</span>
                          <button
                            aria-label={`Remove ${getLabel(item)} option`}
                            aria-roledescription="button to remove option"
                            type="button"
                            onClick={() => {
                              field.onChange(field.value.filter(v => v !== item));
                            }}
                          >
                            <span className="sr-only">Remove {getLabel(item)} option</span>
                            <RemoveIcon className="h-4 w-4 hover:stroke-destructive" />
                          </button>
                        </Badge>
                      ))}
                  </div>
                </FormItem>
              )}
            />
          </div>
        </div>
        <div className="">
          <Typography className="text-center">
            <FormattedMessage
              id="onboarding.candidate.steps.three.max_distance_in_km_label"
              defaultMessage="How many Miles are you willing to fare?"
            />
          </Typography>
          <div className="flex flex-col mt-4 gap-2  md:px-10">
            <FormField
              control={form.control}
              name="max_distance_in_km"
              render={({ field }) => (
                <FormItem>
                  <Select value={field.value} onValueChange={field.onChange}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue
                          placeholder={intl.formatMessage({
                            id: "onboarding.candidate.steps.three.max_distance_in_km_placeholder",
                            defaultMessage: "Select Distance",
                          })}
                        />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="25">25 km</SelectItem>
                      <SelectItem value="50">50 km</SelectItem>
                      <SelectItem value="100">100 km</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage className="text-red-600" />
                </FormItem>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepThreeForm;
