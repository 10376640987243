export default {
  common: {
    yes: "Yes",
    no: "No",
    next_step: "Next Step",
    back: "Back",
    prev_step: "Prev Step",
    finish: "Finish",
    skip_to_dashboard: "Skip this step",
    already_have_an_account: "Already have an account?",
    log_in: "Log in!",
    error: {
      required: "This field is required",
      email: "Please enter a valid email address",
      password: "Password must be at least 8 characters long",
      password_mismatch: "Passwords do not match",
      min: "Minimum value is {min}",
      max: "Maximum value is {max}",
      invalid: "Invalid value",
      invalid_file_type: "Invalid file type",
      invalid_file_size: "File size is too large. Max file size is {size}",
      invalid_file: "Invalid file",
      invalid_date: "Invalid date",
      invalid_number: "Invalid number",
      invalid_url: "Invalid URL",
      invalid_phone: "Invalid phone number",
      invalid_postal_code: "Invalid postal code",
      invalid_credit_card: "Invalid credit card number",
      invalid_cv: "Invalid CV",
      invalid_password: "Invalid password",
      invalid_username: "Invalid username",
      invalid_name: "Invalid name",
      invalid_address: "Invalid address",
      invalid_city: "Invalid city",
      invalid_country: "Invalid country",
      invalid_state: "Invalid state",
      invalid_zip: "Invalid zip code",
      invalid_phone_number: "Invalid phone number",
      invalid_email: "Invalid email address",
    },
    contact_methods: {
      phone_call: "Phone call",
      email: "Email",
      text_message: "Text Message",
      whatsapp: "WhatsApp",
    },
    job_search_status: {
      ready: "I am ready to start interviewing",
      interviewing: "Interviewing",
      not_ready: "Not currently looking",
    },
  },
};
