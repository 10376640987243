import { AxiosV2 } from "@/libs/utils/axios-interceptor";
import { createMutation } from "react-query-kit";

interface UseDeleteAvailabilityResponse {
  id: string;
}

interface UseDeleteAvailabilityPayload {
  id: string;
  user: string;
}

export const useDeleteAvailability = createMutation<UseDeleteAvailabilityResponse, UseDeleteAvailabilityPayload>({
  mutationFn: async payload => {
    const { id, user } = payload;
    const response = await AxiosV2.delete<UseDeleteAvailabilityResponse>(
      `/event-management/availability/${id}/?user=${user}`
    );

    return response.data;
  },
});
