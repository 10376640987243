import { useQuery } from "@tanstack/react-query";
import { Axios } from "../utils/axios-interceptor";

interface FetchCandidateResponse {
  data: ApiCandidate;
  isLoading: boolean;
  error: string;
}
const useFetchCandidate = (shouldFetch: boolean = true) => {
  const { data, isLoading, error } = useQuery<FetchCandidateResponse, Error>({
    queryKey: ["candidate"],
    queryFn: async () => Axios.get("/candidate"),
    enabled: shouldFetch,
  });

  return {
    data: data?.data ?? null,
    isLoading,
    error,
  };
};

export { useFetchCandidate };
