import { useIntl } from "react-intl";
import OnboardingWrapper from "@/components/Layouts/onboarding";
import StepFiveForm from "@/components/onboarding/forms/step-five";
import StepFourForm from "@/components/onboarding/forms/step-four";
import FormOne from "@/components/onboarding/forms/step-one";
import StepSevenForm from "@/components/onboarding/forms/step-seven";

import StepThreeForm from "@/components/onboarding/forms/step-three";
import StepSixForm from "@/components/onboarding/forms/step-six";
import StepTwoForm from "@/components/onboarding/forms/step-two";
import StepEightForm from "@/components/onboarding/forms/step-eight";
// import StepNineForm from "@/components/onboarding/forms/step-nine";
import StepTenForm from "@/components/onboarding/forms/step-ten";
import StepElevenForm from "@/components/onboarding/forms/step-eleven";
import StepTwelveForm from "@/components/onboarding/forms/step-twelve";
import StepThirteenForm from "@/components/onboarding/forms/step-thirteen";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";

const CandidateOnboardingPage = () => {
  const intl = useIntl();

  useDocumentTitle("Onboarding");
  const steps: ProgressStep[] = [
    {
      id: "Step 1",
      title: intl.formatMessage({ id: "onboarding.candidate.steps.one.title" }),
      description: intl.formatMessage({ id: "onboarding.candidate.steps.one.description" }),
      form_fields_key: [
        "name",
        "email",
        "password",
        "terms",
        "categories",
        "confirm_password",
        "receive_notifications",
        "phone",
      ],
      content: <FormOne />,
    },
    {
      id: "Step 2",
      title: intl.formatMessage({ id: "onboarding.candidate.steps.two.title" }),
      form_fields_key: ["work_type"],
      content: <StepTwoForm />,
    },
    {
      id: "Step 3",
      title: intl.formatMessage({ id: "onboarding.candidate.steps.three.title" }),
      form_fields_key: ["work_locations", "max_distance_in_km", "agrees_to_abroad_work", "city"],
      content: <StepThreeForm />,
    },
    {
      id: "Step 4",
      title: intl.formatMessage({ id: "onboarding.candidate.steps.four.title" }),
      content: <StepFourForm />,
      form_fields_key: ["certification"],
    },

    {
      id: "Step 5",
      title: intl.formatMessage({ id: "onboarding.candidate.steps.five.title" }),
      content: <StepFiveForm />,
      form_fields_key: ["min_salary", "max_salary"],
    },
    {
      id: "Step 6",
      title: intl.formatMessage({ id: "onboarding.candidate.steps.six.title" }),
      content: <StepSixForm />,
      form_fields_key: ["agrees_to_abroad_work", "abroad_work_countries", "language_ability"],
    },
    {
      id: "Step 7",
      title: intl.formatMessage({ id: "onboarding.candidate.steps.seven.title" }),
      content: <StepSevenForm />,
      form_fields_key: ["categories"],
    },
    {
      id: "Step 8",
      title: intl.formatMessage({ id: "onboarding.candidate.steps.eight.title" }),
      content: <StepEightForm />,
      form_fields_key: ["categories"],
    },
    {
      id: "Step 10",
      title: intl.formatMessage({ id: "onboarding.candidate.steps.ten.title" }),
      content: <StepTenForm />,
      form_fields_key: ["notification_preferences"],
    },
    {
      id: "Step 11",
      title: intl.formatMessage({ id: "onboarding.candidate.steps.eleven.title" }),
      content: <StepElevenForm />,
      form_fields_key: ["job_search_status"],
    },
    {
      id: "Step 12",
      title: intl.formatMessage({ id: "onboarding.candidate.steps.twelve.title" }),
      form_fields_key: ["black_listed"],
      content: <StepTwelveForm />,
    },

    {
      id: "Step 13",
      title: intl.formatMessage({ id: "onboarding.candidate.steps.thirteen.title" }),
      form_fields_key: ["resume"],
      content: <StepThirteenForm />,
    },
  ];

  return <OnboardingWrapper stepsArray={steps} />;
};

export default CandidateOnboardingPage;
