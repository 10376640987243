import { getExperiences } from "@/services/api/auth";
import { useQuery } from "@tanstack/react-query";

const useFetchExperience = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["experiences"],
    queryFn: getExperiences,
  });

  return {
    data: data?.data ?? [],
    isLoading,
    isError,
  };
};

export { useFetchExperience };
