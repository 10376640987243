import CandidateOnboardingPage from "@/pages/onboarding/candidate";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const searchParamsSchema = z.object({
  utm_content: z.string().optional(),
  utm_campaign: z.string().optional(),
  utm_source: z.string().optional(),
  utm_medium: z.string().optional(),
  http_referrer: z.string().optional(),
  step: z.number().optional(),
  email: z.string().optional(),
  name: z.string().optional(),
});

export type OnboardingSearch = z.infer<typeof searchParamsSchema>;

export const Route = createFileRoute("/_auth-layout/onboarding/candidate/")({
  component: () => <CandidateOnboardingPage />,
  validateSearch: searchParamsSchema,
});
