import { FormField, FormItem, FormLabel, FormControl, FormMessage } from "@/components/form";
import { formSchemaType } from "@/components/Layouts/onboarding";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/select/select";
import { Typography } from "@/components/typography";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useMemo, useState, useCallback } from "react";
import { useFetchCategories } from "@/libs/hooks/useFetchCategories";
import {
  MultiSelector,
  MultiSelectorContent,
  MultiSelectorInput,
  MultiSelectorItem,
  MultiSelectorList,
  MultiSelectorTrigger,
} from "@/components/select/multiselect";
import { Badge } from "@/components/badge";
import { IoCloseSharp as RemoveIcon } from "react-icons/io5";
import { cn } from "@/libs/utils/cn";
import { store } from "@/libs/store";

const sortCategories = (categories: ApiCategory[]) =>
  categories.sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0));

const StepSevenForm = () => {
  const intl = useIntl();
  const { control } = useFormContext<formSchemaType>();
  const { fields } = useFieldArray({
    control,
    name: "categories",
  });

  const [subCategories, setSubCategories] = useState<ApiSubcategory[]>([]);
  const [availableTags, setAvailableTags] = useState<ApiTag[]>([]);

  const { data: categories } = useFetchCategories();
  const { user } = store();

  const filteredCategories = useMemo(() => {
    const primaryDomainExperience = user?.candidate_object?.domain_experiences.find(exp => exp.is_main === 1);
    const primaryCategoryId = primaryDomainExperience?.category.id;

    return categories?.filter(cat => cat.id !== primaryCategoryId);
  }, [categories, user?.candidate_object?.domain_experiences]);

  const sorted_cats = useMemo(() => sortCategories(filteredCategories), [filteredCategories]);

  const handleCategorySelect = useCallback(
    (value: string) => {
      const category = sorted_cats.find(category => category.id === parseInt(value));

      setSubCategories(category?.subcategories ?? []);
      setAvailableTags(category?.tags ?? []);
    },
    [sorted_cats]
  );

  const handleSubCategorySelect = useCallback(
    (values: string[]) => {
      const selectedSubCategories = subCategories.filter(subCat => values.includes(subCat.id.toString()));
      const subCategoryTags = selectedSubCategories.flatMap(subCat => subCat.tags ?? []);

      setAvailableTags(prevTags => {
        const newTags = [...prevTags, ...subCategoryTags];

        return Array.from(new Set([...prevTags, ...subCategoryTags].map(tag => tag.id))).map(
          // eslint-disable-next-line max-nested-callbacks
          id => newTags.find(tag => tag.id === id)!
        );
      });
    },
    [subCategories]
  );

  return (
    <div className="flex flex-col w-full">
      <Typography className="text-center">
        <FormattedMessage
          id="onboarding.candidate.steps.seven.secondary_job_title_label"
          defaultMessage="Please add any other job titles for which you would like to receive job offers."
        />
      </Typography>

      <div className="mt-10 space-y-6">
        <FormField
          key={fields[1].id}
          control={control}
          name={`categories.${1}.category_id`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <FormattedMessage id="onboarding.candidate.steps.one.category" defaultMessage="Category" />
              </FormLabel>

              <FormControl>
                <Select
                  value={field.value}
                  onValueChange={(value: string) => {
                    field.onChange(value);
                    handleCategorySelect(value);
                  }}
                >
                  <SelectTrigger>
                    <SelectValue
                      placeholder={intl.formatMessage({
                        id: "onboarding.candidate.steps.seven.secondary_job_title_placeholder",
                        defaultMessage: "Select Job Title",
                      })}
                    />
                  </SelectTrigger>
                  <SelectContent>
                    {sorted_cats.length > 0 &&
                      sorted_cats.map((option, i) => (
                        <SelectItem value={`${option.id}`} key={i}>
                          {option.value}
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage className="text-red-600" />
            </FormItem>
          )}
        />

        {subCategories?.length > 0 && (
          <FormField
            key={fields[1].id}
            control={control}
            name={`categories.${1}.subcategories`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <FormattedMessage id="onboarding.candidate.steps.one.sub_category" defaultMessage="Sub Category" />
                </FormLabel>

                <FormControl>
                  <MultiSelector
                    values={field.value?.map(item => item.subcategory_id) ?? []}
                    onValuesChange={values => {
                      field.onChange(
                        values.map(value => ({
                          subcategory_id: value,
                        }))
                      );
                      handleSubCategorySelect(values);
                    }}
                  >
                    <MultiSelectorTrigger>
                      <MultiSelectorInput
                        placeholder={intl.formatMessage({
                          id: "onboarding.candidate.steps.one.sub_category_placeholder",
                          defaultMessage: "Select Secondary Sub Categories",
                        })}
                      />
                    </MultiSelectorTrigger>
                    <MultiSelectorContent>
                      <MultiSelectorList>
                        {subCategories.map((option, i) => (
                          <MultiSelectorItem key={i} value={`${option.id}`}>
                            {option.value}
                          </MultiSelectorItem>
                        ))}
                      </MultiSelectorList>
                    </MultiSelectorContent>
                  </MultiSelector>
                </FormControl>
                <FormMessage className="text-red-600" />
                <div className="flex gap-2 items-center flex-wrap mt-2">
                  {field.value &&
                    field.value.map((item: { subcategory_id: string; years?: string | undefined }) => (
                      <Badge
                        key={item.subcategory_id}
                        className={cn("px-1 rounded-xl flex items-center gap-1 w-max")}
                        variant="default"
                      >
                        <span className="text-xs">
                          {subCategories.find(cat => cat.id.toString() === item.subcategory_id)?.value}
                        </span>
                        <button
                          type="button"
                          onClick={() => {
                            field.onChange(
                              field.value?.filter(
                                (v: { subcategory_id: string }) => v.subcategory_id !== item.subcategory_id
                              ) ?? []
                            );
                          }}
                        >
                          <RemoveIcon className="h-4 w-4 hover:stroke-destructive" />
                        </button>
                      </Badge>
                    ))}
                </div>
              </FormItem>
            )}
          />
        )}

        {availableTags.length > 0 && (
          <FormField
            key={fields[1].id}
            control={control}
            name={`categories.${1}.tags`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <FormattedMessage
                    id="onboarding.candidate.steps.one.tags_label"
                    defaultMessage="Secondary Competencies"
                  />
                </FormLabel>
                <FormControl>
                  <MultiSelector values={field.value ?? []} onValuesChange={field.onChange}>
                    <MultiSelectorTrigger>
                      <MultiSelectorInput
                        placeholder={intl.formatMessage({
                          id: "onboarding.candidate.steps.one.tags_placeholder",
                          defaultMessage: "Select Secondary Tags",
                        })}
                      />
                    </MultiSelectorTrigger>
                    <MultiSelectorContent>
                      <MultiSelectorList>
                        {availableTags.map(tag => (
                          <MultiSelectorItem key={tag.id} value={`${tag.id}`}>
                            {tag.value}
                          </MultiSelectorItem>
                        ))}
                      </MultiSelectorList>
                    </MultiSelectorContent>
                  </MultiSelector>
                </FormControl>
                <FormMessage className="text-red-600" />
                <div className="flex gap-2 items-center flex-wrap mt-2">
                  {field.value &&
                    field.value.map((tagId: string) => {
                      const tag = availableTags.find(t => t.id === parseInt(tagId));

                      return tag ? (
                        <Badge
                          key={tag.id}
                          className={cn("px-1 rounded-xl flex items-center gap-1 w-max")}
                          variant="default"
                        >
                          <span className="text-xs">{tag.value}</span>
                          <button
                            type="button"
                            onClick={() => {
                              field.onChange(field.value?.filter((v: string) => v !== tag.id.toString()) ?? []);
                            }}
                          >
                            <RemoveIcon className="h-4 w-4 hover:stroke-destructive" />
                          </button>
                        </Badge>
                      ) : null;
                    })}
                </div>
              </FormItem>
            )}
          />
        )}
      </div>
    </div>
  );
};

export default StepSevenForm;
