export default {
  login: {
    email: "Email",
    password: "Parolă",
    forgot_password: "Ai uitat parola?",
    create_account: "Creează cont",
    no_account: "Nu ai cont?",
    invalid_email_or_password: "Email sau parolă invalidă",
    reset_password_description:
      "Introduceți adresa de email pe care ați folosit-o pentru a vă înregistra. Vă vom trimite un link pentru a restabili parola.",
    reset_password_success: "A fost trimis un e-mail pentru restabilirea parolei.",
    set_new_password: "Stabilește parola nouă",
    password_reset_success: "Parola a fost restabilită cu succes.",
    try_reset_password: "Incearca sa resetezi parola",
    reset_password_link: "Reseteaza parola",
  },
};
